import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router-dom";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type AlarmType } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { usePropertyUpdateSecurity } from "./hooks";
import styles from "./property-update-security.module.scss";

export type PropertyUpdateSecurityContextState = {
  burglarAlarm?: AlarmType;
  fireAlarm?: AlarmType;
  burglarAlarmFiles?: FileList;
  fireAlarmFiles?: FileList;
} | null;

export const PropertyUpdateSecurityContext = createContext<{
  state: PropertyUpdateSecurityContextState;
  setState: Dispatch<SetStateAction<PropertyUpdateSecurityContextState>>;
}>({ state: null, setState: () => null });

export const PropertyUpdateSecurity = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { loading } = usePropertyUpdateSecurity();

  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateSecurityContextState>(
      PropertyUpdateSecurityContext
    );

  useEffect(() => {
    if (!state?.burglarAlarm || !state?.fireAlarm) {
      formNavigate("./");
    }
  }, [formNavigate, state]);

  if (loading || !selectedPolicyId) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet />;
};

export const PropertyUpdateSecurityFlow = () => {
  const { prevBurglar, prevFire } = usePropertyUpdateSecurity();
  const [state, setState] = useState<PropertyUpdateSecurityContextState>({
    burglarAlarm: prevBurglar,
    fireAlarm: prevFire,
  });
  return (
    <PropertyUpdateSecurityContext.Provider value={{ state, setState }}>
      <PropertyUpdateSecurity />
    </PropertyUpdateSecurityContext.Provider>
  );
};
