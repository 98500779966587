import { useEffect, useState } from "react";
import { Container } from "react-grid-system";

import { Link as DSLink } from "@icg360/design-system";

import { getCookie, logError, removeCookie, trackEvent } from "utils";

import styles from "./external-header.module.scss";

const ExternalHeader = ({ returnUrlParsed = false }) => {
  const [returnTokenUrl, setReturnTokenUrl] = useState(undefined);
  useEffect(() => {
    const samlCookie = getCookie("mss-saml-bridge-return");
    let samlValues = {
      returnTokenUrl: undefined,
    };
    if (samlCookie !== undefined) {
      try {
        const jsonString = atob(samlCookie);
        samlValues = JSON.parse(jsonString);
      } catch (err) {
        logError(`External header: ${err.message}`);
      }
    }
    setReturnTokenUrl(samlValues.returnTokenUrl);
  }, [returnUrlParsed]);

  return returnTokenUrl !== undefined ? (
    <div className={styles.headerWrapper}>
      <Container>
        <DSLink
          href={returnTokenUrl}
          onPress={() => {
            trackEvent("GEICO: Back to GEICO");
            removeCookie("mss-saml-bridge-return", { path: "/" });
          }}
        >
          &lt; Back to GEICO.com
        </DSLink>
      </Container>
    </div>
  ) : null;
};

export default ExternalHeader;
