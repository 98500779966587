import cx from "classnames";
import { useContext, useEffect, useRef, useState } from "react";

import { Icon } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { AddressLoader } from "components/loader";
import {
  AuthAppContext,
  AuthAppDispatchContext,
} from "components/root/auth-app-provider";
import { type Policy } from "gql/types";
import { capitalizeEachWord, trackEvent } from "utils";

import styles from "./multi-policy-selector.module.scss";

export const MultiPolicySelector = () => {
  const { policies, policy } = useContext(AuthAppContext);
  const [listVisible, setListVisible] = useState(false);

  const closeSelectorList = () => setListVisible(false);
  useEffect(() => {
    if (listVisible) {
      document.addEventListener("click", closeSelectorList);
    }
    return () => document.removeEventListener("click", closeSelectorList);
  }, [listVisible]);

  return !policies?.length ? (
    <AddressLoader />
  ) : (
    // Catches bubbled up events from buttons, not meant to be clicked directly
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.policyListContainer}
      data-testid="policy-list-nav"
      onClick={(e) => {
        e.stopPropagation();
        if (!listVisible) {
          trackEvent("policySwitcher_opened");
        }
        setListVisible((curVisibility) => !curVisibility);
      }}
    >
      <div className={styles.selectedPolicyItemWrapper}>
        <PolicyItem policy={policy} />
        <div className={styles.listCaret}>
          <Icon name={listVisible ? "ArrowUp" : "ArrowDown"} size="sm" />
        </div>
      </div>
      {listVisible && (
        <div className={styles.policyListWrapper}>
          <div data-testid="policy-list" className={styles.policyList}>
            {policies
              ?.filter((item) => item.policyId !== policy.policyId)
              .map((item) => (
                <PolicyItem policy={item} key={item.policyId} isListItem />
              ))}
          </div>
          <MSSLink
            to={"/my/settings/policies"}
            className={styles.addRemoveLink}
            onClick={() => trackEvent("policySwitcher_addPolicyClicked")}
          >
            Add policy
          </MSSLink>
        </div>
      )}
    </div>
  );
};

type PolicyItemProps = {
  policy: Policy;
  isListItem?: boolean;
};

const PolicyItem = ({ policy, isListItem = false }: PolicyItemProps) => {
  const { propertyAddress, policyId, isCommercial } = policy;
  const { setPolicy } = useContext(AuthAppDispatchContext);

  const iconName = isCommercial ? "Store" : "Home";
  const street1 = capitalizeEachWord(propertyAddress?.street1 ?? "");
  const street2 = capitalizeEachWord(propertyAddress?.street2 ?? "");
  const fullAddress = propertyAddress
    ? `${street1} ${street2} ${capitalizeEachWord(
        propertyAddress?.city ?? ""
      )}, ${propertyAddress.state} ${
        propertyAddress.zip
      } (Policy #: ${policyId})`
    : "";
  const address = propertyAddress ? `${street1} ${street2}` : "";

  const [addressHeight, setAddressHeight] = useState(0);
  const [bumpPolicyNumber, setBumpPolicyNumber] = useState(false);

  const policyContentRef = useRef<HTMLDivElement>(null);
  const addressRef = useRef<HTMLSpanElement>(null);
  const policyIdRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setAddressHeight(addressRef.current?.getBoundingClientRect()?.height ?? 0);
    const addressWidth =
      addressRef.current?.getBoundingClientRect()?.width ?? 0;
    const policyWidth =
      policyIdRef.current?.getBoundingClientRect()?.width ?? 0;
    const containerWidth =
      policyContentRef.current?.getBoundingClientRect()?.width ?? 0;
    const contentWouldBeOneLine = addressWidth + policyWidth <= containerWidth;
    setBumpPolicyNumber(contentWouldBeOneLine);
  }, []);

  const selectPolicy = () => {
    // the non-listItem policy is the currently selected one
    if (isListItem) {
      trackEvent("policySwitcher_anotherPolicyClicked");
      setPolicy(policy);
    }
  };

  return (
    <button
      onClick={selectPolicy}
      data-testid="policy-item"
      className={cx(styles.policyItem, {
        [styles.policyItemList]: isListItem,
      })}
    >
      <div className={styles.policyIcon} data-testid={`${iconName}-icon`}>
        <Icon name={iconName} size="sm" />
      </div>
      <div
        className={styles.policyContent}
        title={fullAddress}
        ref={policyContentRef}
      >
        {propertyAddress && (
          <span className={styles.addressLine} ref={addressRef}>
            {address}
          </span>
        )}
        <span
          ref={policyIdRef}
          className={cx(
            addressHeight > 30 ? styles.breakAll : null,
            bumpPolicyNumber ? styles.shortAddress : null
          )}
        >{` (#${policyId})`}</span>
      </div>
    </button>
  );
};
