import { yupResolver } from "@hookform/resolvers/yup";
import cloneDeep from "lodash.clonedeep";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { object as yupObject, ref as yupRef, string as yupString } from "yup";

import {
  Button,
  CheckboxGroup,
  Modal,
  TextInput,
  addToast,
} from "@icg360/design-system";

import {
  type ContactItemDataQuery,
  type PolicyPaperlessDataQuery,
  type UserBillingAddressQuery,
  useUpdateInsuredInfoMutation,
  useUpdatePaperlessDocumentsEmailMutation,
  useUpdatePaperlessEmailMutation,
} from "gql/__generated__/hooks";
import {
  contactItemDataQuery,
  policyPaperlessDataQuery,
  userBillingAddressQuery,
} from "gql/queries";
import { trackEvent } from "utils";

import styles from "./contact-item.module.scss";

const emailSettingsSchema = yupObject()
  .shape({
    emailAddress: yupString()
      .required("Please enter an email address.")
      .email("Please enter a valid email address."),
    emailAddressConfirm: yupString().when("emailAddress", {
      is: (val) => val?.length > 0,
      then: (schema) =>
        schema
          .oneOf(
            [yupRef("emailAddress"), undefined, ""],
            "Email addresses must match."
          )
          .required("Please enter an email address."),
    }),
  })
  .required();

type EditContactEmailFormFields = {
  emailAddress: string;
  emailAddressConfirm?: string;
  // applyToPaperless is being tracked outside react-hook-forms because DS Checkbox doesn't work with it nicely, yet
  // applyToPaperless: boolean;
};

type EditContactEmailButtonProps = {
  policyId: string;
  userEmail: string;
  isEnrolledBilling: boolean;
  isEnrolledDocuments: boolean;
};

export const EditContactEmailButton = ({
  policyId,
  userEmail,
  isEnrolledBilling,
  isEnrolledDocuments,
}: EditContactEmailButtonProps) => {
  const [updateEmail] = useUpdateInsuredInfoMutation();
  const [updatePaperlessEmail] = useUpdatePaperlessEmailMutation();
  const [updatePaperlessDocumentsEmail] =
    useUpdatePaperlessDocumentsEmailMutation();

  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [applyToPaperless, setApplyToPaperless] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm<EditContactEmailFormFields>({
    resolver: yupResolver(emailSettingsSchema),
    mode: "onBlur",
  });

  const onSave = async ({ emailAddress }) => {
    setSaving(true);
    const userUpdates = { emailAddress };
    let paperlessUpdateSucceeded = true;

    try {
      const { data: updateEmailData } = await updateEmail({
        variables: {
          policyID: policyId,
          emailAddress: userEmail,
          userUpdates,
        },
        update: (store, { data }) => {
          const { updateInsuredInfo } = data ?? {};
          if (updateInsuredInfo) {
            const readData = cloneDeep(
              store.readQuery<ContactItemDataQuery>({
                query: contactItemDataQuery,
                variables: { policyID: policyId },
              })
            );

            const { emailAddress } = userUpdates;

            if (emailAddress && readData) {
              readData.userDetails = {
                ...readData.userDetails,
                primaryInsured: {
                  ...readData.userDetails?.primaryInsured,
                  emailAddress: emailAddress,
                },
              };

              store.writeQuery({
                query: contactItemDataQuery,
                data: readData,
                variables: { policyID: policyId },
              });
            }
          }
        },
        context: {
          clientName: "keystone-api",
        },
      });
      const { updateInsuredInfo } = updateEmailData ?? {};
      if (updateInsuredInfo && applyToPaperless) {
        if (isEnrolledBilling) {
          const { data: updatePaperlessEmailData } = await updatePaperlessEmail(
            {
              variables: {
                opInsuredEmailAddressBilling: emailAddress,
                policyID: policyId,
              },
              update: (store, { data: successful }) => {
                if (successful) {
                  const readData = cloneDeep(
                    store.readQuery<UserBillingAddressQuery>({
                      query: userBillingAddressQuery,
                      variables: { policyID: policyId },
                    })
                  );

                  if (readData) {
                    readData.userBillingAddress = emailAddress;

                    store.writeQuery({
                      query: userBillingAddressQuery,
                      variables: { policyID: policyId },
                      data: readData,
                    });
                  }
                }
              },
            }
          );

          paperlessUpdateSucceeded =
            !!updatePaperlessEmailData?.updatePaperlessEmail;
        }

        if (isEnrolledDocuments) {
          const { data: updatePaperlessDocumentsEmailData } =
            await updatePaperlessDocumentsEmail({
              variables: {
                opInsuredEmailAddressDocuments: emailAddress,
                policyID: policyId,
              },
              update: (store, { data }) => {
                if (data?.updatePaperlessDocumentsEmail) {
                  const readData = cloneDeep(
                    store.readQuery<PolicyPaperlessDataQuery>({
                      query: policyPaperlessDataQuery,
                      variables: { policyID: policyId },
                    })
                  );

                  if (readData?.userDetails?.insuredPreferences) {
                    readData.userDetails.insuredPreferences.opInsuredEmailAddressDocuments =
                      emailAddress;

                    store.writeQuery({
                      query: policyPaperlessDataQuery,
                      variables: { policyID: policyId },
                      data: readData,
                    });
                  }
                }
              },
            });

          paperlessUpdateSucceeded =
            paperlessUpdateSucceeded &&
            !!updatePaperlessDocumentsEmailData?.updatePaperlessDocumentsEmail;
        }
      }

      if (updateInsuredInfo && paperlessUpdateSucceeded) {
        addToast("Email address updated", { icon: true });
        reset();
        trackEvent("Email Address Updated", {
          updatePaperlessEmail:
            isEnrolledBilling || isEnrolledDocuments ? applyToPaperless : null,
        });
        setShowModal(false);
      } else {
        trackEvent("Error Displayed (Update Contact Email)");
        addToast("We apologize. Something went wrong.", {
          icon: true,
          error: true,
        });
      }
      setSaving(false);
    } catch (err) {
      trackEvent("Error Displayed (Update Contact Email)", err);
      addToast("We apologize. Something went wrong.", {
        icon: true,
        error: true,
      });
      setSaving(false);
    }
  };

  return (
    <>
      <Button
        appearance="link"
        onPress={() => setShowModal(true)}
        data-testid="contact-email-edit-btn"
      >
        Edit
      </Button>
      <Modal
        open={showModal}
        heading="Update your email"
        onClose={() => setShowModal(false)}
        primaryAction="Save"
        onPrimaryAction={handleSubmit(onSave)}
        loadingPrimary={saving}
        disablePrimary={!isValid}
      >
        <div className={styles.modalContent}>
          <div>
            This email is used for claims updates, payment reminders, and
            notifications about your account. It&apos;s separate from your login
            email.
          </div>
          <TextInput
            label="New email"
            {...register("emailAddress")}
            errorMessage={errors.emailAddress?.message}
            isError={!!errors.emailAddress}
          />
          <TextInput
            label="Confirm email"
            {...register("emailAddressConfirm")}
            errorMessage={errors.emailAddressConfirm?.message}
            isError={!!errors.emailAddressConfirm}
          />
          {isEnrolledBilling ? (
            <CheckboxGroup
              aria-label={
                !isEnrolledDocuments
                  ? "Receive policy documents and billing statements."
                  : "Receive billing statements."
              }
              helpMessage={
                !isEnrolledDocuments
                  ? "This will update all emails in paperless settings."
                  : "This will update your existing billing email."
              }
            >
              <CheckboxGroup.Checkbox
                onChange={(value) => setApplyToPaperless(value)}
              >
                {!isEnrolledDocuments
                  ? "Receive policy documents and billing statements."
                  : "Receive billing statements."}
              </CheckboxGroup.Checkbox>
            </CheckboxGroup>
          ) : null}
        </div>
      </Modal>
    </>
  );
};
