import { type PropsWithChildren, type ReactNode } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Span } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { PaymentButton } from "components/payment-button";
import { AuthAppContext } from "components/root/auth-app-provider";
import { GlobalContext } from "components/root/global-provider";
import { POLICY_SERVICES } from "consts";
import { useTriggerLiveChat } from "hooks/use-live-chat";
import {
  formatCurrency,
  formatDate,
  logError,
  trackEvent,
  useFlags,
} from "utils";

import { WelcomeBannerSection } from "../welcome-banner-section";
import { useBillingState } from "./hooks";
import { BILLING_STATES } from "./hooks";

type BillingStateContent = {
  badge: { type: string; text: string };
  button: ReactNode;
  content: ReactNode;
  link?: ReactNode;
};

export const BillingSection = () => {
  const { liveChat } = useFlags();
  const { liveChatStatus } = useContext(GlobalContext);
  const triggerLiveChat = useTriggerLiveChat("BillingSection");

  const { userBilling, userDetails } = useContext(AuthAppContext);
  const dueDate = userBilling?.accounting?.dueDate;

  const dueDateDisplay =
    dueDate === "No Upcoming Payment"
      ? dueDate
      : formatDate(dueDate, "M/D/YYYY");

  const minimumPaymentDisplay = formatCurrency(
    userBilling?.accounting?.minimumPaymentDue,
    true
  );
  let totalBalanceDisplay = formatCurrency(
    userBilling?.accounting?.outstandingBalanceDue,
    true
  );
  totalBalanceDisplay = totalBalanceDisplay.startsWith("-")
    ? `(${totalBalanceDisplay.slice(1)})`
    : `${totalBalanceDisplay}`;

  // All possible states for the banner's billing section:
  const contentMap: Record<BILLING_STATES, BillingStateContent> = {
    [BILLING_STATES.cancelledPaymentDue]: {
      badge: { type: "neutral", text: "Policy Cancelled" },
      button: <BillingPaymentButton billingState="Policy cancelled" />,
      content: (
        <div>
          Your policy has been cancelled. You have a past due payment of{" "}
          <Span bold>{totalBalanceDisplay}</Span>.
        </div>
      ),
    },
    [BILLING_STATES.cancelled]: {
      badge: { type: "neutral", text: "Policy Cancelled" },
      button: <DetailsButton billingState="Policy Cancelled" />,
      content: (
        <div>
          Your policy has been cancelled.{" "}
          {userDetails?.policyStateAttributes?.effectiveDate ? (
            <>
              Your policy ended on{" "}
              <Span bold>
                {formatDate(
                  userDetails.policyStateAttributes.effectiveDate,
                  "M/D/YYYY"
                )}
              </Span>
              .
            </>
          ) : null}
        </div>
      ),
    },
    [BILLING_STATES.paid]: {
      badge: { type: "success", text: "Up to date" },
      button: <DetailsButton billingState="Direct Bill - up to date" />,
      content:
        "You are all paid up for your policy term. We will notify you when your next payment is coming up for renewal.",
    },
    [BILLING_STATES.mortgageePastDue]: {
      badge: { type: "danger", text: "Past due" },
      button: <BillingPaymentButton billingState="Mortgagee - past due" />,
      content: (
        <div>
          You have a past due payment of <Span bold>{totalBalanceDisplay}</Span>
          . Please contact your mortgage company or submit payment immediately
          to avoid cancellation of your policy.
        </div>
      ),
      link: (
        <BillingLink
          to="/my/settings/payment"
          eventName="welcomeTaskCard_billing_updateMortgage"
          billingState="Mortgagee - past due"
        >
          Update mortgage
        </BillingLink>
      ),
    },
    [BILLING_STATES.mortgagee]: {
      badge: { type: "success", text: "Up to date" },
      button: <DetailsButton billingState="Mortgagee - up to date" />,
      content:
        "Your policy premium has been paid by your mortgage company from your escrow account.",
      link: (
        <BillingLink
          to="/my/settings/payment"
          eventName="welcomeTaskCard_billing_updateMortgage"
          billingState="Mortgagee - up to date"
        >
          Update mortgage
        </BillingLink>
      ),
    },
    [BILLING_STATES.directPastDue]: {
      badge: { type: "danger", text: "Past due" },
      button: <BillingPaymentButton billingState="Direct pay - past due" />,
      content: (
        <div>
          You have a past due payment of <Span bold>{totalBalanceDisplay}</Span>
          . Please submit payment immediately to avoid the cancellation of your
          policy.
        </div>
      ),
      link: (
        <BillingLink
          to="/my/billing/update"
          eventName="welcomeTaskCard_billing_enrollInEasyPay"
          billingState="Direct bill - past due"
        >
          Enroll in EasyPay
        </BillingLink>
      ),
    },
    [BILLING_STATES.easypayPastDue]: {
      badge: { type: "danger", text: "Past due" },
      button: <BillingPaymentButton billingState="Easypay - past due" />,
      content: (
        <div>
          You have a past due payment of <Span bold>{totalBalanceDisplay}</Span>
          . Please submit payment immediately to avoid the cancellation of your
          policy.
        </div>
      ),
      link: (
        <BillingLink
          to="/my/settings/payment"
          eventName="welcomeTaskCard_billing_updatePayment"
          billingState="Easypay - past due"
        >
          Update Payment
        </BillingLink>
      ),
    },
    [BILLING_STATES.directScheduled]: {
      badge: { type: "success", text: "Scheduled" },
      button: <DetailsButton billingState="Direct bill - payment scheduled" />,
      content: (
        <div>
          You have a payment of <Span bold>{minimumPaymentDisplay}</Span>{" "}
          scheduled on <Span bold>{dueDateDisplay}</Span>.
        </div>
      ),
    },
    [BILLING_STATES.easypayScheduled]: {
      badge: { type: "success", text: "Up to date" },
      button: <DetailsButton billingState="Easypay - Up to date" />,
      content: (
        <div>
          Your account is currently enrolled in EasyPay. A payment of{" "}
          <Span bold>{minimumPaymentDisplay}</Span> will automatically be made
          on <Span bold>{dueDateDisplay}</Span>. If you have any questions,
          contact us{" "}
          {liveChat && liveChatStatus === "live" ? "through Live Chat or " : ""}
          by calling <Span bold>{POLICY_SERVICES}</Span>.
        </div>
      ),
      link:
        liveChat && liveChatStatus === "live" ? (
          <Button
            appearance="link"
            onPress={() => {
              trackEvent("welcomeTaskCard_billing_liveChat", {
                billingState: "Easypay - up to date",
              });
              triggerLiveChat();
            }}
            style={{ padding: 0 }}
          >
            Live Chat
          </Button>
        ) : null,
    },
  };

  const billingState = useBillingState();
  const { badge, button, content, link } = contentMap[billingState];

  return (
    <WelcomeBannerSection
      title="Billing & Payments"
      badgeProps={{
        appearance: badge.type,
        text: badge.text,
      }}
      footer={
        <>
          {button}
          {link ? link : null}
        </>
      }
    >
      {content}
    </WelcomeBannerSection>
  );
};

type DetailsButtonProps = {
  billingState: string;
};

const DetailsButton = ({ billingState }: DetailsButtonProps) => {
  const navigate = useNavigate();
  const onClick = () => {
    trackEvent("welcomeTaskCard_billing_viewDetails", { billingState });
    navigate("/my/billing");
  };
  return (
    <Button size="sm" appearance="secondary" onPress={onClick}>
      View details
    </Button>
  );
};

type BillingLinkProps = PropsWithChildren<{
  to: string;
  eventName: string;
  billingState: string;
}>;

const BillingLink = ({
  to,
  children,
  eventName,
  billingState,
}: BillingLinkProps) => {
  const onClick = () => {
    trackEvent(eventName, { billingState });
  };
  return (
    <MSSLink to={to} onClick={onClick}>
      {children}
    </MSSLink>
  );
};

type BillingPaymentButtonProps = {
  billingState: string;
};

const BillingPaymentButton = ({ billingState }: BillingPaymentButtonProps) => {
  return (
    <PaymentButton
      onPaymentError={(error) => {
        const errorMessage = error?.message ?? "Unknown Error";
        logError(`Payment Error (Billing): ${errorMessage}`);
        trackEvent("welcomeTaskCard_billing_makePayment_error", {
          billingState,
        });
      }}
      onPaymentComplete={(response) =>
        trackEvent("welcomeTaskCard_billing_makePayment_complete", {
          ...response,
          billingState,
        })
      }
      onPaymentStart={() =>
        trackEvent("welcomeTaskCard_billing_makePayment_start", {
          billingState,
        })
      }
      onScheduledPaymentCreated={() =>
        trackEvent("welcomeTaskCard_billing_makePayment_scheduled", {
          billingState,
        })
      }
    >
      Make a payment
    </PaymentButton>
  );
};
