import { AuthApp } from "components/auth-app";
import { HomeServicesProvider } from "components/home-services/home-services-provider";
import { CoverageContextProvider } from "pages/overview/extended-coverages/hooks";

import { AuthAppProvider } from "./auth-app-provider";

const AuthAppWrapper = () => (
  <AuthAppProvider>
    <HomeServicesProvider>
      <CoverageContextProvider>
        <AuthApp />
      </CoverageContextProvider>
    </HomeServicesProvider>
  </AuthAppProvider>
);

export default AuthAppWrapper;
