import {
  LogoutUserDocument,
  type LogoutUserMutation,
} from "gql/__generated__/hooks";
import {
  apolloClient,
  flagCheck,
  logout,
  resetAnalytics,
  trackEvent,
} from "utils";

export async function handleUnauthorized(details: { endpoint: string }) {
  trackEvent("Unauthorized Request", details);

  if (flagCheck("universalLogin")) {
    logout();
  } else {
    await apolloClient.query<LogoutUserMutation>({
      query: LogoutUserDocument,
    });
  }
  resetAnalytics();

  if (window.location.pathname !== "/") {
    window.location.replace("/");
  }
}
