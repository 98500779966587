import { useContext, useEffect, useState } from "react";

import { Alert, Heading, Span } from "@icg360/design-system";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import {
  PROPERTY_ROOF_CODE_MAP,
  PROPERTY_ROOF_TYPE_MAP,
  PROPERTY_ROOF_UPDATE_TYPE,
} from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { usePropertyUpdateRoof } from "pages/property-profile/property-update-roof/hooks";
import { logError, trackEvent, useFlags } from "utils";

import {
  PropertyUpdateRoofContext,
  type PropertyUpdateRoofContextState,
} from ".";
import { FileThumbnails } from "../file-thumbnails";
import { PropertyUpdateLayout } from "../property-update-layout";
import styles from "./property-update-roof.module.scss";

const POLLING_TIMEOUT = 30000; // Thirty seconds
const POLLING_INTERVAL = 1000; // One second

export const RoofReview = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const { supportPhoneNumber } = useFlags();
  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);
  const { email: userEmail } = userInfo ?? {};
  const { keystonePolicyId: insightPolicyId } = userDetails ?? {};
  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );
  const { files, roofYear, roofMaterial } = state ?? {};

  const { prevRoofYear, prevRoofType } = usePropertyUpdateRoof();

  const { startPolling, stopPolling, data } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  type PropertyErrorProps = {
    errorCode?: string | number;
    errorMessage?: string;
    errorType?: string;
  };

  const trackError = ({
    errorCode,
    errorMessage,
    errorType,
  }: PropertyErrorProps) => {
    const fileNames: string[] = [];
    const fileTypes: string[] = [];
    const fileSizes: number[] = [];
    files.forEach((file: File) => {
      fileNames.push(file.name);
      fileTypes.push(file.type);
      fileSizes.push(file.size);
    });
    trackEvent("PHE_Error", {
      id: "roof-update-submit-failed",
      numberOfFiles: files?.length,
      errorCode,
      errorMessage,
      errorType,
      fileNames,
      fileTypes,
      fileSizes,
    });
  };

  // After submitting, poll until data is updated, then navigate
  useEffect(() => {
    if (!pollingTimeout) {
      return;
    }
    const roofUpdateData =
      data?.userPropertyProfileData?.propertyUpdateData?.find(
        (detail) => detail?.detailName === "ROOF"
      );
    const hasYearUpdate = roofUpdateData?.items?.some(
      (update) => update?.enumerationValue === `${roofYear}`
    );
    const hasMaterialUpdate = roofUpdateData?.items?.some(
      (update) =>
        update?.enumerationValue === `${PROPERTY_ROOF_TYPE_MAP[roofMaterial]}`
    );

    if (hasYearUpdate && hasMaterialUpdate) {
      clearTimeout(pollingTimeout);
      stopPolling();
      formNavigate("../success");
    }
  }, [
    data?.userPropertyProfileData?.propertyUpdateData,
    formNavigate,
    pollingTimeout,
    roofMaterial,
    roofYear,
    stopPolling,
  ]);

  const onSubmit = async () => {
    setSubmitting(true);
    const formData = new FormData();
    const request = {
      insightPolicyId,
      userEmail,
      propertySection: "ROOF",
      propertyUpdateItems: [
        {
          termName: "Roof Year",
          enumerationValue: roofYear,
          currentDisplayValue: prevRoofYear ?? "N/A",
          updatedDisplayValue: roofYear,
        },
        {
          termName: "Roof Material",
          enumerationValue: PROPERTY_ROOF_TYPE_MAP[roofMaterial],
          currentDisplayValue: prevRoofType
            ? PROPERTY_ROOF_UPDATE_TYPE[PROPERTY_ROOF_CODE_MAP[prevRoofType]]
                ?.label ?? "N/A"
            : "N/A",
          updatedDisplayValue: PROPERTY_ROOF_UPDATE_TYPE[roofMaterial].label,
        },
      ],
    };
    formData.append("request", JSON.stringify(request));

    for (const file of files) {
      formData.append("file", file);
    }

    try {
      const response = await fetch(
        `${CONFIG.KEYSTONE_PROXY_HREF}/api/property/update`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        startPolling(POLLING_INTERVAL);
        setPollingTimeout(
          setTimeout(() => {
            stopPolling();
            formNavigate("../success");
          }, POLLING_TIMEOUT)
        );
      } else {
        setSubmitting(false);
        setError(response?.statusText);
        logError(`Roof update submission error: ${response?.statusText}`);
        trackError({
          errorMessage: response?.statusText,
          errorType: "server_error",
        });
      }
    } catch (err) {
      setSubmitting(false);
      setError(err);
      logError(`Roof update submission error: (${err?.name}: ${err?.message})`);
      trackError({
        errorCode: err?.code,
        errorMessage: err?.message,
        errorType: "system_error",
      });
    }
  };

  if (submitting) {
    return (
      <div className={styles.loadingSpinner}>
        <SpinnerLoader firstMessage="Hang tight, we're sending your updates..." />
      </div>
    );
  }

  return (
    <PropertyUpdateLayout
      step={4}
      numSteps={4}
      heading="Update roof details"
      buttonProps={{
        primaryOnClick: onSubmit,
        primaryText: "Yes, submit",
      }}
    >
      <div className={styles.roofReview}>
        <Heading size="md" className={styles.reviewHeading}>
          Does this look right?
        </Heading>
        {error ? (
          <Alert
            appearance="danger"
            title="There was an error submitting your update."
            description={`Please try again. If the problem persists, contact support at ${supportPhoneNumber}.`}
          />
        ) : null}
        <Span>
          Year replaced: <Span bold>{state?.roofYear}</Span>
        </Span>
        <Span>
          Material:{" "}
          <Span bold>
            {PROPERTY_ROOF_UPDATE_TYPE[state?.roofMaterial].label}
          </Span>
        </Span>
        <div>
          <Span>Proof of update:</Span>
          <FileThumbnails files={state?.files} />
        </div>
      </div>
    </PropertyUpdateLayout>
  );
};
