import { useNavigate } from "react-router-dom";

import { Button, Heading, Span, addToast } from "@icg360/design-system";

import styles from "./property-update-completed.module.scss";

const TEN_SECONDS = 10000;

export const PropertyUpdateCompleted = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.completed}>
      <img
        className={styles.checkGif}
        src="/assets/images/check.gif"
        alt="green check mark animation"
      />
      <Heading size="md">Thanks! We&apos;ve got it.</Heading>
      <Span>
        We&apos;ll get back to you with an update in the next few days.
      </Span>
      <Button
        onPress={() => {
          navigate("/my/property");
          addToast("Your update has been submitted.", {
            icon: true,
            duration: TEN_SECONDS,
          });
        }}
      >
        Done
      </Button>
    </div>
  );
};
