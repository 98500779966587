import cx from "classnames";
import { type HTMLAttributes, type PropsWithChildren } from "react";

import styles from "./body.module.scss";

type BodyProps = PropsWithChildren<HTMLAttributes<HTMLParagraphElement>> & {
  small?: boolean;
  align?: "left" | "right" | "center";
};

export const Body = ({
  children,
  small,
  align,
  className,
  ...props
}: BodyProps) => (
  <p
    className={cx(
      styles.body,
      className,
      small ? styles.small : "",
      align ? styles[align] : ""
    )}
    {...props}
  >
    {children}
  </p>
);
