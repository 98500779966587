import { Link as DSLink, Modal } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./contact-us-modal.module.scss";

type ContactUsModalProps = {
  show: boolean;
  handleClose: () => void;
};

export const ContactUsModal = ({ show, handleClose }: ContactUsModalProps) => {
  return (
    <Modal open={show} onClose={handleClose} noFooter heading="Contact Us">
      <div className={styles.modalContent}>
        <div>
          <div className={styles.modalBold}>
            Having questions about your policy or wanting to make changes?
          </div>
          <div>
            Contact your insurance representative, the agency or broker listed
            on your policy declaration page.
          </div>
        </div>
        <div className={styles.modalBold}>
          For any other questions, contact SageSure Customer Service at{" "}
          <DSLink
            href="tel: (800) 481-0661"
            onPress={() => trackEvent("landingV2_contact_phoneNumberClicked")}
          >
            (800) 481-0661
          </DSLink>
        </div>
        <div>
          <div className={styles.modalBold}>Business hours</div>
          <div>Monday-Friday: 8am - 9pm ET</div>
          <div>Saturday: 10am - 3pm ET</div>
        </div>
      </div>
    </Modal>
  );
};
