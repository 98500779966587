import { Field, useFormikContext } from "formik";

import { Button, Heading, Span } from "@icg360/design-system";

import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { PAYMENT_METHOD_CODE } from "consts";
import AcceptedCards from "images/accepted-cards.svg";
import { type EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form";
import { type EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";

import styles from "./choose-method.module.scss";

type ChooseMethodProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const ChooseMethod = ({ setStep }: ChooseMethodProps) => {
  const {
    values: { easypayMethod },
    setFieldValue,
  } = useFormikContext<EasyPayFormValues>();
  const nextStep = easypayMethod.includes("cc") ? "cardInfo" : "bankInfo";

  return (
    <>
      <Heading size="lg">Choose your payment method</Heading>
      <Field
        component={RadioButtonGroup}
        onChange={(value) => {
          setFieldValue("easypayMethod", value);
        }}
        value={easypayMethod}
        fieldChildren={() => (
          <>
            <div className={styles.radioAndDescription}>
              <div className={styles.radio}>
                <Field
                  value={PAYMENT_METHOD_CODE.EASYPAY}
                  label="Bank account (ACH)"
                  component={RadioButtonField}
                />
              </div>
              <div className={styles.description}>
                <div>Pay directly from your bank account</div>
                <Span bold>No processing fee.</Span>
              </div>
            </div>

            <div className={styles.radioAndDescription}>
              <div className={styles.acceptedCards}>
                <div className={styles.radio}>
                  <Field
                    value={PAYMENT_METHOD_CODE.EASYPAYCC}
                    label="Credit / debit card"
                    component={RadioButtonField}
                  />
                </div>
                <AcceptedCards className={styles.acceptedCardsImg} />
              </div>
              <div className={styles.description}>
                <div>Pay with an accepted credit or debit Card</div>
                <Span bold>2.99% processing fee per payment.</Span>
              </div>
            </div>
          </>
        )}
      />
      <div className={styles.navigation}>
        <Button
          appearance="tertiary"
          onPress={() => setStep("chooseDay")}
          data-testid="easypay-method-back-btn"
        >
          Go back
        </Button>

        <Button
          onPress={() => setStep(nextStep)}
          data-testid="easypay-method-continue-btn"
        >
          Continue
        </Button>
      </div>
    </>
  );
};
