import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Alert } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { SelfServiceCenter } from "pages/documents/documents/self-service-center";
import { scrollToTop, trackEvent } from "utils";

import { AllPolicyDocs } from "./documents/all-policy-docs";
import { PaperlessDocumentsEnrollWidget } from "./documents/paperless-documents-enroll-widget";
import { PolicyDocs } from "./documents/policy-docs";

export const Documents = () => {
  const { isPolicyDataReady } = useContext(AuthAppContext);

  const [searchParams] = useSearchParams();
  const documentsSigned = searchParams.get("documents_signed") === "true";

  useEffect(() => {
    if (documentsSigned) {
      trackEvent("documents_selfServiceCenter_signSuccess");
    }
  }, [documentsSigned]);

  useEffect(() => {
    trackEvent("Documents");
    scrollToTop();
  }, []);

  if (!isPolicyDataReady) {
    return <BaseLayout loading />;
  }

  const Sidebar = () => (
    <Stack>
      <PaperlessDocumentsEnrollWidget />
      <InsuranceRepresentative />
      <FaqSidebar page="documents" />
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={<Sidebar />}>
        <Stack>
          {documentsSigned ? (
            <Alert
              title="Thank you!"
              description="We've successfully received your signed documents."
              appearance="success"
            />
          ) : null}
          <SelfServiceCenter />
          <PolicyDocs />
          <AllPolicyDocs />
        </Stack>
      </MainLayout>
    </BaseLayout>
  );
};
