import { useContext, useState } from "react";

import { Button, Card } from "@icg360/design-system";

import { PaperlessModal } from "components/paperless-modal";
import {
  AuthAppContext,
  AuthAppDispatchContext,
} from "components/root/auth-app-provider";
import { ENROLLMENT_STATUS, type PageName } from "consts";
import { trackEvent } from "utils";

import styles from "./paperless-card.module.scss";

export type PaperlessCardUnenrolledProps = {
  pageName: PageName;
};

export const PaperlessCardUnenrolled = ({
  pageName,
}: PaperlessCardUnenrolledProps) => {
  const { userDetails, userBilling, selectedPolicyId } =
    useContext(AuthAppContext);
  const { refetchPolicy } = useContext(AuthAppDispatchContext);

  const [showModal, setShowModal] = useState(false);
  const openPaperlessModal = () => {
    trackEvent("Paperless Banner Enroll Button Clicked", {
      pageName,
      paperlessDocumentEnrollment:
        userDetails?.insuredPreferences?.paperlessDocumentEnrollmentStatus ??
        ENROLLMENT_STATUS.NOT_ENROLLED,
      paperlessBillingEnrollment:
        userDetails?.insuredPreferences?.paperlessBillingEnrollmentStatus ??
        ENROLLMENT_STATUS.NOT_ENROLLED,
    });
    setShowModal(true);
  };
  return (
    <>
      <Card leadingIcon="Paperless" title="Paperless">
        <div className={styles.content}>
          <p>Skip the extra mail and help plant trees by going paperless.</p>
          <p>
            When you sign up, we&apos;ll donate $1 to the Arbor Day Foundation
            to plant trees in neighborhoods devastated by natural disasters.
          </p>
          <div>
            <Button
              appearance="secondary"
              size="sm"
              onPress={openPaperlessModal}
              data-testid="enrollInPaperlessButton"
            >
              Enroll in Paperless
            </Button>
          </div>
        </div>
      </Card>
      <PaperlessModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        documentsEmailAddress={
          userDetails?.insuredPreferences?.opInsuredEmailAddressDocuments ??
          userDetails?.insuredPreferences?.opInsuredEmailAddress ??
          ""
        }
        userDetails={userDetails}
        userBilling={userBilling}
        refetch={refetchPolicy}
        policyId={selectedPolicyId}
      />
    </>
  );
};
