import cx from "classnames";
import { type PropsWithChildren, type ReactElement } from "react";

import { ExternalHeader } from "components/external-header";
import { IEModal } from "components/ie-warning-modal";
import { PageFooter } from "components/shared/page-footer";
import { UnauthHeader } from "components/shared/unauth-header";

import styles from "./unauth-layout.module.scss";

type UnauthLayoutProps = PropsWithChildren<{
  size?: "lg" | "md";
  headerElement?: ReactElement;
  hideNav?: boolean;
}>;

const UnauthLayout = ({
  children,
  size = "md",
  headerElement,
  hideNav,
}: UnauthLayoutProps) => {
  return (
    <>
      <IEModal
        title={"It looks like you're using Internet Explorer"}
        line1="As Microsoft transitions to Edge, SageSure is no longer supporting Internet Explorer. This means that parts of the website may not work properly."
        line2="For the best experience, we recommend downloading, installing and using one of these browsers: (click the links below to be taken to the download page where you can download and then install the browser)"
      />
      <ExternalHeader returnUrlParsed />
      <UnauthHeader includeNav={!hideNav} rightElement={headerElement} />
      <div className={styles.unauthLayoutWrapper}>
        <div
          className={cx(
            styles.unauthLayoutContent,
            size === "lg" ? styles.large : null
          )}
        >
          {children}
        </div>
      </div>
      <PageFooter />
    </>
  );
};

export default UnauthLayout;
