import { useState } from "react";

import { Button } from "@icg360/design-system";

import { trackEvent } from "utils";

import ChangePasswordModal from "./change-password-modal";

type ChangePasswordProps = {
  userEmail: string;
  userName?: string;
};

const ChangePassword = ({ userEmail, userName }: ChangePasswordProps) => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const showModal = () => {
    setShowChangePasswordModal(true);
    trackEvent("Change password modal displayed");
  };

  return (
    <>
      <Button
        appearance="secondary"
        onPress={showModal}
        data-testid="password-change-btn"
      >
        Change password
      </Button>
      <ChangePasswordModal
        show={showChangePasswordModal}
        handleClose={() => setShowChangePasswordModal(false)}
        userEmail={userEmail}
        userName={userName}
      />
    </>
  );
};

export default ChangePassword;
