import cx from "classnames";
import { type PropsWithChildren } from "react";

import styles from "./data-row.module.scss";

type BaseProps = PropsWithChildren<{ className?: string }>;

type DataListProps = BaseProps & { gap?: "xxs" | "xs" | "sm" | "md" | "lg" };

const DataList = ({ children, className, gap = "md" }: DataListProps) => {
  return (
    <dl
      className={cx(
        styles.dataList,
        {
          [styles.gapXxs]: gap === "xxs",
          [styles.gapXs]: gap === "xs",
          [styles.gapSm]: gap === "sm",
          [styles.gapMd]: gap === "md",
          [styles.gapLg]: gap === "lg",
        },
        className
      )}
    >
      {children}
    </dl>
  );
};

type DataRowProps = BaseProps & {
  flex?: "col" | "column" | "row";
  noWrap?: boolean;
  mobileFlex?: "col" | "column" | "row";
  spaceBetween?: boolean;
};

const DataRow = ({
  children,
  className,
  flex,
  mobileFlex,
  noWrap,
  spaceBetween,
}: DataRowProps) => {
  return (
    <div
      className={cx(
        styles.dataRow,
        {
          [styles.flex]: flex,
          [styles.noWrap]: noWrap,
          [styles.mobileFlexColumn]:
            mobileFlex === "col" || mobileFlex === "column",
          [styles.flexColumn]: flex === "col" || flex === "column",
          [styles.spaceBetween]: spaceBetween,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

const InlineDataRow = ({ children, className }: BaseProps) => {
  return (
    <div className={cx(styles.dataRow, styles.inline, className)}>
      {children}
    </div>
  );
};

const DataTerm = ({ children, className }: BaseProps) => {
  return <dt className={cx(styles.dataTerm, className)}>{children}</dt>;
};

const DataDefinition = ({ children, className }: BaseProps) => {
  return <dd className={cx(styles.dataDefinition, className)}>{children}</dd>;
};

export { DataRow, InlineDataRow, DataList, DataTerm, DataDefinition };
