import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Button, Link as DSLink, Heading } from "@icg360/design-system";

import UnauthLayout from "components/shared/unauth-layout";
import { CLAIMS_SUPPORT } from "consts";
import { trackEvent } from "utils";

import styles from "./claims-help.module.scss";

export const GetHelp = () => {
  const navigate = useNavigate();
  const clickLogin = () => {
    trackEvent("landingV2_claims_loginClicked");
    navigate("/");
  };

  const tips = [
    {
      title: "Confirm if your loss is covered",
      body: (
        <>
          One of the first{" "}
          <DSLink
            href="https://www.sagesure.com/insurance-insights/take-the-claims-process-one-step-at-a-time/"
            onPress={() => trackEvent("landingV2_claims_stepsClicked")}
          >
            steps in the claim process
          </DSLink>{" "}
          is confirming that your policy covers your loss. You can log in to
          view your policy, or give us a call so we can review it together.
        </>
      ),
    },
    {
      title: "Check your deductible",
      body: "Your deductible is the amount you’ll pay out of pocket before your insurance policy covers the remaining loss. If the amount of your deductible is more than the cost of your loss, you may decide not to file a claim. If you have questions, we can help. Give us a call.",
    },
    {
      title: "File your claim online or by phone",
      body: "Once you file your claim, we’ll get to work right away, and make sure you know who to contact if you have questions.",
    },
    {
      title: "Let us help you",
      body: (
        <>
          While this might be the first time you’re filing a claim, it’s what we
          do every day. We’re here to help. You can reach us anytime by phone,
          or learn more about the claims process{" "}
          <DSLink
            href="https://www.sagesure.com/insurance-insights/take-the-claims-process-one-step-at-a-time/"
            onPress={() => trackEvent("landingV2_claims_learnClicked")}
          >
            here.
          </DSLink>
        </>
      ),
    },
  ];

  return (
    <UnauthLayout size="lg">
      <Heading size="lg">We&apos;re here to help you</Heading>
      <div>
        Filing a claim only takes about 10 minutes. Log in to let us know what
        happened and get the process started.
      </div>
      <div className={styles.loginRow}>
        <Button
          onPress={clickLogin}
          className={classNames(styles.cols, styles.loginButton)}
        >
          Log in
        </Button>
        <span className={styles.cols}>
          or call &nbsp;
          <DSLink
            href={`tel:${CLAIMS_SUPPORT}`}
            onPress={() => trackEvent("landingV2_claims_phoneNumberClicked")}
          >
            {CLAIMS_SUPPORT}
          </DSLink>
        </span>
      </div>
      <Heading size="lg">Here are some tips:</Heading>
      {tips.map((tip) => (
        <div key={tip.title}>
          <Heading size="sm">{tip.title}</Heading>
          <div>{tip.body}</div>
        </div>
      ))}
    </UnauthLayout>
  );
};
