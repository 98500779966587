import { type PropsWithChildren } from "react";

import { Heading } from "@icg360/design-system";

import ProgressBar from "pages/shingle-repair-pilot/progress-bar";

import styles from "./shingle-repair-pilot.module.scss";

type SingleRepairLayoutProps = PropsWithChildren<{
  title: string;
  description?: string;
  stepNumber: number;
}>;

const ShingleRepairLayout = ({
  title,
  description,
  stepNumber,
  children,
}: SingleRepairLayoutProps) => {
  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.progressBarWrapper}>
        <ProgressBar stepCount={5} completeCount={stepNumber} />
      </div>
      <Heading size="lg">{title}</Heading>
      {description ? <div>{description}</div> : null}
      {children}
    </div>
  );
};

export default ShingleRepairLayout;
