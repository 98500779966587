import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, Span, SpotIcon } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  type ShingleResealContextState,
} from "pages/shingle-reseal/shingle-reseal-flow";
import { ShingleResealLayout } from "pages/shingle-reseal/shingle-reseal-layout";
import { formatTwoLineAddress } from "utils";

import styles from "./shingle-reseal.module.scss";

export const ContactInformationConfirmation = () => {
  const { policy } = useContext(AuthAppContext);
  const navigate = useNavigate();
  const { state } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);

  useEffect(() => {
    if (
      !(
        state?.phone &&
        state?.firstName &&
        state?.lastName &&
        state?.emailAddress
      )
    ) {
      navigate("../policy-confirmation");
    }
  }, [
    navigate,
    state?.emailAddress,
    state?.firstName,
    state?.lastName,
    state?.phone,
  ]);

  if (!policy.propertyAddress) {
    return null;
  }

  const { lineOne, lineTwo } = formatTwoLineAddress(
    policy.propertyAddress,
    true
  );

  return (
    <ShingleResealLayout
      step={3}
      heading="Please review and confirm your details"
      buttonProps={{ primaryPath: "../waiver-sagesure" }}
    >
      <div className={styles.mainContent}>
        <div className={styles.iconItemSm}>
          <SpotIcon name="Home" size="xs" appearance="bold" />
          <div>
            <Heading size="sm">{lineOne}</Heading>
            <Heading size="sm">{lineTwo}</Heading>
            <div className={styles.subtext}>
              Policy number: {policy.policyId}
            </div>
          </div>
        </div>
        <div className={styles.iconItemSm}>
          <SpotIcon name="User" size="xs" appearance="bold" />
          <div>
            <Span bold>
              {state?.firstName} {state?.lastName}
            </Span>
            <div className={styles.subtext}>{state?.emailAddress}</div>
            <div>{state?.phone}</div>
          </div>
        </div>
      </div>
    </ShingleResealLayout>
  );
};
