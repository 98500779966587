import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";

import { TelephoneMask, TextInput } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { ShingleResealButtons } from "pages/shingle-reseal/shingle-reseal-buttons";
import {
  ShingleContext,
  type ShingleResealContextState,
} from "pages/shingle-reseal/shingle-reseal-flow";
import { ShingleResealLayout } from "pages/shingle-reseal/shingle-reseal-layout";

import styles from "./shingle-reseal.module.scss";

const schema = yupObject()
  .shape({
    firstName: yupString().required("Please enter your first name."),
    lastName: yupString().required("Please enter your last name."),
    emailAddress: yupString()
      .required("Please enter an email address.")
      .email("Please enter a valid email address."),
    phone: yupString()
      .required("Please enter a valid phone number.")
      .matches(
        /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a valid 10 digit phone number"
      ),
  })
  .required();

type ContactFormFields = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
};

export const ContactInformation = () => {
  const { userInfo, userDetails } = useContext(AuthAppContext);
  const { state, setFieldsAndNavigate } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);

  // phone isn't in the default values here
  // but is passed in below because of how the PhoneNumber mask works
  const defaultValues = {
    firstName: state?.firstName ?? userInfo?.firstName ?? "",
    lastName: state?.lastName ?? userInfo?.lastName ?? "",
    emailAddress:
      state?.emailAddress ?? userDetails?.primaryInsured?.emailAddress ?? "",
    // phone: state?.phone ?? "", // TODO: prepopulated phone number? claims constants???
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ContactFormFields>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    setFieldsAndNavigate("../contact-information-confirmation", { ...data });
  };

  return (
    <ShingleResealLayout
      step={2}
      heading="How can LiftLock reach you?"
      subheading="In the event of a hurricane near you, LiftLock will reach out if your property is selected for shingle resealing."
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.contactInformationForm}
      >
        <div className={styles.contactNameFields}>
          <div className={styles.contactNameField}>
            <TextInput
              label="First name"
              {...register("firstName")}
              errorMessage={errors.firstName?.message}
              isError={!!errors.firstName}
              data-testid="shingle-firstname"
            />
          </div>
          <div className={styles.contactNameField}>
            <TextInput
              label="Last name"
              {...register("lastName")}
              errorMessage={errors.lastName?.message}
              isError={!!errors.lastName}
              data-testid="shingle-lastname"
            />
          </div>
        </div>
        <TextInput
          label="Email"
          {...register("emailAddress")}
          errorMessage={errors.emailAddress?.message}
          isError={!!errors.emailAddress}
          data-testid="shingle-email"
        />
        <TelephoneMask
          label="Phone number"
          {...register("phone")}
          errorMessage={errors.phone?.message}
          isError={!!errors.phone}
          value={state?.phone?.replace(/\D+/g, "") ?? ""}
          data-testid="shingle-phone"
        />
        <div className={styles.contactInformationButtons}>
          <ShingleResealButtons
            primaryType="submit"
            primaryDisabled={!isValid}
          />
        </div>
      </form>
    </ShingleResealLayout>
  );
};
