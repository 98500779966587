import { useContext } from "react";

import { Card, Heading } from "@icg360/design-system";

import { EmailIconLink, PhoneIconLink } from "components/common/link";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { formatPhone, isFormattedPhone } from "utils";

type InsuranceRepresentativeProps = {
  border?: boolean;
};

export const InsuranceRepresentative = ({
  border = false,
}: InsuranceRepresentativeProps) => {
  const { userInsuranceRep } = useContext(AuthAppContext);
  const {
    agencyName,
    agentStreetNumber,
    agentStreetName,
    agentZipCode,
    agentCity,
    agentState,
    agentEmail,
    agentPhone: unFormattedPhone,
  } = userInsuranceRep ?? {};

  const agentPhone = isFormattedPhone(unFormattedPhone)
    ? unFormattedPhone
    : formatPhone(unFormattedPhone || "");

  return (
    <Card border={border}>
      <Stack>
        <Heading size="md">Insurance representative</Heading>
        <p>
          Your insurance representative can assist with any questions or
          concerns about your policy.
        </p>

        <div>
          <div>{agencyName}</div>
          <div>
            {/* agent possibly missing street address */}
            {agentStreetNumber || agentStreetName ? (
              <>
                {`${agentStreetNumber} ${agentStreetName}`}
                <br />
              </>
            ) : null}
            <div>{`${agentCity}, ${agentState} ${agentZipCode}`}</div>
          </div>
        </div>
        <Stack gap="sm">
          <EmailIconLink email={agentEmail || ""} />
          <PhoneIconLink phone={agentPhone || ""} />
        </Stack>
      </Stack>
    </Card>
  );
};
