import { type FieldProps } from "formik";
import get from "lodash.get";
import { type ReactNode, useRef } from "react";

import { TextInput, type TextInputProps } from "@icg360/design-system";

import { scrollToError } from "utils";

type InputFieldProps = Omit<
  TextInputProps,
  "isError" | "name" | "errorMessage" | "id" | "value" | "label" | "error"
> & {
  field: FieldProps["field"];
  form: FieldProps["form"];
  title: ReactNode;
};

export const InputField = ({
  field: { name, value, onChange: formikOnChange },
  form: { errors, touched, setFieldTouched, isSubmitting },
  title,
  type = "text",
  onChange,
  ...props
}: InputFieldProps) => {
  const errorsField = get(errors, name);
  const hasError = !!(errorsField && get(touched, name));

  const inputRef = useRef(null);
  scrollToError(name, inputRef, hasError, isSubmitting, errors);

  return (
    <TextInput
      name={name}
      id={name}
      value={value}
      onChange={(e) => {
        setFieldTouched(name, true, true);
        formikOnChange(e);
        onChange?.(e);
      }}
      type={type}
      label={title ? <span ref={inputRef}>{title}</span> : null}
      isError={hasError}
      errorMessage={errorsField}
      {...props}
    />
  );
};
