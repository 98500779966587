import { Icon, Span } from "@icg360/design-system";

import { RegularContentLoader } from "components/loader";
import { useSecurityDataQuery } from "gql/__generated__/hooks";
import GoogleIcon from "images/icon-google.svg";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";
import { authUser } from "utils";

import ChangePassword from "./change-password";
import styles from "./security.module.scss";

const Security = () => {
  const user = authUser();
  const { data: SecurityData, loading } = useSecurityDataQuery({
    variables: {
      email: user?.email ?? "",
    },
  });

  if (loading || !user) return <RegularContentLoader />;

  const { userBasicInfo: userInfo } = SecurityData ?? {};
  const showGoogleEnabled = userInfo?.authProvider === "GOOGLE";

  return (
    <SettingsPanelLayout title="Security">
      {showGoogleEnabled ? (
        <div className={styles.googleInfo}>
          <div className={styles.googleRow}>
            <div className={styles.icon}>
              <GoogleIcon />
            </div>
            <Span>Sign in with Google is enabled.</Span>
          </div>
          <div className={styles.googleRow}>
            <div className={styles.icon}>
              <Icon name="Lock" />
            </div>
            <Span>Log in to your Google account to update your password.</Span>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.dataRow}>
            <Span bold>User ID:</Span>
            <Span>{userInfo?.email || userInfo?.userName}</Span>
          </div>
          <div className={styles.dataRow}>
            <Span bold>Password:</Span>
            <Span className={styles.password}>
              <ChangePassword
                userEmail={userInfo?.email ?? ""}
                userName={userInfo?.userName ?? undefined}
              />
            </Span>
          </div>
        </>
      )}
    </SettingsPanelLayout>
  );
};

export default Security;
