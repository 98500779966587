import { Field, Form, Formik, useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";

import { Button, Span } from "@icg360/design-system";

import { CheckboxField, InputField } from "components/shared/form-fields";
import { emailSettingsSchema } from "consts";
import {
  type ContactItemDataQuery,
  type PolicyPaperlessDataQuery,
  type UserBillingAddressQuery,
  useUpdateInsuredInfoMutation,
  useUpdatePaperlessDocumentsEmailMutation,
  useUpdatePaperlessEmailMutation,
} from "gql/__generated__/hooks";
import {
  contactItemDataQuery,
  policyPaperlessDataQuery,
  userBillingAddressQuery,
} from "gql/queries";
import { trackEvent } from "utils";

import styles from "./update-email.module.scss";

const initialValues = {
  newInsuredEmail: "",
  confirmInsuredEmail: "",
  applyToPaperless: true,
};

type UpdateEmailProps = {
  enrolledBilling: boolean;
  enrolledDocuments: boolean;
  policyId: string;
  userEmail: string;
  onContactInfoUpdateSuccess: () => void;
  onContactInfoUpdateError: () => void;
  onCancelForm: () => void;
};

const UpdateEmail = ({
  enrolledBilling,
  enrolledDocuments,
  policyId,
  userEmail,
  onContactInfoUpdateSuccess,
  onContactInfoUpdateError,
  onCancelForm,
}: UpdateEmailProps) => {
  const [updateEmail] = useUpdateInsuredInfoMutation();
  const [updatePaperlessEmail] = useUpdatePaperlessEmailMutation();
  const [updatePaperlessDocumentsEmail] =
    useUpdatePaperlessDocumentsEmailMutation();

  const handleSubmit = async (
    { newInsuredEmail, applyToPaperless },
    { setSubmitting, resetForm }
  ) => {
    try {
      setSubmitting(true);
      let paperlessUpdateSucceeded = true;
      const userUpdates = { emailAddress: newInsuredEmail };

      const { data: updateEmailData } = await updateEmail({
        variables: {
          policyID: policyId,
          emailAddress: userEmail,
          userUpdates: userUpdates,
        },
        update: (store, { data }) => {
          const { updateInsuredInfo } = data ?? {};
          if (updateInsuredInfo) {
            const readData = cloneDeep(
              store.readQuery<ContactItemDataQuery>({
                query: contactItemDataQuery,
                variables: { policyID: policyId },
              })
            );

            const { emailAddress } = userUpdates;

            if (emailAddress && readData) {
              readData.userDetails = {
                ...readData.userDetails,
                primaryInsured: {
                  ...readData.userDetails?.primaryInsured,
                  emailAddress: emailAddress,
                },
              };

              store.writeQuery({
                query: contactItemDataQuery,
                data: readData,
                variables: { policyID: policyId },
              });
            }
          }
        },
        context: {
          clientName: "keystone-api",
        },
      });
      const { updateInsuredInfo } = updateEmailData ?? {};
      if (updateInsuredInfo && applyToPaperless) {
        if (enrolledBilling) {
          const { data: updatePaperlessEmailData } = await updatePaperlessEmail(
            {
              variables: {
                opInsuredEmailAddressBilling: newInsuredEmail,
                policyID: policyId,
              },
              update: (store, { data: successful }) => {
                if (successful) {
                  const readData = cloneDeep(
                    store.readQuery<UserBillingAddressQuery>({
                      query: userBillingAddressQuery,
                      variables: { policyID: policyId },
                    })
                  );

                  if (readData) {
                    readData.userBillingAddress = newInsuredEmail;

                    store.writeQuery({
                      query: userBillingAddressQuery,
                      variables: { policyID: policyId },
                      data: readData,
                    });
                  }
                }
              },
            }
          );

          paperlessUpdateSucceeded =
            !!updatePaperlessEmailData?.updatePaperlessEmail;
        }

        if (enrolledDocuments) {
          const { data: updatePaperlessDocumentsEmailData } =
            await updatePaperlessDocumentsEmail({
              variables: {
                opInsuredEmailAddressDocuments: newInsuredEmail,
                policyID: policyId,
              },
              update: (store, { data }) => {
                if (data?.updatePaperlessDocumentsEmail) {
                  const readData = cloneDeep(
                    store.readQuery<PolicyPaperlessDataQuery>({
                      query: policyPaperlessDataQuery,
                      variables: { policyID: policyId },
                    })
                  );

                  if (readData?.userDetails?.insuredPreferences) {
                    readData.userDetails.insuredPreferences.opInsuredEmailAddressDocuments =
                      newInsuredEmail;

                    store.writeQuery({
                      query: policyPaperlessDataQuery,
                      variables: { policyID: policyId },
                      data: readData,
                    });
                  }
                }
              },
            });

          paperlessUpdateSucceeded =
            paperlessUpdateSucceeded &&
            !!updatePaperlessDocumentsEmailData?.updatePaperlessDocumentsEmail;
        }
      }

      if (updateInsuredInfo && paperlessUpdateSucceeded) {
        onContactInfoUpdateSuccess();
        resetForm();
        trackEvent("Email Address Updated", {
          updatePaperlessEmail:
            enrolledBilling || enrolledDocuments ? applyToPaperless : null,
        });
      } else {
        trackEvent("Error Displayed (Update Contact Email)");
        onContactInfoUpdateError();
      }
      setSubmitting(false);
    } catch (err) {
      trackEvent("Error Displayed (Update Contact Email)", err);
      onContactInfoUpdateError();
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={emailSettingsSchema}
    >
      <UpdateEmailForm
        enrolledBilling={enrolledBilling}
        enrolledDocuments={enrolledDocuments}
        onCancelForm={onCancelForm}
      />
    </Formik>
  );
};

export default UpdateEmail;

type UpdateEmailFormProps = {
  enrolledBilling: boolean;
  enrolledDocuments: boolean;
  onCancelForm: () => void;
};

const UpdateEmailForm = ({
  enrolledBilling,
  enrolledDocuments,
  onCancelForm,
}: UpdateEmailFormProps) => {
  const { isValid, dirty, isSubmitting } = useFormikContext();
  return (
    <Form className={styles.formWrapper}>
      <div className={styles.fields}>
        <Field
          component={InputField}
          name="newInsuredEmail"
          title="New insured email"
        />
        <Field
          component={InputField}
          name="confirmInsuredEmail"
          title="Confirm insured email"
        />
      </div>
      <Span bold>Communication preferences</Span>
      <p>
        This email is used for claims updates, payment reminders, and
        notifications about your account.
      </p>
      {enrolledBilling ? (
        <div className={styles.applyToPaperless}>
          <Field
            component={CheckboxField}
            name="applyToPaperless"
            label={
              enrolledDocuments
                ? "Receive policy documents and billing statements. This will update all emails in paperless settings."
                : "Receive billing statements. This will update your existing billing email."
            }
          />
        </div>
      ) : null}
      <div className={styles.buttonWrapper}>
        <Button
          appearance="tertiary"
          onPress={onCancelForm}
          data-testid="contact-email-update-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!isValid || !dirty || isSubmitting}
          data-testid="contact-email-update-btn"
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};
