import cx from "classnames";
import { Field, Form, useFormikContext } from "formik";
import { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";

import { Button, Link as DSLink, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import { Stack } from "components/common/stack";
import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { setMixpanelSuperProperties, trackEvent } from "utils";

import styles from "./ceremony-link-form.module.scss";

type CeremonyLinkFormSchema = {
  selectedEmail: string;
};

type CeremonyLinkFormProps = {
  emailList: string[];
  policyNumber: string;
};

const CeremonyLinkForm = ({
  emailList,
  policyNumber,
}: CeremonyLinkFormProps) => {
  setMixpanelSuperProperties({
    policyNumber,
    listOfEmails: emailList,
  });

  const navigate = useNavigate();
  const { values, isValid, setFieldValue } =
    useFormikContext<CeremonyLinkFormSchema>();

  useEffect(() => {
    trackEvent("GEICO: Link Account Page Loaded");
  }, []);

  const [firstEmail] = emailList;

  return (
    <Form className={cx("form-inline", styles.formWrapper)}>
      <Heading size="md" className={styles.formTitle}>
        {`Existing SageSure account${emailList.length > 1 ? "s" : ""}`}
      </Heading>
      <Body className={styles.description}>
        {emailList.length > 1
          ? `We found existing SageSure accounts for policy number ${policyNumber}
      listed under the following email addresses:`
          : `We found an existing SageSure account for policy number ${policyNumber}
      listed under the following email address:`}
      </Body>
      {emailList.length > 1 ? (
        <Row className={styles.radioWrapper}>
          <Col>
            <Field
              component={RadioButtonGroup}
              onChange={(value) => {
                setFieldValue("selectedEmail", value);
              }}
              value={values.selectedEmail}
              fieldChildren={() =>
                emailList.map((email) => (
                  <Field
                    key={email}
                    value={email}
                    label={email}
                    component={RadioButtonField}
                  />
                ))
              }
            />
          </Col>
        </Row>
      ) : (
        <p className={styles.singleEmail}>{firstEmail}</p>
      )}

      <Body className={styles.promptText}>
        {emailList.length > 1
          ? `Do you want to link GEICO to
        one of these accounts?`
          : `Do you want to link GEICO to this
        account?`}
      </Body>
      <Stack gap="lg">
        <Button
          type="submit"
          disabled={!isValid}
          data-testid="ceremony-submit-btn"
          fluid
        >
          Link accounts
        </Button>
        <DSLink
          onPress={() => {
            if (emailList.length < 2) {
              trackEvent("GEICO: Create Account Button Clicked");
            }
            navigate("/ceremony/register");
          }}
        >
          Create a new account
        </DSLink>
      </Stack>
    </Form>
  );
};

export default CeremonyLinkForm;
