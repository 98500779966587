import { useEffect, useState } from "react";

import { POOL_CARDS, type PoolFormStep } from "consts";
import { scrollToTop, trackEvent } from "utils";

export const useUpdatePool = ({ status, values }) => {
  const [shownCard, setShownCard] = useState<PoolFormStep>("poolType");
  const [review, setReview] = useState(false);
  const [confirmNone, setConfirmNone] = useState(false);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    trackEvent(`Property pool - ${POOL_CARDS[shownCard]} clicked`, {
      ...values,
      pool: values.poolType,
    });
  }, [shownCard, values]);

  useEffect(() => {
    if (confirmNone) {
      trackEvent("Property pool - Sign attestation page load");
    }
  }, [confirmNone]);

  useEffect(() => {
    if (status && status.type === "error") {
      setError(
        "There was an error. Please try again or contact support if this persists."
      );
    }
  }, [status]);

  useEffect(() => {
    setError(null);
    scrollToTop();
    if (review) {
      trackEvent("Property updates review page load");
    }
  }, [review]);

  return {
    setShownCard,
    setReview,
    setError,
    setConfirmNone,
    shownCard,
    review,
    confirmNone,
    error,
  };
};
