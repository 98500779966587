import { useContext } from "react";

import { Button } from "@icg360/design-system";

import { GlobalContext } from "components/root/global-provider";
import { useTriggerLiveChat } from "hooks/use-live-chat";
import { useFlags } from "utils";

type LiveChatButtonProps = {
  location: string;
};

const LiveChatButton = ({ location }: LiveChatButtonProps) => {
  const { liveChat } = useFlags();
  const { liveChatStatus } = useContext(GlobalContext);
  const triggerLiveChat = useTriggerLiveChat(location);

  if (!liveChat || liveChatStatus === "uninitialized") {
    return null;
  }

  return (
    <Button
      appearance="secondary"
      leadingIcon={liveChatStatus === "live" ? "Chat" : "Envelope"}
      size="xs"
      onPress={triggerLiveChat}
      data-testid={`live-chat-btn-${location}`}
    >
      {liveChatStatus === "live" ? "Live chat" : "Leave a message"}
    </Button>
  );
};

export default LiveChatButton;
