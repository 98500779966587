import { PaymentController } from "@icg360/payment-toolkit-v2";

import { CONFIG } from "config";

// This is just based on what we're passing in right now...
// Ideally, we'd be getting these types from payment-toolkit-v2
type MakePaymentParams = {
  minAmountDue: number;
  accountBalance: number;
  insightPolicyId: string;
  paymentApplicationClient: string;
  policyId: string;
  email: string;
  phone: string;
  quoteId: string;
  isScheduledPayEnabled: boolean;
  finalPayDate: string;
};

export type PayControl = {
  _payment_ready: boolean;
  _savecard_ready: boolean;
  _sessionId: string;
  isReady: () => boolean;
  addEventListener: (string, onEvent: (event) => void) => void;
  removeEventListener: (string, onEvent: (event) => void) => void;
  makePayment: (makePaymentParams: MakePaymentParams) => void;
};

export type PaymentConfig = {
  accountBalance: number;
  agencyLocationCode?: string | null;
  billingCity: string;
  billingState: string;
  billingStreet: string;
  billingZip: string;
  email: string;
  finalPayDate: string; // date?
  firstName: string;
  insightPolicyId: string;
  isScheduledPayEnabled: boolean;
  lastName: string;
  minAmountDue: number;
  paymentApplicationClient: string;
  phone: string;
  policyId: string;
  quoteId: string;
};

const config = {
  paymentsEndpoint: CONFIG.PAYMENT_API_HREF,
  production: CONFIG.APP_ENV === "production",
};

export let payControl: PayControl;

const waitForJquery = setInterval(() => {
  if (window.jQuery) {
    payControl = new PaymentController(config);
    clearInterval(waitForJquery);
  }
}, 50);
