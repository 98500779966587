import { useState } from "react";

import { Button, Card, Heading, Icon } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./inspection-review-sidebar.module.scss";

export const InspectionReviewSidebar = ({ setShowContactModal }) => {
  const [showText, setShowText] = useState(false);
  return (
    <Card border>
      <button
        className={styles.sidebarHeading}
        onClick={() => {
          setShowText(!showText);
          trackEvent("writeout_sideCardClicked");
        }}
      >
        <Heading size="sm">{`What if I can't fix the issue?`}</Heading>
        <Icon name={showText ? "ArrowUp" : "ArrowDown"} />
      </button>

      {showText ? (
        <div className={styles.textContent}>
          Failure to make repairs within the due date would result in policy
          cancellation.{" "}
          <Button
            appearance="link"
            className={styles.modalButtonDS}
            onPress={() => {
              setShowContactModal(true);
              trackEvent("writeout_contactClicked");
            }}
          >
            Contact SageSure
          </Button>{" "}
          or your agent if you do not want to proceed and we can work with your
          agent to explore other carriers that may meet your needs.
        </div>
      ) : null}
    </Card>
  );
};
