import { Auth0Client, type User } from "@auth0/auth0-spa-js";

import { CONFIG } from "config";
import {
  LegacyUserDataDocument,
  type LegacyUserDataQuery,
} from "gql/__generated__/hooks";
import { apolloClient, flagCheck, resetAnalytics } from "utils";

let user: User | undefined;
const auth0 = new Auth0Client({
  domain: CONFIG.AUTH0_DOMAIN,
  clientId: CONFIG.AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/my/overview`,
  },
  cache: {
    get(key) {
      return JSON.parse(sessionStorage.getItem(key) ?? "{}");
    },

    set(key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    },

    remove(key) {
      sessionStorage.removeItem(key);
    },

    allKeys() {
      return Object.keys(sessionStorage);
    },
  },
});

export async function handleAuth0Callback() {
  if (
    location.search.includes("state=") &&
    (location.search.includes("code=") || location.search.includes("error="))
  ) {
    await auth0.handleRedirectCallback();
    window.history.replaceState({}, document.title, "/");
  }
}

export async function login() {
  await auth0.loginWithRedirect();
}

export function logout() {
  resetAnalytics();
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}

export async function authToken() {
  if (await isLoggedIn()) {
    return auth0.getTokenSilently();
  }
  return null;
}

export function authUser() {
  return user;
}

export async function authHeaders(
  includeUniversalHeader = true
): Promise<Record<string, string>> {
  const [token, user] = await Promise.all([authToken(), authUser()]);
  const headers: Record<string, string> = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (includeUniversalHeader) {
    headers["x-universal-login"] = "?1";

    if (user) {
      headers["x-username"] = user.email ?? "";
    }
  }
  return headers;
}

export async function isLoggedIn() {
  if (flagCheck("universalLogin")) {
    const isAuth = await auth0.isAuthenticated();
    if (isAuth && !user) {
      user = await auth0.getUser();
    }
    return isAuth;
  }
  // This query combines isLoggedIn with the legacy userInfo. We don't
  // need to pass a policy ID because the server doesn't use it, but
  // the schema requires it.
  const res = await apolloClient.query<LegacyUserDataQuery>({
    query: LegacyUserDataDocument,
    fetchPolicy: "network-only",
    variables: {
      policyID: "NOT_USED",
    },
  });
  user = {
    email: res.data.userInfo?.email ?? undefined,
    given_name: res.data.userInfo?.firstName ?? undefined,
    family_name: res.data.userInfo?.lastName ?? undefined,
    preferred_username: res.data.userInfo?.userName ?? undefined,
  };
  return !!res.data.isLoggedIn?.loggedIn;
}
