import { type ReactNode } from "react";

import { Card, Heading, Icon, type UiIconNames } from "@icg360/design-system";

import styles from "./property-profile-sidebar.module.scss";

type PropertyProfileSidebarProps = {
  iconName?: UiIconNames;
  headline: string;
  body: ReactNode;
};

export const PropertyProfileSidebar = ({
  iconName = "Document",
  headline,
  body,
}: PropertyProfileSidebarProps) => {
  return (
    <Card className={styles.sidebar}>
      <Heading size="md" className={styles.iconHeader}>
        <div className={styles.icon}>
          <Icon name={iconName} />
        </div>
        {headline}
      </Heading>
      {body}
    </Card>
  );
};
