import { useContext, useEffect, useState } from "react";

import { Alert, Heading, Span } from "@icg360/design-system";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { ALARM_VALUE_DISPLAY_MAP, ALARM_VALUE_ENUM_MAP } from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { logError, useFlags } from "utils";

import {
  PropertyUpdateSecurityContext,
  type PropertyUpdateSecurityContextState,
} from ".";
import { FileThumbnails } from "../file-thumbnails";
import { PropertyUpdateLayout } from "../property-update-layout";
import { usePropertyUpdateSecurity } from "./hooks";
import styles from "./property-update-security.module.scss";

const POLLING_TIMEOUT = 30000; // Thirty seconds
const POLLING_INTERVAL = 1000; // One second

export const SecurityReview = () => {
  const STEP = 3;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const { supportPhoneNumber } = useFlags();
  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);
  const { email: userEmail } = userInfo ?? {};
  const { keystonePolicyId: insightPolicyId } = userDetails ?? {};
  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateSecurityContextState>(
      PropertyUpdateSecurityContext
    );
  const { burglarAlarm, burglarAlarmFiles, fireAlarm, fireAlarmFiles } =
    state ?? {};

  const { prevBurglar, prevFire } = usePropertyUpdateSecurity();

  const { startPolling, stopPolling, data } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  // After submitting, poll until data is updated, then navigate
  useEffect(() => {
    if (!pollingTimeout) {
      return;
    }
    const securityUpdateData =
      data?.userPropertyProfileData?.propertyUpdateData?.find(
        (detail) => detail?.detailName === "SECURITY"
      );
    const hasBurglarAlarmUpdate = securityUpdateData?.items?.some(
      (update) =>
        update?.enumerationValue === ALARM_VALUE_ENUM_MAP[burglarAlarm]
    );
    const hasFireAlarmUpdate = securityUpdateData?.items?.some(
      (update) => update?.enumerationValue === ALARM_VALUE_ENUM_MAP[fireAlarm]
    );

    if (hasBurglarAlarmUpdate && hasFireAlarmUpdate) {
      clearTimeout(pollingTimeout);
      stopPolling();
      formNavigate("../success");
    }
  }, [
    data?.userPropertyProfileData?.propertyUpdateData,
    formNavigate,
    pollingTimeout,
    burglarAlarm,
    fireAlarm,
    stopPolling,
  ]);

  const onSubmit = async () => {
    setSubmitting(true);
    const formData = new FormData();
    const request = {
      insightPolicyId,
      userEmail,
      propertySection: "SECURITY",
      propertyUpdateItems: [
        {
          termName: "Burglar Alarm",
          enumerationValue: ALARM_VALUE_ENUM_MAP[burglarAlarm],
          currentDisplayValue: ALARM_VALUE_DISPLAY_MAP[prevBurglar] ?? "None",
          updatedDisplayValue: ALARM_VALUE_DISPLAY_MAP[burglarAlarm],
        },
        {
          termName: "Fire Alarm",
          enumerationValue: ALARM_VALUE_ENUM_MAP[fireAlarm],
          currentDisplayValue: ALARM_VALUE_DISPLAY_MAP[prevFire] ?? "None",
          updatedDisplayValue: ALARM_VALUE_DISPLAY_MAP[fireAlarm],
        },
      ],
    };
    formData.append("request", JSON.stringify(request));
    let files = [];
    if (burglarAlarm === "central" && burglarAlarmFiles?.length) {
      files = files.concat(burglarAlarmFiles);
    }
    if (fireAlarm === "central" && fireAlarmFiles?.length) {
      files = files.concat(fireAlarmFiles);
    }
    if (files.length) {
      for (const file of files) {
        formData.append("file", file);
      }
    }

    try {
      const response = await fetch(
        `${CONFIG.KEYSTONE_PROXY_HREF}/api/property/update`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        startPolling(POLLING_INTERVAL);
        setPollingTimeout(
          setTimeout(() => {
            stopPolling();
            formNavigate("../success");
          }, POLLING_TIMEOUT)
        );
      } else {
        setSubmitting(false);
        setError(response?.statusText);
        logError(`Security update submission error: ${response?.statusText}`);
      }
    } catch (err) {
      setSubmitting(false);
      setError(err);
      logError(
        `Security update submission error: (${err?.name}: ${err?.message})`
      );
    }
  };

  if (submitting) {
    return (
      <div className={styles.loadingSpinner}>
        <SpinnerLoader firstMessage="Hang tight, we're sending your updates..." />
      </div>
    );
  }

  return (
    <PropertyUpdateLayout
      step={STEP}
      numSteps={3}
      heading="Update security details"
      buttonProps={{
        primaryOnClick: onSubmit,
        primaryText: "Yes, submit",
      }}
    >
      <div className={styles.securityReview}>
        <Heading size="md" className={styles.reviewHeadingDS}>
          Does this look right?
        </Heading>
        {error ? (
          <Alert
            appearance="danger"
            title="There was an error submitting your update."
            description={`Please try again. If the problem persists, contact support at ${supportPhoneNumber}.`}
          />
        ) : null}
        <Span>
          Burglar alarm:{" "}
          <Span bold>{ALARM_VALUE_DISPLAY_MAP[burglarAlarm]}</Span>
          {burglarAlarm === "central" && burglarAlarmFiles ? (
            <FileThumbnails files={burglarAlarmFiles} />
          ) : null}
        </Span>
        <Span>
          Fire alarm: <Span bold>{ALARM_VALUE_DISPLAY_MAP[fireAlarm]}</Span>
          {fireAlarm === "central" && fireAlarmFiles ? (
            <FileThumbnails files={fireAlarmFiles} />
          ) : null}
        </Span>
      </div>
    </PropertyUpdateLayout>
  );
};
