export const mailingUpdateReasons = [
  {
    value: "FIXING_TYPO",
    label: "I'm fixing a typo in this address.",
  },
  {
    value: "SEND_DOCUMENTS_ELSEWHERE",
    label: "I still live here, but I want my documents sent elsewhere.",
  },
  {
    value: "UPCOMING_MOVE",
    label: "I'm moving to this property, but don't live here yet.",
  },
  {
    value: "TEMPORARY_RESIDENCE",
    label: "I don't live permanently at this property (e.g. a summer home).",
  },
  {
    value: "MOVED_PERMANENTLY",
    label: "I moved and no one is living at this property.",
  },
  {
    value: "MOVED_INTEND_TO_SELL",
    label: "I moved and sold or intend to sell this property.",
  },
  {
    value: "MOVED_RENTING_TO_TENANTS",
    label: "I moved and I'm renting this property to tenants.",
  },
];

export const detailsRequiredReasons = [
  "TEMPORARY_RESIDENCE",
  "MOVED_PERMANENTLY",
  "MOVED_INTEND_TO_SELL",
  "MOVED_RENTING_TO_TENANTS",
];
