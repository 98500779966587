import { Link as DSLink } from "@icg360/design-system";

import { useRemovePolicy } from "pages/settings/policies/unlink-policy/hooks";

import UnlinkPolicyModal from "./unlink-policy-modal";

const UnlinkPolicy = ({ policy }) => {
  const { removePolicyState, removePolicyHandlers } = useRemovePolicy();

  return (
    <>
      <DSLink
        leadingIcon="Delete"
        onPress={() =>
          removePolicyHandlers.openRemovePolicyModal({
            ...policy,
          })
        }
        data-testid="remove-policy-link"
      >
        Remove policy from your SageSure account
      </DSLink>
      <UnlinkPolicyModal
        show={removePolicyState.showRemovePolicyModal}
        handleClose={removePolicyHandlers.closeRemovePolicyModal}
        policyDetails={removePolicyState.removePolicyInfo}
        onSuccess={removePolicyHandlers.handleRemovePolicySucceeded}
      />
    </>
  );
};

export default UnlinkPolicy;
