import { useContext, useEffect } from "react";

import { Link as DSLink, Modal } from "@icg360/design-system";

import LiveChatButton from "components/live-chat-button";
import { GlobalContext } from "components/root/global-provider";
import { GENERAL_SUPPORT } from "consts";
import { trackEvent, useFlags } from "utils";

import styles from "./styles.module.scss";

export const CustomerCareContactModal = ({
  description,
  show,
  handleClose,
}) => {
  const { liveChat } = useFlags();
  const { liveChatStatus } = useContext(GlobalContext);

  useEffect(() => {
    if (show) {
      trackEvent("paperlessSettings_contactModalClicked");
    }
  }, [show]);
  const trackHandleClose = () => {
    trackEvent("paperlessSettings_contactModal_closeClicked");
    handleClose();
  };
  return (
    <Modal
      open={show}
      onClose={trackHandleClose}
      heading="SageSure customer care"
      noFooter
    >
      <div className={styles.modalContent}>
        <p>{description}</p>
        {liveChat && liveChatStatus !== "uninitialized" ? (
          <div>
            <LiveChatButton location="CustomerCareContactModal" />
            <p className={styles.contactHours}>
              Monday-Friday: 8am - 9pm ET <br />
              Saturday: 10am - 3pm ET
            </p>
          </div>
        ) : null}
        <div>
          <DSLink
            className={styles.contactLink}
            href={`tel:${GENERAL_SUPPORT}`}
            leadingIcon="Phone"
            onPress={() => {
              trackEvent("paperlessSettings_contactModal_phoneClicked");
            }}
          >
            {GENERAL_SUPPORT}
          </DSLink>
          <p className={styles.contactHours}>
            Monday-Friday: 8am - 9pm ET <br />
            Saturday: 10am - 3pm ET
          </p>
        </div>
        <div>
          <DSLink
            className={styles.contactLink}
            href="mailto:customercare@sagesure.com"
            leadingIcon="Envelope"
            onPress={() => {
              trackEvent("paperlessSettings_contactModal_emailClicked");
            }}
          >
            customercare@sagesure.com
          </DSLink>
          <p className={styles.contactHours}>
            {"We'll reply within 48 hours."}
          </p>
        </div>
      </div>
    </Modal>
  );
};
