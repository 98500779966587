import { useState } from "react";

import { useFaqs } from "hooks/use-faqs";
import { type FAQ, type FAQCategory, trackEvent } from "utils";

export type FaqPage =
  | "overview"
  | "claims"
  | "billing"
  | "documents"
  | "property";

const pageCategoryMap: Record<FaqPage, FAQCategory> = {
  overview: "general",
  claims: "claims",
  billing: "payments",
  documents: "documents",
  property: "property",
};

export const useFaqSidebar = (page: FaqPage) => {
  const [selectedFaq, setSelectedFaq] = useState<FAQ | null>(null);
  const faqs = useFaqs({ category: pageCategoryMap[page] });

  const selectFaq = (faq) => {
    if (faq) {
      trackEvent("FAQ Clicked", {
        questionCategory: faq.category,
        questionID: faq.id,
        questionName: faq.question,
      });
    }

    setSelectedFaq(faq);
  };

  return { faqs, selectedFaq, setSelectedFaq: selectFaq };
};
