import { expirationDate, number } from "card-validator";
import { yupToFormErrors } from "formik";
import { boolean, object, ref, string } from "yup";

const formFieldValidations = {
  required: "Required",
  firstName: "Please enter a first name.",
  lastName: "Please enter a last name.",
  emailRequired: "Please enter an email address.",
  emailInvalid: "Please enter a valid email address.",
  bankStatementName: "Please enter a valid name.",
  financialInstitutionName: "Please enter a valid institution name.",
  accountNumber: "Please enter a valid account number.",
  routingNumber: "Please enter a valid routing number.",
  cardholderName: "Please enter a valid name.",
  cardNumber: "Please enter a valid card number.",
  cardExpirationDate: "Please enter a valid date.",
  cardZipCode: "Please enter a valid zip code.",
  policyNumber: "Please enter your 10 to 12 digit policy number.",
  phoneNumber: "Please enter a valid 10 digit phone number",
  twelveDigitPolicyNumber: "Please enter your 12 digit policy number.",
  zipCode: "Please enter a valid 5-digit property zip code.",
  confirmEmail: "Email addresses must match.",
};

const passwordValidationRules = [
  "No spaces",
  "At least 1 number",
  "At least 1 lowercase letter",
  "At least 1 uppercase letter",
  "At least 8 characters total",
  "Passwords match",
];

export const passwordSchema = object().shape({
  password: string()
    .required()
    .matches(/^\S*$/, passwordValidationRules[0])
    .min(8, passwordValidationRules[4])
    .matches(/[0-9]/, passwordValidationRules[1])
    .matches(/[A-Z]/, passwordValidationRules[3])
    .matches(/[a-z]/, passwordValidationRules[2]),
  passwordConfirmation: string().oneOf(
    [ref("password"), undefined],
    passwordValidationRules[5]
  ),
});

// This may not be a great place for this...
export const validatePasswordFormik = async (values) => {
  try {
    await passwordSchema.validate(
      {
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      },
      {
        abortEarly: false,
      }
    );
  } catch (e) {
    const errors = e.inner.map(({ message }) => message);
    return { ...yupToFormErrors(e), errors };
  }
};

export const requestResetSchema = object().shape({
  email: string()
    .required(formFieldValidations.emailRequired)
    .email(formFieldValidations.emailInvalid),
});

export const addressSchema = object().shape({
  streetLine1: string().trim().required("Please enter a valid address"),
  streetLine2: string(),
  city: string()
    .required("Please enter a valid city name")
    .trim()
    .matches(/^([a-zA-Z]|\s)+$/, "Please enter a valid city name"),
  state: string().required("Please select a valid state"),
  zipFirstFive: string()
    .required(formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, formFieldValidations.zipCode),
});

export const policyZipCodeSchema = object().shape({
  policyNumber: string()
    .required(formFieldValidations.policyNumber)
    .matches(
      /^[a-zA-Z0-9]{10}([a-zA-Z0-9])?([a-zA-Z0-9])?$/,
      formFieldValidations.policyNumber
    ),
  zipCode: string()
    .required(formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, formFieldValidations.zipCode),
});

export const addPolicySchema = object().shape({
  policyNumber: string()
    .required(formFieldValidations.twelveDigitPolicyNumber)
    .matches(/^[a-zA-Z0-9]{12}$/, formFieldValidations.twelveDigitPolicyNumber),
  zipCode: string()
    .required(formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, formFieldValidations.zipCode),
});

export const updatePhoneNumberSchema = object().shape({
  phoneNumber: string()
    .required(formFieldValidations.phoneNumber)
    .matches(
      /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      formFieldValidations.phoneNumber
    ),
  confirmPhoneNumber: string()
    .required(formFieldValidations.phoneNumber)
    .oneOf([ref("phoneNumber")], "Both phone numbers should match."),
});

export const userActivationSchema = object().shape({
  firstName: string().required(formFieldValidations.firstName).trim(),
  lastName: string().required(formFieldValidations.lastName).trim(),
  email: string()
    .required(formFieldValidations.emailRequired)
    .email(formFieldValidations.emailInvalid),
});

export const achSchema = object().shape({
  easypayMethod: string().required(formFieldValidations.required),
  bankStatementName: string().required(formFieldValidations.bankStatementName),
  financialInstitutionName: string().required(
    formFieldValidations.financialInstitutionName
  ),
  accountNumber: string()
    .required(formFieldValidations.accountNumber)
    .matches(/^[a-zA-Z0-9]*$/, formFieldValidations.accountNumber)
    .min(6, formFieldValidations.accountNumber)
    .max(17, formFieldValidations.accountNumber),
  routingNumber: string()
    .matches(/^\d{9}$/, formFieldValidations.routingNumber)
    .required(formFieldValidations.routingNumber),
  authorize: boolean().oneOf([true], formFieldValidations.required),
});

export const ccSchema = object().shape({
  cardholderName: string().required(formFieldValidations.cardholderName),
  cardNumber: string()
    .required(formFieldValidations.cardNumber)
    .test(
      "valid-card-number",
      formFieldValidations.cardNumber,
      (value) => number(value).isValid
    ),

  cardExpirationDate: string()
    .required(formFieldValidations.cardExpirationDate)
    .matches(
      /^((^0?[1-9])|(^1[0-2]))\/\d{4}$/,
      formFieldValidations.cardExpirationDate
    )
    .test(
      "valid-expiration-date",
      formFieldValidations.cardExpirationDate,
      (value) => {
        if (!value) return false;
        return expirationDate(value).isValid;
      }
    ),
  cardZipCode: string()
    .required(formFieldValidations.cardZipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, formFieldValidations.cardZipCode),
  authorize: boolean().oneOf([true], formFieldValidations.required),
});

export const emailSettingsSchema = object().shape({
  newInsuredEmail: string()
    .required(formFieldValidations.emailRequired)
    .email(formFieldValidations.emailInvalid),
  confirmInsuredEmail: string()
    .oneOf(
      [ref("newInsuredEmail"), undefined],
      formFieldValidations.confirmEmail
    )
    .required(formFieldValidations.emailRequired),
  applyToPaperless: boolean(),
});

export const updateRoofSchema = (initialYear) =>
  object().shape({
    constructionYearRoof: string()
      .matches(/^[0-9]{4}/, "Please enter a valid year.")
      .required("Year is required.")
      .test(
        "valid-roof-year",
        `Please enter a year later than ${initialYear}.`,
        (value) => parseInt(value, 10) > initialYear
      )
      .test(
        "valid-roof-year",
        `Can't be in the future.`,
        (value) => parseInt(value, 10) <= new Date().getFullYear()
      ),
  });
