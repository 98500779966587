import { createContext, useEffect, useState } from "react";

import { type LiveChatStatus, getLiveChatStatus } from "utils";

type GlobalContextType = {
  flagOverrides: Record<string, string>;
  liveChatStatus: LiveChatStatus;
};

export const GlobalContext = createContext<GlobalContextType>({
  flagOverrides: {},
  liveChatStatus: "uninitialized",
});

export const GlobalProvider = ({ children }) => {
  const [flagOverrides, setFlagOverrides] = useState({});
  const [liveChatStatus, setLiveChatStatus] =
    useState<LiveChatStatus>("uninitialized");

  //The button image changes according to business hours. The listener will set image once chat.js sends the image data.
  useEffect(() => {
    const handleEmbeddedChatMessage = (e) => {
      setLiveChatStatus((curStatus) => getLiveChatStatus(e) ?? curStatus);
    };
    window.addEventListener("message", handleEmbeddedChatMessage);
    return () => {
      window.removeEventListener("message", handleEmbeddedChatMessage);
    };
  }, []);

  const value = {
    flagOverrides,
    setFlagOverrides,
    liveChatStatus,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
