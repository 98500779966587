import { useContext, useEffect } from "react";

import { BaseLayout } from "components/base-layout";
import { FetchingError } from "components/common/fetching-error";
import { Stack } from "components/common/stack";
import { SubmitClaim } from "components/renderers";
import { ClaimActivity } from "components/renderers/claim-activity";
import { ClaimHelpSection } from "components/renderers/claim-help-section/claim-help-section";
import { AuthAppContext } from "components/root/auth-app-provider";
import { MainLayout } from "components/shared/main-layout";
import { scrollToTop, trackEvent } from "utils";

import THIRD_PARTY_CLAIM_TRACKERS from "./constants";
import { useClaimsData } from "./hooks";

const Claims = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { error, loading, claimsList, carrierInfo } =
    useClaimsData(selectedPolicyId);

  useEffect(() => {
    trackEvent("Claims");
    scrollToTop();
  }, []);

  if (error) {
    return (
      <BaseLayout>
        <MainLayout sidebar={<ClaimHelpSection />}>
          <FetchingError page="Claims" />
        </MainLayout>
      </BaseLayout>
    );
  }

  const claimTrackedInternally = !THIRD_PARTY_CLAIM_TRACKERS.includes(
    carrierInfo?.id ?? ""
  );

  const main = (
    <Stack>
      <SubmitClaim
        hasClaims={!!claimsList?.ClaimsTrackerInfo}
        carrierName={carrierInfo?.name ?? ""}
      />
      {claimsList?.ClaimsTrackerInfo ? (
        <ClaimActivity
          claims={claimsList.ClaimsTrackerInfo ?? []}
          claimTrackedInternally={claimTrackedInternally}
        />
      ) : null}
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={<ClaimHelpSection />} mainLoading={loading}>
        {main}
      </MainLayout>
    </BaseLayout>
  );
};

export default Claims;
