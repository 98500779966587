import PropTypes from "prop-types";
import { Col, Container, Row } from "react-grid-system";

const MainWithNoSideBarLayout = ({ main, ...props }) => {
  return (
    <Container {...props}>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <main>{main}</main>
        </Col>
      </Row>
    </Container>
  );
};

MainWithNoSideBarLayout.propTypes = {
  main: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export { MainWithNoSideBarLayout };
