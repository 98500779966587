import { useContext, useEffect } from "react";

import {
  Card,
  Heading,
  Hr,
  Span,
  SpotIcon,
  Tag,
  addToast,
} from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import SignatureSection from "pages/overview/welcome-banner/signature-section";
import { UnderwritingSection } from "pages/overview/welcome-banner/underwriting-section";
import { UnderwritingSectionOld } from "pages/overview/welcome-banner/underwriting-section-old";
import { trackEvent, useFlags } from "utils";

import { BillingSection } from "./billing-section";
import styles from "./welcome-banner.module.scss";

const WelcomeBanner = () => {
  const { welcomeBannerIneligibleWriteout } = useFlags();
  const { userInfo, selectedPolicyId, policy } = useContext(AuthAppContext);

  useEffect(() => {
    trackEvent("welcomeTaskCard_load");
  }, []);

  const copyPolicyIdToClipboard = () => {
    navigator.clipboard.writeText(selectedPolicyId);
    addToast("Policy # copied to clipboard", { icon: true });
  };

  return (
    <Card className={styles.welcomeCardDS}>
      <div className={styles.welcomeTitle}>
        <div className={styles.welcomeTitleLeft}>
          <SpotIcon
            name={policy.isCommercial ? "Store" : "Home"}
            appearance="quiet"
            size="sm"
          />
          <Heading size="md">
            Hi{userInfo?.firstName ? `, ${userInfo.firstName}` : ""}
          </Heading>
        </div>
        <div data-testid="policy-number-tag">
          <Span color="inverse" bold>
            Policy #
          </Span>{" "}
          <button
            onClick={copyPolicyIdToClipboard}
            data-testid="copy-policy-id-to-clipboard-btn"
          >
            <Tag
              className={styles.policyTag}
              bgColor="accentTealLight"
              textColor="accentTealDarkest"
            >
              {selectedPolicyId}
            </Tag>
          </button>
        </div>
      </div>
      <div className={styles.welcomeSections}>
        <BillingSection />
        <div className={styles.stackedDivider}>
          <Hr spacing="xl" />
        </div>
        <SignatureSection />
        <div className={styles.stackedDivider}>
          <Hr spacing="xl" />
        </div>
        {welcomeBannerIneligibleWriteout ? (
          <UnderwritingSection />
        ) : (
          <UnderwritingSectionOld />
        )}
      </div>
    </Card>
  );
};

export { WelcomeBanner };
