import { useState } from "react";

import { Alert, Button } from "@icg360/design-system";

import { CustomerCareContactModal } from "components/customer-care-contact-modal";
import { RegularContentLoader } from "components/loader";
import { usePaperlessSettingsDataQuery } from "gql/__generated__/hooks";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";
import { authUser } from "utils";

import PaperlessItem from "./paperless-item";
import styles from "./paperless.module.scss";

export const Paperless = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  const { loading, data } = usePaperlessSettingsDataQuery({
    variables: { email: authUser()?.email ?? "" },
  });
  const [showPaperlessBanner, setShowPaperlessBanner] = useState(false);

  if (loading) {
    return <RegularContentLoader />;
  }
  const { userPolicies } = data ?? {};

  const banner = showPaperlessBanner ? (
    <Alert
      appearance="info"
      title=""
      description={
        <>
          You have enrolled in Paperless to receive communication via email. To
          cancel paperless enrollment,{" "}
          <Button
            appearance="link"
            className={styles.supportButton}
            onPress={() => setShowContactModal(true)}
          >
            contact support
          </Button>
          .
        </>
      }
    />
  ) : null;

  const policyItems = userPolicies?.map((policy) => (
    <PaperlessItem
      key={policy.policyId}
      policy={policy}
      setShowPaperlessBanner={setShowPaperlessBanner}
    />
  ));

  return (
    <>
      <SettingsPanelLayout
        title="Paperless"
        banner={banner}
        policyItems={policyItems}
      />
      <CustomerCareContactModal
        description="We can assist with any questions about billing or your SageSure account."
        handleClose={() => {
          setShowContactModal(false);
        }}
        show={showContactModal}
      />
    </>
  );
};
