import { useCallback, useRef, useState } from "react";

import { Button, Link as DSLink } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import LoggingOut from "components/logging-out";
import { useLogout } from "hooks/use-logout-user";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import { trackEvent } from "utils";

import styles from "./profile-dropdown.module.scss";

const ProfileDropdown = () => {
  const { logout } = useLogout();
  const [loggingOut, setLoggingOut] = useState(false);

  const [profileMenuVisible, setProfileMenuVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setProfileMenuVisible(false));

  const openProfileMenu = () => {
    trackEvent("Profile menu opened");
    setProfileMenuVisible(!profileMenuVisible);
  };

  const logoutOnClick = useCallback(async () => {
    setLoggingOut(true);
    await logout();
  }, [logout]);

  return (
    <>
      <div className={styles.profile} ref={ref}>
        <div>
          <Button
            icon="User"
            appearance="neutral"
            onPress={openProfileMenu}
            data-testid="profile-icon-btn"
          />
        </div>
        {profileMenuVisible && (
          <div className={styles.profileOptions}>
            <MSSLink
              className={styles.profileOption}
              to={location.pathname === "/my/settings" ? "" : "/my/settings"}
              onClick={() => setProfileMenuVisible(false)}
            >
              Settings
            </MSSLink>
            <DSLink className={styles.profileOption} onPress={logoutOnClick}>
              Log Out
            </DSLink>
          </div>
        )}
      </div>
      {loggingOut ? <LoggingOut /> : null}
    </>
  );
};

export default ProfileDropdown;
