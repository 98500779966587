import { useContext, useEffect, useState } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { ENROLLMENT_STATUS, PAYMENT_METHOD_CODE } from "consts";
import {
  type FAQ,
  type FAQCategory,
  getFaqs,
  getPaymentMethodDetails,
  useFlags,
} from "utils";

type UseFaqsParams = {
  category?: FAQCategory;
  noUpsell?: boolean;
};

export const useFaqs = ({ category, noUpsell }: UseFaqsParams): FAQ[] => {
  const flags = useFlags();
  const supportPhoneNumber = flags.supportPhoneNumber;
  const { userDetails, userBilling } = useContext(AuthAppContext);

  const [filteredFaqs, setFilteredFaqs] = useState<FAQ[]>([]);

  useEffect(() => {
    const { isCommercial } = userDetails ?? {};
    const { accounting } = userBilling ?? {};

    const paymentMethod =
      userDetails?.insuredPreferences?.easyPayEnrollmentStatus ===
      ENROLLMENT_STATUS.PENDING
        ? PAYMENT_METHOD_CODE.EASYPAY
        : getPaymentMethodDetails(
            accounting?.paymentPlan?.planType?.toLowerCase()
          )?.paymentMethod;

    setFilteredFaqs(
      getFaqs(supportPhoneNumber).filter((faq) => {
        return (
          (!faq.hideUnlessFlag || flags[faq.hideUnlessFlag]) &&
          !(isCommercial && faq.hideForCommercial) &&
          (!category || faq.category === category) &&
          (!faq.paymentMethods || faq.paymentMethods.includes(paymentMethod)) &&
          !(noUpsell && faq.hideIfNoUpsell)
        );
      }) ?? []
    );
  }, [category, flags, noUpsell, supportPhoneNumber, userBilling, userDetails]);

  return filteredFaqs;
};
