import React from "react";

import { Button, Card, Heading } from "@icg360/design-system";

import { useThumbtackModalContext } from "components/thumbtack-modal";
import { useTruncatedString } from "hooks/use-truncated-string";

import styles from "./home-services.module.scss";
import type { ThumbtackHomeCareResult } from "./hooks";

type TaskCardProps = { zipCode: string; task: ThumbtackHomeCareResult };

const TRUNCATE_LENGTH = 135;

export const TaskCard = ({ zipCode, task }: TaskCardProps) => {
  const { openThumbtackModal } = useThumbtackModalContext();
  const { output, isShowingAll, exceedsMaxLength, showAll, showLess } =
    useTruncatedString(task.taskDetails.description, TRUNCATE_LENGTH);
  return (
    <Card>
      <div className={styles.suggestedTask}>
        <div className={styles.taskContent}>
          <Heading size="sm">{task.taskDetails.title}</Heading>
          <div
            className={styles.costRange}
          >{`$${task.taskDetails.avgCost.min} - ${task.taskDetails.avgCost.max}`}</div>
          <p>{output}</p>
          {exceedsMaxLength && (
            <Button
              appearance="link"
              size="xs"
              onPress={() => {
                if (isShowingAll) {
                  showLess();
                } else {
                  showAll();
                }
              }}
              data-testid="activate-banner-register-btn"
            >
              {isShowingAll ? "Show less…" : "Show more…"}
            </Button>
          )}
        </div>
        <div className={styles.taskAction}>
          <Button
            appearance="secondary"
            size="sm"
            onPress={() => {
              openThumbtackModal(
                zipCode,
                task.categoryName,
                task.taskDetails.title
              );
            }}
            data-testid="open-thumbtack-btn"
          >
            Find contractor
          </Button>
        </div>
      </div>
    </Card>
  );
};
