import { type ReactElement } from "react";

import { Link as DSLink, Span } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { CLAIMS_SUPPORT, PAYMENT_METHOD_CODE } from "consts";

import { trackEvent } from "./analytics-tools";
import { scrollToTop } from "./scroll-to";

export type FAQCategory =
  | "payments"
  | "claims"
  | "documents"
  | "general"
  | "property";

export type FAQ = {
  question: string;
  answer: () => ReactElement;
  id: string;
  category: FAQCategory;
  paymentMethods?: string[];
  hideForCommercial?: boolean;
  hideIfNoUpsell?: boolean;
  hideUnlessFlag?: string;
};

export const faqCategories: Record<FAQCategory, FAQCategory> = {
  payments: "payments",
  claims: "claims",
  documents: "documents",
  general: "general",
  property: "property",
};

export const getFaqs = (supportPhoneNumber): FAQ[] => {
  return [
    // Payments:
    {
      question: "How can I update my mortgagee information?",
      answer: () => (
        <p>
          You can update your mortgagee information on the{" "}
          <MSSLink to="/my/settings/payment">Settings &gt; Payment</MSSLink>{" "}
          page.
        </p>
      ),
      id: "how-can-i-update-my-mortgagee",
      category: faqCategories.payments,
    },
    {
      question: "How do I change my payment plan?",
      answer: () => (
        <p>
          To change your payment plan, please contact your insurance
          representative or SageSure customer care at{" "}
          <DSLink href={`tel:${supportPhoneNumber}`}>
            {supportPhoneNumber}
          </DSLink>
          .
        </p>
      ),
      id: "how-do-i-change-my-payment-plan",
      category: faqCategories.payments,
    },
    {
      question: "How can I make a payment?",
      answer: () => (
        <>
          <p>
            If you are on <Span bold>Easypay</Span> or{" "}
            <Span bold>mortgage</Span> payment plan, your payment will be
            processed automatically. Contact your insurance representative or
            SageSure customer care to make a manual payment.
          </p>
          <p>
            If you are on <Span bold>direct bill</Span> payment plan, you can
            click “Make a payment” on the{" "}
            <MSSLink onClick={scrollToTop} to="/my/billing">
              Billing
            </MSSLink>{" "}
            tab to submit an ACH or debit/credit card payment.
          </p>
        </>
      ),
      id: "how-can-i-make-a-payment",
      category: faqCategories.payments,
    },
    {
      question: "Can I set up automatic payments?",
      answer: () => (
        <p>
          Yes! You can automate your payments by{" "}
          <MSSLink to="/my/billing/update">enrolling in EasyPay.</MSSLink> Once
          you’ve enrolled, your payment plan change request will be processed so
          you can submit payments automatically.
        </p>
      ),
      id: "can-i-set-up-automatic-payments",
      paymentMethods: [PAYMENT_METHOD_CODE.DIRECTBILL],
      category: faqCategories.payments,
    },
    {
      question: "What is EasyPay?",
      answer: () => (
        <p>
          EasyPay is SageSure’s automated recurring payment system. This is a
          great way to eliminate late payment fees! EasyPay supports credit
          cards and ACH payments. With ACH payments, there’s no service fee.{" "}
          <MSSLink to="/my/billing/update">
            Click here to enroll in EasyPay
          </MSSLink>
          .
        </p>
      ),
      id: "what-is-easypay",
      category: faqCategories.payments,
    },
    {
      question: "How can I update my EasyPay payment settings?",
      answer: () => (
        <>
          <p>
            Once enrolled in EasyPay, you can update your payment method and
            details from the{" "}
            <MSSLink to="/my/settings/payment">Settings &gt; Payment</MSSLink>{" "}
            page.
          </p>
          <p>
            If you don’t see the option to edit your EasyPay enrollment, your
            enrollment may still be pending.
          </p>
          <p>
            If you need to adjust your payment plan (the number of payments per
            term), please contact SageSure Customer Care at{" "}
            <DSLink href="mailto:customercare@sagesure.com">
              customercare@sagesure.com
            </DSLink>{" "}
            or{" "}
            <DSLink href={`tel:${supportPhoneNumber}`}>
              {supportPhoneNumber}
            </DSLink>
            .
          </p>
        </>
      ),
      id: "easypay-updates",
      category: faqCategories.payments,
    },
    {
      question: "How can I see my payment history?",
      answer: () => (
        <p>
          You can view your account payment history on the{" "}
          <MSSLink onClick={scrollToTop} to="/my/billing">
            Billing
          </MSSLink>{" "}
          tab.
        </p>
      ),
      id: "how-can-i-see-my-payment-history",
      category: faqCategories.payments,
    },
    // Claims:
    {
      question: "How do I submit a claim?",
      answer: () => (
        <p>
          You can submit a claim directly on the{" "}
          <MSSLink onClick={scrollToTop} to="/my/claims">
            Claims
          </MSSLink>{" "}
          tab. If you need help filing a claim or have questions, please contact
          us at <DSLink href={`tel:${CLAIMS_SUPPORT}`}>{CLAIMS_SUPPORT}</DSLink>
          .
        </p>
      ),
      id: "how-do-i-submit-a-claim",
      category: faqCategories.claims,
    },
    {
      question: "What is the status of my claim?",
      answer: () => (
        <p>
          Most policyholders can view the status of their claim on the{" "}
          <MSSLink onClick={scrollToTop} to="/my/claims">
            Claims
          </MSSLink>{" "}
          tab. If you don’t see your claim status there, or have further
          questions about your claim, please contact us at{" "}
          <DSLink href={`tel:${CLAIMS_SUPPORT}`}>{CLAIMS_SUPPORT}</DSLink>.
        </p>
      ),
      id: "what-is-the-status-of-my-claim",
      category: faqCategories.claims,
    },
    // Documents:
    {
      question: "Can I set up electronic policy and billing documents?",
      answer: () => (
        <p>
          Yes! You can{" "}
          <MSSLink to="/my/settings/paperless">
            enroll in paperless policy and/or billing documents
          </MSSLink>{" "}
          to receive them electronically via email. This is a great way to
          reduce waste and mail delays!
        </p>
      ),
      id: "can-i-set-up-electronic-policy-and-billing-documents",
      hideForCommercial: true,
      category: faqCategories.documents,
    },
    {
      question: "How can I access my policy documents?",
      answer: () => (
        <p>
          You can access your policy documents on the{" "}
          <MSSLink onClick={scrollToTop} to="/my/documents">
            Documents
          </MSSLink>{" "}
          tab.
        </p>
      ),
      id: "how-can-i-access-my-policy-documents",
      category: faqCategories.documents,
    },
    // General:
    {
      question: "How do I reset my password?",
      answer: () => (
        <p>
          You may reset your password on the{" "}
          <MSSLink to="/my/settings/security">Settings &gt; Security</MSSLink>{" "}
          page by clicking the Change button.
        </p>
      ),
      id: "how-do-i-reset-my-password",
      category: faqCategories.general,
    },
    {
      question: "Where can I update my contact information?",
      answer: () => (
        <p>
          You can update the insured email address, phone number, and mailing
          address on the{" "}
          <MSSLink to="/my/settings/contact">Settings &gt; Contact</MSSLink>{" "}
          page.
        </p>
      ),
      id: "update-contact-info",
      category: faqCategories.general,
    },
    {
      question: "Can I manage multiple SageSure policies in one account?",
      answer: () => (
        <p>
          Yes, you can link multiple policies to the same account by adding your
          policies on the{" "}
          <MSSLink to="/my/settings/policies">Settings &gt; Policies</MSSLink>{" "}
          page.
        </p>
      ),
      id: "can-i-link-policies-to-one-account",
      category: faqCategories.general,
    },
    {
      question: "How can I add or remove policies from my account?",
      answer: () => (
        <p>
          You can add and remove policies from your account on the{" "}
          <MSSLink to="/my/settings/policies">Settings &gt; Policies</MSSLink>{" "}
          page.
        </p>
      ),
      id: "how-can-i-add-or-remove-policies-from-my-account",
      category: faqCategories.general,
    },
    {
      question: "What are additional coverages?",
      answer: () => (
        <p>
          Additional coverages offer supplementary protection that you can add
          to your current policy. To learn more about these policies and if
          they’re a good fit for you, please contact your insurance
          representative. You may also purchase these coverages directly on the{" "}
          <MSSLink onClick={scrollToTop} to="/my/overview">
            Overview
          </MSSLink>{" "}
          page.
        </p>
      ),
      id: "what-are-additional-coverages",
      category: faqCategories.general,
      hideIfNoUpsell: true,
    },
    {
      question: "How can I cancel my insurance policy?",
      answer: () => (
        <>
          <p>
            Policyholders should contact their insurance representative to
            initiate the cancellation process. You can find the contact
            information for your insurance representative on this page.
          </p>
          <p>
            When your policy is cancelled, you will receive a refund for any
            pre-paid premium between the cancellation date and the expiration
            date of your policy. You can expect to receive your refund within
            approximately 15 days after the cancellation takes effect. Premium
            will be refunded via the most recent payment method you used. If the
            last payment was made with a credit card or ACH, the name on the
            account must match the policyholder&apos;s name. Otherwise, a check
            will be issued in the policyholder&apos;s name and sent to the
            address on file.
          </p>
        </>
      ),
      id: "how-can-i-cancel-my-insurance-policy",
      category: faqCategories.general,
    },
    {
      question: "What is my.sagesure.com?",
      answer: () => (
        <>
          <p>
            SageSure is proud to work as a servicing agent for several highly
            rated insurance companies. We work with you and your insurance
            representative to manage your policy and provide support for all
            your policy needs.{" "}
            <MSSLink to="https://www.sagesure.com/faq/">
              Click here to learn more about SageSure.
            </MSSLink>
          </p>
          <p>
            Our policyholder portal (where you are now!) is our online customer
            care center where you can make payments, submit claims, access
            policy documents, and more.
          </p>
        </>
      ),
      id: "what-is-mss",
      category: faqCategories.general,
    },
    {
      question: "I have another question not answered here. Who can help me?",
      answer: () => (
        <>
          <p>
            We’re happy to help! You can contact SageSure customer care at{" "}
            <DSLink href={`tel:${supportPhoneNumber}`}>
              {supportPhoneNumber}
            </DSLink>{" "}
            or via email at{" "}
            <DSLink href="mailto:customercare@sagesure.com">
              customercare@sagesure.com
            </DSLink>
            .
          </p>
          <p>
            You can also find contact information for your insurance
            representative on this page if you have questions about your policy
            or want to make changes to your coverage.
          </p>
        </>
      ),
      id: "i-have-another-question",
      category: faqCategories.general,
    },
    // Property:
    {
      question:
        "How can I update SageSure about changes to my insured property?",
      answer: () => (
        <p>
          You can submit an update for certain property details on the{" "}
          <MSSLink onClick={scrollToTop} to="/my/property">
            Property tab
          </MSSLink>
          . Most updates require supporting documentation before they can be
          fully validated in SageSure’s systems. If the property detail you need
          to update is not listed on the Property tab, please contact your
          insurance representative to let them know. They can assist with next
          steps to update SageSure.
        </p>
      ),
      id: "property-updates-how-to",
      category: faqCategories.property,
      hideForCommercial: true,
      hideUnlessFlag: "showPropertyTabDetailsSection",
    },
    {
      question: "Why does SageSure need information about my property?",
      answer: () => (
        <p>
          Your home’s specific characteristics have a significant impact on your
          insurance coverage and premium! By keeping SageSure updated on your
          home’s details, such as year roof installed / replaced and alarm
          systems, we can ensure that you have the right coverage. Some updates
          can even lower your premium!
        </p>
      ),
      id: "property-updates-why",
      category: faqCategories.property,
      hideForCommercial: true,
      hideUnlessFlag: "showPropertyTabDetailsSection",
    },
    {
      question: `
        I submitted an update on the Property tab, but I don’t see
        that change reflected in the property profile yet. Why not?
      `,
      answer: () => (
        <p>
          You probably need to provide supporting documentation to validate your
          update. Only validated updates are shown in the property profile, and
          most updates require supporting documentation before they can be fully
          validated in SageSure’s system. Please check your email for a message
          from{" "}
          <DSLink href="mailto:customercare@sagesure.com">
            customercare@sagesure.com
          </DSLink>
          , which will advise on any next steps, or please reach out to Customer
          Care at{" "}
          <DSLink href="mailto:customercare@sagesure.com">
            customercare@sagesure.com
          </DSLink>{" "}
          or{" "}
          <DSLink href={`tel:${supportPhoneNumber}`}>
            {supportPhoneNumber}
          </DSLink>{" "}
          for more immediate assistance.
        </p>
      ),
      id: "property-updates-not-shown",
      category: faqCategories.property,
      hideForCommercial: true,
      hideUnlessFlag: "showPropertyTabDetailsSection",
    },
    {
      question: `What is LeakBot?`,
      answer: () => (
        <>
          <p>
            As a valued SageSure policyholder, your property could qualify for a
            LeakBot smart water detector to help spot leaks early before they
            become bigger problems.
          </p>
          <p>
            LeakBot is a device that clips to your main water supply pipe and
            detects problems from small hidden water leaks to sudden large
            losses of water. As soon as LeakBot spots a problem, you’ll be
            notified in your LeakBot app so an expert can find and fix the issue
            before it causes more serious damage.
          </p>
        </>
      ),
      id: "what-is-leakbot",
      category: faqCategories.property,
      hideForCommercial: true,
      hideUnlessFlag: "leakbotFaqs",
    },
    {
      question: `I have a question about my LeakBot, who can help?`,
      answer: () => (
        <p>
          SageSure is excited to partner with LeakBot and provide policyholders
          with a LeakBot device. Should you have any questions about the LeakBot
          app, device, installation, or upkeep, you can reach out to LeakBot
          directly via email at{" "}
          <DSLink
            href="mailto:help.us@leakbotsupport.com"
            onPress={() =>
              trackEvent("LeakBot FAQ - Clicked email LeakBot support.")
            }
          >
            help.us@leakbotsupport.com
          </DSLink>{" "}
          and they will be happy to assist you!
        </p>
      ),
      id: "i-have-a-question-about-my-leakbot-who-can-help",
      category: faqCategories.property,
      hideForCommercial: true,
      hideUnlessFlag: "leakbotFaqs",
    },
  ];
};
