import { ApolloProvider } from "@apollo/client";
import { useContext, useEffect } from "react";
import { setConfiguration } from "react-grid-system";
import { RouterProvider } from "react-router-dom";

import { DesignSystemProvider, Toast } from "@icg360/design-system";

import { ErrorBoundary } from "components/error-boundary";
import { GlobalContext, GlobalProvider } from "components/root/global-provider";
import "stylesheets/main.scss";
import { apolloClient, logError, urlSearchParamsObject } from "utils";

import { router } from "./router";

const FLAG_OVERRIDE_URL_PARAM = "flag_overrides";

setConfiguration({
  breakpoints: [576, 768, 992, 1200, 1600],
  containerWidths: [540, 768, 970, 1200],
  maxScreenClass: "xl",
});

const Root = () => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <DesignSystemProvider>
          <GlobalProvider>
            <App />
            <Toast />
          </GlobalProvider>
        </DesignSystemProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

const App = () => {
  const { setFlagOverrides } = useContext(GlobalContext);
  const { [FLAG_OVERRIDE_URL_PARAM]: qsFlagOverrides } =
    urlSearchParamsObject();
  useEffect(() => {
    if (qsFlagOverrides) {
      try {
        setFlagOverrides(JSON.parse(qsFlagOverrides));
      } catch (e) {
        // flag overrides should be for testing or debugging
        // So we don't want bad json to kill the app, but we want the errror message
        logError(`App root: ${e.message}`);
      }
    }
  }, [qsFlagOverrides, setFlagOverrides]);

  return <RouterProvider router={router()} />;
};

export default Root;
