import { Form, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Card, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { Stack } from "components/common/stack";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import { type TrampolineFormValues } from "pages/property-profile/update-trampoline";

import styles from "./update-trampoline.module.scss";

export const ReviewTrampoline = () => {
  const navigate = useNavigate();
  const {
    values: { trampoline, trampolineShockAbsorbing },
    isSubmitting,
    status,
  } = useFormikContext<TrampolineFormValues>();

  return (
    <>
      <ProgressBar progress={90} />
      <MainLayout
        sidebar={
          <PropertyProfileSidebar
            headline="Next steps"
            body="After submitting this update, an email will be sent to you and your insurance representative documenting any changes to your property profile."
          />
        }
      >
        <Card className={styles.reviewCard}>
          <Form>
            <Stack>
              <Heading size="lg">Please review your updates</Heading>
              {status?.type === "error" && (
                <Alert
                  appearance="danger"
                  title=""
                  description="There was an error. Please try again or contact support if this persists."
                />
              )}
              <Heading size="sm">Trampoline</Heading>

              <Body>
                Keeping your property information accurate and up-to-date helps
                us ensure that you have the best coverage for your needs.
              </Body>

              <InlineDataRow className={styles.dataRow}>
                <DataTerm>Trampoline:</DataTerm>
                <DataDefinition>{trampoline ? "Yes" : "No"}</DataDefinition>
              </InlineDataRow>
              {trampoline && (
                <InlineDataRow className={styles.dataRow}>
                  <DataTerm data-testid="shock-review">
                    Shock-absorbing padding:
                  </DataTerm>
                  <DataDefinition>
                    {trampolineShockAbsorbing ? "Yes" : "No"}
                  </DataDefinition>
                </InlineDataRow>
              )}
              <div className={styles.navigation}>
                <Button
                  appearance="tertiary"
                  onPress={() => navigate("/my/property/trampoline")}
                  data-testid="review-go-back-btn"
                >
                  Go back
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  data-testid="trampoline-submit-btn"
                >
                  Submit updates
                </Button>
              </div>
            </Stack>
          </Form>
        </Card>
      </MainLayout>
    </>
  );
};
