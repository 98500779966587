import { Field, Form, useFormikContext } from "formik";

import { Alert, Modal } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";

import styles from "./edit-email-form.module.scss";

type EditEmailFormProps = {
  handleClose: () => void;
  show: boolean;
  heading?: string;
};

const EditEmailForm = ({ handleClose, show, heading }: EditEmailFormProps) => {
  const { isValid, isSubmitting, dirty, resetForm, status, submitForm } =
    useFormikContext();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      heading={heading ?? "Update email address"}
      onPrimaryAction={submitForm}
      primaryAction="Update"
      loadingPrimary={isSubmitting}
      disablePrimary={!isValid || !dirty}
    >
      <div className={styles.wrapper}>
        {status && <Alert appearance="danger" title="" description={status} />}
        <Form>
          <div className={styles.wrapper}>
            <Field
              name="email"
              title="New email address"
              component={InputField}
              placeholder="Enter email address"
            />
            <Field
              name="confirmEmail"
              title="Confirm email address"
              component={InputField}
              placeholder="Confirm email address"
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditEmailForm;
