import PropTypes from "prop-types";
import { Col, Container, Row } from "react-grid-system";

import styles from "./main-with-sidebar-layout.module.scss";

const MainWithSideBarLayout = ({ main, sidebar, ...props }) => {
  return (
    <Container {...props}>
      <Row>
        <Col md={8} sm={6} xs={12}>
          <main>{main}</main>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <aside className={styles.sidebar}>{sidebar}</aside>
        </Col>
      </Row>
    </Container>
  );
};

MainWithSideBarLayout.propTypes = {
  sidebar: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  main: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export { MainWithSideBarLayout };
