import classNames from "classnames";
import { type PropsWithChildren, useContext } from "react";

import { SpinnerLoader } from "components/loader/loader";
import { PageHeader } from "components/page-header";
import { AuthAppContext } from "components/root/auth-app-provider";
import { Notifications } from "components/shared/notifications";
import { type NotificationName } from "components/shared/notifications/notifications";
import { PageFooter } from "components/shared/page-footer";

import styles from "./base-layout.module.scss";

type BaseLayoutProps = PropsWithChildren<{
  title?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideNavigation?: boolean;
  loading?: boolean;
  availableNotifications?: NotificationName[];
}>;

const BaseLayout = ({
  children,
  hideFooter = false,
  hideHeader = false,
  hideNavigation = false,
  loading = false,
  availableNotifications = [],
}: BaseLayoutProps) => {
  const { isPolicyDataReady } = useContext(AuthAppContext);

  return (
    <>
      {!hideHeader ? <PageHeader hideNavigation={hideNavigation} /> : null}
      <div
        className={classNames(
          styles.pageContainer,
          hideNavigation && styles.hideNavigation,
          hideHeader && styles.hideHeader
        )}
      >
        {availableNotifications.length ? (
          <Notifications availableNotifications={availableNotifications} />
        ) : null}
        {!isPolicyDataReady || loading ? (
          <div className={styles.spinnerWrapper}>
            <SpinnerLoader />
          </div>
        ) : (
          children
        )}
      </div>
      {!hideFooter ? (
        <PageFooter
          className={
            // fnol-policyholder component on claims submit page has a styling conflict with our footer on mobile
            location.pathname === "/my/claims/submit"
              ? styles.hideFooterMobile
              : ""
          }
        />
      ) : null}
    </>
  );
};

export { BaseLayout };
