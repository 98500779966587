import { type PropsWithChildren } from "react";

import {
  Heading,
  type ProgressStep,
  ProgressStepper,
} from "@icg360/design-system";

import {
  ShingleResealButtons,
  type ShingleResealButtonsProps,
} from "pages/shingle-reseal/shingle-reseal-buttons";

import styles from "./shingle-reseal.module.scss";

type ShingleResealLayoutProps = PropsWithChildren<{
  step: 1 | 2 | 3 | 4 | 5 | 6;
  heading: string;
  subheading?: string;
  buttonProps?: ShingleResealButtonsProps;
}>;

export const ShingleResealLayout = ({
  step,
  heading,
  subheading,
  buttonProps,
  children,
}: ShingleResealLayoutProps) => {
  const steps: ProgressStep[] = [];
  for (let i = 1; i <= 6; i++) {
    if (i < step) {
      steps.push({ status: "completed" });
    } else if (i === step) {
      steps.push({ status: "in-progress" });
    } else {
      steps.push({ status: "incomplete" });
    }
  }
  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.progressStepperWrapper}>
        <ProgressStepper appearance="simple" steps={steps} />
      </div>
      <div className={styles.layoutContent}>
        <div className={styles.layoutHeader}>
          <Heading size="lg">{heading}</Heading>
          {subheading ? (
            <p className={styles.layoutSubheading}>{subheading}</p>
          ) : null}
        </div>
        {children}
        {buttonProps ? <ShingleResealButtons {...buttonProps} /> : null}
      </div>
    </div>
  );
};
