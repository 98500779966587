import { yupResolver } from "@hookform/resolvers/yup";
import cloneDeep from "lodash.clonedeep";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { object as yupObject, ref as yupRef, string as yupString } from "yup";

import { Button, Modal, TelephoneMask, addToast } from "@icg360/design-system";

import { useUpdateInsuredInfoMutation } from "gql/__generated__/hooks";
import { contactItemDataQuery } from "gql/queries";
import { formatPhone, logError, trackEvent, useFlags } from "utils";

import styles from "./contact-item.module.scss";

const schema = yupObject()
  .shape({
    phone: yupString()
      .required("Please enter a valid phone number.")
      .matches(
        /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a valid 10 digit phone number"
      ),
    phoneConfirm: yupString().when("phone", {
      is: (val) => val?.length > 0,
      then: (schema) =>
        schema
          .oneOf([yupRef("phone"), undefined, ""], "Phone numbers must match.")
          .required("Please enter a valid phone number."),
    }),
  })
  .required();

type EditContactPhoneFormFields = {
  phone: string;
  phoneConfirm?: string;
};

type EditContactPhoneButtonProps = {
  policyId: string;
  userEmail: string;
  onSuccess: () => void;
};

export const EditContactPhoneButton = ({
  policyId,
  userEmail,
  onSuccess,
}: EditContactPhoneButtonProps) => {
  const { retireProxy, universalLogin } = useFlags();
  const [updatePhoneNumber] = useUpdateInsuredInfoMutation();

  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm<EditContactPhoneFormFields>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSave = async ({ phone }) => {
    setSaving(true);

    try {
      const { data: updatePhoneNumberData } = await updatePhoneNumber({
        variables: {
          policyID: policyId,
          emailAddress: userEmail,
          userUpdates: {
            phoneNumber: phone.replace(/[\D]/g, ""),
          },
        },
        update: (store, { data }) => {
          if (data?.updateInsuredInfo && (!retireProxy || !universalLogin)) {
            const readData = cloneDeep(
              store.readQuery<contactItemDataQuery>({
                query: contactItemDataQuery,
                variables: { policyID: policyId },
              })
            );

            if (phone && readData) {
              readData.userClaimsConstants = {
                ...readData.userClaimsConstants,
                PolicyHolderDetails: {
                  ...readData.userClaimsConstants.PolicyHolderDetails,
                  phoneNumber: formatPhone(phone.replace(/[\D]/g, "")),
                },
              };

              store.writeQuery({
                query: contactItemDataQuery,
                data: readData,
                variables: { policyID: policyId },
              });
            }
          }
        },
        context: {
          clientName: "keystone-api",
        },
      });

      setSaving(false);

      if (updatePhoneNumberData?.updateInsuredInfo) {
        trackEvent("Update Phone Number - complete");
        setShowModal(false);
        addToast("Phone number updated", { icon: true });
        onSuccess();
        reset();
      } else {
        trackEvent("Error Displayed (Update Contact Phone Number)");
        addToast("We apologize. Something went wrong.", {
          icon: true,
          error: true,
        });
      }
    } catch (err) {
      trackEvent("Error Displayed (Update Contact Phone Number)", err);
      setSaving(false);
      addToast("We apologize. Something went wrong.", {
        icon: true,
        error: true,
      });
      logError(`Update phone number: ${err.message}`);
    }
  };

  return (
    <>
      <Button
        appearance="link"
        size="xs"
        onPress={() => setShowModal(true)}
        data-testid="contact-phone-edit-btn"
      >
        Edit
      </Button>
      <Modal
        open={showModal}
        heading="Update phone number"
        onClose={() => setShowModal(false)}
        primaryAction="Save"
        onPrimaryAction={handleSubmit(onSave)}
        disablePrimary={!isValid}
        loadingPrimary={saving}
      >
        <div className={styles.modalContent}>
          <TelephoneMask
            label="Phone number"
            {...register("phone")}
            errorMessage={errors.phone?.message}
            isError={!!errors.phone}
            data-testid="contact-phone"
          />
          <TelephoneMask
            label="Confirm phone number"
            {...register("phoneConfirm")}
            errorMessage={errors.phoneConfirm?.message}
            isError={!!errors.phoneConfirm}
          />
        </div>
      </Modal>
    </>
  );
};
