import { type FormikErrors, useFormikContext } from "formik";

import CheckIcon from "images/icon-small-check.svg";
import CrossIcon from "images/icon-small-x.svg";

import styles from "./password-validation-list.module.scss";

const PASSWORD_VALIDATION_RULES = [
  "No spaces",
  "At least 1 number",
  "At least 1 lowercase letter",
  "At least 1 uppercase letter",
  "At least 8 characters total",
  "Passwords match",
];

type PasswordValidationListProps = {
  password: string;
  passwordConfirmation: string;
};

type PasswordValidationErrors = {
  password?: string;
  passwordConfirmation?: string;
  errors?: string[];
};

export const PasswordValidationList = ({
  password,
  passwordConfirmation,
}: PasswordValidationListProps) => {
  const { errors } = useFormikContext();
  const { errors: passwordErrors }: FormikErrors<PasswordValidationErrors> =
    errors;

  return (
    <ul className={styles.list}>
      {PASSWORD_VALIDATION_RULES.map((rule, i) => (
        <li key={i}>
          {rule}
          {(password || passwordConfirmation) &&
            (passwordErrors?.includes(rule) ? (
              <CrossIcon className={styles.crossIcon} />
            ) : (
              <CheckIcon className={styles.checkIcon} />
            ))}
        </li>
      ))}
    </ul>
  );
};
