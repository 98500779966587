import { type PropsWithChildren, type ReactNode } from "react";

import {
  Heading,
  type ProgressStep,
  ProgressStepper,
} from "@icg360/design-system";

import {
  PropertyUpdateButtons,
  type PropertyUpdateButtonsProps,
} from "./property-update-buttons";
import styles from "./property-update-layout.module.scss";

type PropertyUpdateLayoutProps = PropsWithChildren<{
  buttonProps: Omit<PropertyUpdateButtonsProps, "step">;
  heading: ReactNode;
  numSteps: number;
  step: number;
}>;

export const PropertyUpdateLayout = ({
  buttonProps,
  children,
  heading,
  numSteps,
  step,
}: PropertyUpdateLayoutProps) => {
  const steps: ProgressStep[] = [];
  for (let i = 1; i <= numSteps; i++) {
    if (i < step) {
      steps.push({ status: "completed" });
    } else if (i === step) {
      steps.push({ status: "in-progress" });
    } else {
      steps.push({ status: "incomplete" });
    }
  }

  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.layout}>
        <Heading size="sm" className={styles.mainHeading}>
          {heading}
        </Heading>
        <ProgressStepper appearance="simple" steps={steps} />
        <div className={styles.content}>{children}</div>
      </div>
      <PropertyUpdateButtons {...buttonProps} step={step} />
    </div>
  );
};
