import { useEffect, useState } from "react";
import Dropzone, { ErrorCode, type FileRejection } from "react-dropzone-latest";
import { useMediaQuery } from "react-responsive";

import { Alert, Button, List, Span, UiIcon } from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import {
  PropertyUpdateSecurityContext,
  type PropertyUpdateSecurityContextState,
} from ".";
import { FileThumbnails } from "../file-thumbnails";
import styles from "./security-upload.module.scss";

type RejectionErrorDisplayInfo = {
  fileName: string;
  rejectionReason: string;
};

const MAX_BYTE_FILE_SIZE = 6000000; // 6mb
const MAX_FILE_COUNT = 20;
const REJECTION_MAP: { [key in ErrorCode]?: string } = {
  [ErrorCode.FileInvalidType]: "File type needs be .pdf, .jpg, or .png.",
  [ErrorCode.FileTooLarge]: "File is too large.",
};

export const SecurityUpload = ({
  filesField,
}: {
  filesField: "burglarAlarmFiles" | "fireAlarmFiles";
}) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [rejectionErrorList, setRejectionErrorList] = useState<
    RejectionErrorDisplayInfo[]
  >([]);
  const [fileCountLimit, setFileCountLimit] = useState<number>(MAX_FILE_COUNT);
  const [showTooManyError, setShowTooManyError] = useState(false);
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateSecurityContextState>(
      PropertyUpdateSecurityContext
    );
  const files = state?.[filesField];
  const preparedFiles = files ?? [];
  const isMobile = useMediaQuery({ maxWidth: screenSizes.laptop });

  useEffect(() => {
    setFileCountLimit(MAX_FILE_COUNT - (files ?? []).length);
  }, [files]);

  useEffect(() => {
    setRejectionErrorList(
      rejectedFiles.reduce(
        (
          errorList: RejectionErrorDisplayInfo[],
          fileRejection: FileRejection
        ): RejectionErrorDisplayInfo[] => {
          const { errors } = fileRejection;

          // Prioritize the invalid type error over other errors.
          const errorCode = errors.reduce((curCode, { code }) => {
            if (code === ErrorCode.TooManyFiles) {
              setShowTooManyError(true);
            }
            if ([curCode, code].includes(ErrorCode.FileInvalidType)) {
              curCode = ErrorCode.FileInvalidType;
            } else if (code === ErrorCode.FileTooLarge) {
              curCode = code;
            } else if (code === ErrorCode.TooManyFiles) {
              curCode = code;
            }
            return curCode;
          }, "");

          if (errorCode !== ErrorCode.TooManyFiles) {
            errorList.push({
              fileName: fileRejection.file.name,
              rejectionReason: REJECTION_MAP[errorCode],
            });
          }

          return errorList;
        },
        []
      )
    );
  }, [rejectedFiles]);

  const fileCountValidator = () => {
    if (fileCountLimit <= 0) {
      return {
        code: ErrorCode.TooManyFiles,
        message: "", // we don't use this
      };
    }
    return null;
  };

  const removeFile = (fileToDelete: File & { preview?: string }) => {
    if (fileToDelete?.preview) {
      URL.revokeObjectURL(fileToDelete.preview);
    }
    const newList = preparedFiles.filter((file) => file !== fileToDelete);
    setFields({ [filesField]: newList });
  };

  return (
    <div className={styles.uploadWrapper}>
      <Dropzone
        noClick
        maxSize={MAX_BYTE_FILE_SIZE}
        maxFiles={fileCountLimit}
        validator={fileCountValidator}
        accept={{
          "image/jpg": [".jpg", ".jpeg"],
          "image/png": [".png"],
          "application/pdf": [".pdf"],
        }}
        onDropRejected={setRejectedFiles}
        onDrop={(acceptedFiles) => {
          setShowTooManyError(false);
          const currentFiles = files;
          setFields({
            [filesField]: [
              ...(currentFiles ?? []),
              ...acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              ),
            ] as unknown as FileList,
          });

          if (rejectionErrorList.length > 0) {
            setRejectedFiles([]);
          }
        }}
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => (
          <div
            role="presentation"
            className={isDragActive ? styles.dropAreaDragging : styles.dropArea}
            {...getRootProps()}
            onClick={open}
          >
            <UiIcon name="Document" size="md" />
            {isMobile ? (
              <Button
                size="sm"
                appearance="secondary"
                onPress={open}
                className={styles.uploadButtonDS}
              >
                Upload document
              </Button>
            ) : (
              <Span bold className={styles.dropAreaHeadingDS}>
                Drag your files here
              </Span>
            )}
            <Span color="quiet">Up to 6MB each</Span>
            <Span color="quiet">We can handle: JPG, PNG, PDF</Span>

            {!isMobile && (
              <div className={styles.inputLabel}>
                <Span bold color="interactive">
                  Choose files
                </Span>
              </div>
            )}
            <input
              {...getInputProps({
                type: "file",
                name: filesField,
                multiple: true,
              })}
            />
          </div>
        )}
      </Dropzone>
      {showTooManyError ? (
        <Alert
          title="You've reached the limit."
          description={`You can upload up to ${MAX_FILE_COUNT} files.`}
          appearance="danger"
          className={styles.fileErrorAlertDS}
        />
      ) : null}
      {rejectionErrorList.length > 0 && (
        <Alert
          className={styles.fileErrorAlertDS}
          title={`Something went wrong while uploading the following file${
            rejectionErrorList.length > 1 ? "s" : ""
          }:`}
          appearance="danger"
          description={
            <List type="ul">
              {rejectionErrorList?.map(({ fileName, rejectionReason }) => {
                return (
                  <li key={fileName + rejectionReason}>
                    {`"${fileName}" (${rejectionReason})`}
                  </li>
                );
              })}
            </List>
          }
        />
      )}
      <FileThumbnails files={files} removeFile={removeFile} />
    </div>
  );
};
