import { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Modal } from "@icg360/design-system";

import { EasyPayEnrollmentModal } from "components/easypay-enrollment-modal";
import { ExternalHeader } from "components/external-header";
import { useHomeServicesData } from "components/home-services/hooks";
import { PaperlessModal } from "components/paperless-modal";
import {
  AuthAppContext,
  AuthAppDispatchContext,
} from "components/root/auth-app-provider";
import { type PrivateLoaderData } from "components/root/router";
import { ENROLLMENT_STATUS, ORIGINS, PAYMENT_METHOD_CODE } from "consts";
import { useUserClaimsListQuery } from "gql/__generated__/hooks";
import { useLiveChat } from "hooks/use-live-chat";
import {
  getCookie,
  getPaymentMethodDetails,
  identify,
  isEasyPayEnrolledOrPending,
  isPaperlessDocumentEligible,
  setCookie,
  setMixpanelProfileProperties,
  setMixpanelSuperProperties,
  trackEvent,
  urlSearchParamsObject,
  useFlags,
} from "utils";

import trackClaimsInfo from "./utils/track-claims-info";

type ModalName = "paperless" | "easypay" | "invalid-policy";

const AuthApp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const featureFlags = useFlags();
  const { retireProxy, universalLogin } = featureFlags;
  const [invalidPolicy, setInvalidPolicy] = useState("");
  const [paymentMethod, setPaymentMethod] = useState();
  const [curModal, setCurModal] = useState<ModalName | null>(null);

  const {
    policies,
    selectedPolicyId,
    userBilling,
    userDetails,
    userInfo,
    userPolicyProperties,
    isPolicyDataReady,
  } = useContext(AuthAppContext);
  const { refetchPolicy } = useContext(AuthAppDispatchContext);

  const [initialPageDataLoaded, setInitialPageDataLoaded] = useState(false);
  useEffect(() => {
    if (isPolicyDataReady) {
      setInitialPageDataLoaded(true);
    }
  }, [isPolicyDataReady]);

  useEffect(() => {
    if (!userBilling) {
      return;
    }
    const curPaymentMethod = getPaymentMethodDetails(
      userBilling?.accounting?.paymentPlan?.planType?.toLowerCase()
    ).paymentMethod;
    setPaymentMethod(curPaymentMethod);
    setMixpanelSuperProperties({ paymentPlan: curPaymentMethod });
    setMixpanelProfileProperties({
      paymentPlan: curPaymentMethod,
    });
  }, [userBilling]);

  useEffect(() => {
    if (userDetails) {
      const { currentTerm, policyStatus, propertyAddress, insuredPreferences } =
        userDetails;

      setMixpanelSuperProperties({
        policyStatus,
        agencyLocationCode: currentTerm?.agencyLocationCode,
        propertyState: propertyAddress?.state,
        zipCode: propertyAddress?.zip,
      });

      setMixpanelProfileProperties({
        agencyLocationCode: currentTerm?.agencyLocationCode,
        paperlessDocumentsEnrollment:
          insuredPreferences?.paperlessDocumentEnrollmentStatus ||
          ENROLLMENT_STATUS.NOT_ENROLLED,
        paperlessDocumentsEmail:
          insuredPreferences?.opInsuredEmailAddressDocuments || "",
        paperlessBillingEnrollment:
          insuredPreferences?.paperlessBillingEnrollmentStatus ||
          ENROLLMENT_STATUS.NOT_ENROLLED,
        paperlessBillingEmail:
          insuredPreferences?.opInsuredEmailAddressBilling || "",
      });
    }
  }, [userDetails]);

  //********** START DATA FETCHING **********//
  const loaderData = useLoaderData() as PrivateLoaderData;

  const { data: userClaimsListData } = useUserClaimsListQuery({
    variables: {
      policyID: selectedPolicyId,
    },
    skip: !selectedPolicyId,
  });

  useEffect(() => {
    (async () => {
      const claimsData =
        universalLogin && retireProxy
          ? await loaderData?.claimsList
          : userClaimsListData?.userClaimsList;
      trackClaimsInfo(claimsData);
    })();
  }, [loaderData, retireProxy, universalLogin, userClaimsListData]);

  useLiveChat({
    emailAddress: userDetails?.primaryInsured?.emailAddress,
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    userPolicies: policies,
    selectedPolicy: selectedPolicyId,
  });

  const parsedQS = urlSearchParamsObject();
  const isCommercial = userDetails?.isCommercial;
  const isEasyPayEnrolled = isEasyPayEnrolledOrPending(
    userBilling,
    userDetails
  );

  // Populate Qualtrics user data.
  useEffect(() => {
    if (policies) {
      window.SURVEY_USER_FIRST_NAME = userInfo?.firstName;
      window.SURVEY_USER_LAST_NAME = userInfo?.lastName;
      window.SURVEY_USER_EMAIL = userInfo?.email;
      window.SURVEY_USER_POLICY_NUMBERS = policies
        .map((p) => p?.policyId)
        .join(", ");
    }
  }, [policies, userInfo]);

  // Populate mixpanel data
  useEffect(() => {
    if (!userDetails || !selectedPolicyId || !featureFlags) {
      return;
    }
    // Set super properties / flags.
    const enabledFlags = Object.keys(featureFlags).filter(
      (key) => featureFlags[key]
    );
    setMixpanelSuperProperties({
      enabledFeatureFlag: enabledFlags,
      policyNumber: selectedPolicyId,
      isBookRoll: userDetails.isBookRoll,
    });
  }, [featureFlags, userDetails, selectedPolicyId]);

  useHomeServicesData();

  useEffect(() => {
    if (!isPolicyDataReady) {
      return;
    }

    const { agencyLocationCode, carrierGroup, policyType, policyState } =
      userPolicyProperties ?? {};
    const isActivePolicy = RegExp(/(^active)/gi).test(
      userDetails?.policyStatus ?? ""
    );

    const userName = userInfo?.userName?.toLowerCase() || "NEW_USER";
    const email = userInfo?.email?.toLowerCase() ?? "";
    const firstName = userInfo?.firstName?.toLowerCase();

    setCookie("mss-username", btoa(email));

    // Send user info to LaunchDarkly
    identify({
      email,
      firstName,
      policyType,
      agencyLocationCode,
      policyState,
      isActivePolicy,
      kind: "user",
      key: userName,
      carrierID: carrierGroup,
      policyPrefix: selectedPolicyId.slice(0, 3),
      isCommercial: userDetails?.isCommercial,
      zipcode: userDetails?.propertyAddress?.zip,
    });
  }, [
    isPolicyDataReady,
    selectedPolicyId,
    userDetails,
    userInfo,
    userPolicyProperties,
  ]);

  useEffect(() => {
    if (!initialPageDataLoaded || location.pathname !== "/my/overview") {
      return;
    }
    initializeModals();
  }, [initialPageDataLoaded]);

  const initializeModals = () => {
    if (parsedQS.origin === ORIGINS.DIGITAL_INVOICE) {
      // if the policyNumber were valid, it would have been removed from the qs (look in useAuthAppData)
      if (parsedQS.policyNumber) {
        trackEvent("Error Modal Displayed (policy not found)", {
          policyNumber: parsedQS.policyNumber,
          errorMessage: "Policy is not linked to the account",
        });
        setInvalidPolicy(parsedQS.policyNumber);
        setCurModal("invalid-policy");
        navigate("/my/overview");
      } else if (isEasyPayEnrolled) {
        trackEvent("Error Modal Displayed (easypay enrolled)", {
          policyNumber: selectedPolicyId,
          errorMessage: "Policy is already enrolled in EasyPay",
        });
        setCurModal("easypay");
      }
      navigate(location.pathname);
    } else {
      if (
        getCookie("mss-easypay-modal-dismissed") === undefined &&
        paymentMethod !== PAYMENT_METHOD_CODE.INVOICE &&
        !isEasyPayEnrolled
      ) {
        setCurModal("easypay");
      } else if (
        getCookie("mss-paperless-modal-dismissed") === undefined &&
        isPaperlessDocumentEligible(userDetails)
      ) {
        setCurModal("paperless");
      }
    }
  };

  const handleEasypayNavigation = () => {
    navigate("/my/billing/update");
    setCurModal(null);
    setCookie("mss-easypay-modal-dismissed", true);
  };

  const onEasyPayModalClose = () => {
    setCurModal(null);
    setCookie("mss-easypay-modal-dismissed", true);
  };

  return (
    <>
      <ExternalHeader returnUrlParsed />
      <Outlet />
      {initialPageDataLoaded && (
        <PaperlessModal
          show={curModal === "paperless" && !isCommercial}
          onClose={() => {
            setCurModal(null);
            setCookie("mss-paperless-modal-dismissed", true);
          }}
          documentsEmailAddress={
            userDetails?.insuredPreferences?.opInsuredEmailAddressDocuments ??
            userDetails?.insuredPreferences?.opInsuredEmailAddress ??
            ""
          }
          userDetails={userDetails}
          userBilling={userBilling}
          refetch={refetchPolicy}
          policyId={selectedPolicyId}
        />
      )}
      {paymentMethod !== PAYMENT_METHOD_CODE.INVOICE && (
        <EasyPayEnrollmentModal
          show={curModal === "easypay"}
          onEnroll={handleEasypayNavigation}
          onClose={onEasyPayModalClose}
        />
      )}
      <Modal
        open={curModal === "invalid-policy"}
        heading="This policy is not in your account."
        onClose={() => setCurModal(null)}
        primaryAction="Add new policy"
        onPrimaryAction={() => {
          setCurModal(null);
          navigate("/my/settings/policies");
        }}
      >
        {`Policy # ${invalidPolicy} is not currently linked to your online account. To continue EasyPay enrollment, switch to another account or add new policy to your account.`}
      </Modal>
    </>
  );
};

export default AuthApp;
