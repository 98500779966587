import { Card } from "@icg360/design-system";

import { useEnvelopeToSign } from "hooks/use-envelope-to-sign";
import { DocumentSignRow } from "pages/documents/documents/self-service-center/document-sign-row";

const SelfServiceCenter = () => {
  const { envelopeToSign } = useEnvelopeToSign();

  return envelopeToSign ? (
    <Card title="Self service center">
      <DocumentSignRow
        documentType={envelopeToSign.documentType}
        documentStatus={envelopeToSign.documentStatus}
        envelopeId={envelopeToSign.id}
      />
    </Card>
  ) : null;
};

export { SelfServiceCenter };
