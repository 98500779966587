import { useContext, useEffect } from "react";
import { useLoaderData } from "react-router-dom";

import { BaseLayout } from "components/base-layout";
import { FetchingError } from "components/common/fetching-error";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { EasyPayCard } from "components/shared/easypay-card";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { MainLayout } from "components/shared/main-layout";
import { PaperlessCard } from "components/shared/paperless-card";
import {
  type Transaction,
  useBillingPageDataQuery,
} from "gql/__generated__/hooks";
import { isEasyPayEligible, scrollToTop, trackEvent, useFlags } from "utils";

import Balance from "./billing/balance";
import { History } from "./billing/history";
import { PaymentPlanSidebar } from "./billing/payment-plan-sidebar";
import { UpcomingPayments } from "./billing/upcoming-payments";

type BillingLoaderData = {
  billingEmailAddress?: string | null;
  transactions?: Transaction[];
} | null;

const Billing = () => {
  const { universalLogin, retireProxy } = useFlags();
  const loaderData = useLoaderData() as BillingLoaderData;

  const { selectedPolicyId, userBilling, userDetails, userCarrierInfo } =
    useContext(AuthAppContext);

  const {
    loading,
    error,
    data: billingPageData,
  } = useBillingPageDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
    skip: !selectedPolicyId,
  });

  const {
    userBillingAddress: userBillingEmailAddress,
    userTransactions,
    policyPaymentPlans,
  } = billingPageData ?? {};

  const { installments } = userBilling ?? {};

  const billingEmail =
    universalLogin && retireProxy
      ? loaderData?.billingEmailAddress
      : userBillingEmailAddress;
  const transactions =
    universalLogin && retireProxy
      ? loaderData?.transactions
      : userTransactions?.transactions;

  useEffect(() => {
    trackEvent("Billing");
    scrollToTop();
  }, []);

  if (error) {
    return (
      <BaseLayout>
        <MainLayout sidebar={<FaqSidebar page="billing" />}>
          <FetchingError page="Billing" />
        </MainLayout>
      </BaseLayout>
    );
  }

  if (loading) {
    return <BaseLayout loading />;
  }

  const Main = () => (
    <Stack>
      <Balance />
      <UpcomingPayments installments={installments ?? []} />
      <History
        transactions={transactions ?? []}
        carrierId={userCarrierInfo?.id ?? ""}
      />
    </Stack>
  );

  const Sidebar = () => (
    <Stack>
      <PaymentPlanSidebar
        userBillingEmailAddress={billingEmail}
        policyPaymentPlans={policyPaymentPlans}
      />
      {isEasyPayEligible(userBilling, userDetails) && <EasyPayCard />}
      <PaperlessCard pageName="Billing" />
      <FaqSidebar page="billing" />
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "ScheduledPayment",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={<Sidebar />}>
        <Main />
      </MainLayout>
    </BaseLayout>
  );
};

export default Billing;
