import cx from "classnames";

import { Span } from "@icg360/design-system";

import { type PROPERTY_ROOF_TYPE, PROPERTY_ROOF_UPDATE_TYPE } from "consts";

import styles from "./roof-material-card.module.scss";

type RoofMaterialCardProps = {
  roofType: PROPERTY_ROOF_TYPE;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
};

export const RoofMaterialCard = ({
  roofType,
  onClick,
  selected,
}: RoofMaterialCardProps) => {
  const { imgSrc, label } = PROPERTY_ROOF_UPDATE_TYPE[roofType];

  return (
    <button
      className={cx(
        styles.materialCard,
        selected && styles.highlighted,
        !imgSrc && styles.noImg
      )}
      onClick={onClick}
      key={roofType}
    >
      {imgSrc ? (
        <img src={imgSrc} alt={label} className={styles.materialImage} />
      ) : (
        <div className={styles.imagePlaceholder} /> // Empty div to preserve width
      )}

      <Span
        bold
        size="sm"
        color={selected ? "default" : "quiet"}
        className={styles.materialCardLabelDS}
      >
        {label}
      </Span>
    </button>
  );
};
