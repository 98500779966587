import { Field, Form, Formik, useFormikContext } from "formik";
import { type ReactNode, useContext, useState } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { Button, Card, Heading, addToast } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Body } from "components/common/body";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import {
  CheckboxField,
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import {
  useSendPropertyProfileUpdateNotificationMutation,
  useUserPropertyProfileDataQuery,
} from "gql/__generated__/hooks";
import { logError, trackEvent, useFlags } from "utils";

import { usePropertyProfilePage } from "../hooks";
import styles from "./update-trampoline.module.scss";

export type TrampolineFormValues = {
  trampoline: null | boolean;
  trampolineShockAbsorbing: null | boolean;
  iAgreeCheckbox: boolean;
};

const initialValues: TrampolineFormValues = {
  trampoline: null,
  trampolineShockAbsorbing: null,
  iAgreeCheckbox: false,
};

export const UpdateTrampoline = () => {
  const navigate = useNavigate();
  const { universalLogin, retireProxy } = useFlags();
  const loaderData = useLoaderData() as PropertyProfileLoaderData;
  const { selectedPolicyId, userInfo } = useContext(AuthAppContext);
  const { data: propertyProfileData } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });
  const { setPropertyUpdated } = usePropertyProfilePage(
    universalLogin && retireProxy
      ? loaderData?.propertyProfile
      : propertyProfileData
  );

  const [sendPropertyProfileUpdateNotification] =
    useSendPropertyProfileUpdateNotificationMutation();

  const handleSubmit = async (
    { trampoline, trampolineShockAbsorbing },
    { setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      trackEvent("Property trampoline - Updates submitted", {
        trampoline,
        trampolineShockAbsorbing,
      });

      const { data } = await sendPropertyProfileUpdateNotification({
        variables: {
          policyID: selectedPolicyId,
          emailAddress: userInfo?.email,
          username: userInfo?.email,
          endorsementUpdates: {
            trampoline,
            trampolineHasShockAbsorbingPadding: trampolineShockAbsorbing,
          },
        },
        context: {
          clientName: "keystone-api",
        },
      });
      if (data) {
        trackEvent("Property trampoline - Update completed");
        setPropertyUpdated(true);
        addToast(
          "We have received your update! Once validated, you will see these changes reflected here on your property profile.",
          { icon: true, duration: 10000 }
        );
        navigate("/my/property");
      }
    } catch (error) {
      logError(`Update trampoline: ${error.message}`);
      setStatus({ type: "error", message: error.message });
      trackEvent("Property trampoline - Update submission error", {
        errorCode: error.code,
        errorMessage: error.message,
      });
    }

    setSubmitting(false);
  };

  return (
    <BaseLayout hideNavigation>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Outlet />
      </Formik>
    </BaseLayout>
  );
};

export const UpdateTrampolineForm = () => {
  const navigate = useNavigate();
  const [hasSelectedTrampoline, setHasSelectedTrampoline] = useState(false);

  const { values, setFieldValue } = useFormikContext<TrampolineFormValues>();
  const { trampoline, trampolineShockAbsorbing, iAgreeCheckbox } = values;

  let progressValue = 50;
  let sideHeadline = "Support";
  let sideBody: ReactNode =
    "Your policy may contain liability coverage restrictions regarding your trampoline, please contact your insurance representative for further guidance.";

  const setNextSteps = () => {
    if (!trampoline) {
      trackEvent("Property Trampoline - Sign attestation page load", {
        trampoline,
      });
    } else if (trampoline) {
      trackEvent(
        "Property Trampoline - Edit shock-absorbing padding page load",
        { trampoline, trampolineShockAbsorbing }
      );
    }

    setHasSelectedTrampoline(true);
  };

  let main = (
    <Card className={styles.cardContainer}>
      <Heading size="lg">Do you have a trampoline on the property?</Heading>
      <br />
      <Form>
        <Field
          name="trampoline"
          component={RadioButtonGroup}
          onChange={(value: boolean) => {
            setFieldValue("trampoline", value);
            if (!value) {
              setFieldValue("trampolineShockAbsorbing", null);
            }
          }}
          value={trampoline}
          aria-label="Do you have a trampoline on the property?"
          fieldChildren={() => (
            <>
              <Field
                value={true}
                label="Yes"
                component={RadioButtonField}
                data-testid="trampoline-yes"
              />
              <Field
                value={false}
                label="No"
                component={RadioButtonField}
                data-testid="trampoline-no"
              />
            </>
          )}
        />
      </Form>
      <div className={styles.navigation}>
        <Button appearance="tertiary" onPress={() => navigate("/my/property")}>
          Go back
        </Button>
        <Button
          appearance="primary"
          size="default"
          onPress={() => setNextSteps()}
          disabled={trampoline === null}
          data-testid="trampoline-yes-no-continue-btn"
        >
          Continue
        </Button>
      </div>
    </Card>
  );

  if (hasSelectedTrampoline) {
    progressValue = 75;
    if (!trampoline) {
      main = (
        <Card className={styles.cardContainer}>
          <Heading size="lg">Please confirm your property update.</Heading>
          <br />
          <Body>
            I confirm that I no longer own or have never owned a trampoline on
            my property. I agree to notify SageSure in the future if I add a
            trampoline, and I understand liability coverage is excluded.
          </Body>
          <Form>
            <br />
            <Field
              name="iAgreeCheckbox"
              label="Yes, I agree."
              component={CheckboxField}
            />
          </Form>
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onPress={() => setHasSelectedTrampoline(false)}
              data-testid="no-trampoline-go-back-btn"
            >
              Go back
            </Button>
            <Button
              appearance="primary"
              size="default"
              onPress={() => navigate("/my/property/trampoline/review")}
              disabled={!iAgreeCheckbox}
              data-testid="no-trampoline-continue-btn"
            >
              Continue
            </Button>
          </div>
        </Card>
      );
    } else {
      sideHeadline = "Trampoline safety";
      sideBody = (
        <>
          Shock absorbing <b>padding over the springs, hooks and frame</b> is
          highly recommended for trampoline safety.
        </>
      );
      main = (
        <Card className={styles.cardContainer}>
          <Heading size="lg">
            Does your trampoline have shock-absorbing padding?
          </Heading>
          <br />
          <Form>
            <Field
              name="trampolinePadding"
              component={RadioButtonGroup}
              onChange={(value) => {
                setFieldValue("trampolineShockAbsorbing", value);
              }}
              value={trampolineShockAbsorbing}
              aria-label="Does your trampoline have shock-absorbing padding?"
              fieldChildren={() => (
                <>
                  <Field
                    value={true}
                    label="Yes"
                    component={RadioButtonField}
                    data-testid="trampoline-padding-yes"
                  />
                  <Field
                    value={false}
                    label="No"
                    component={RadioButtonField}
                    data-testid="trampoline-padding-no"
                  />
                </>
              )}
            />
          </Form>
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onPress={() => setHasSelectedTrampoline(false)}
            >
              Go back
            </Button>
            <Button
              appearance="primary"
              size="default"
              onPress={() => navigate("/my/property/trampoline/review")}
              disabled={trampolineShockAbsorbing === null}
              data-testid="trampoline-padding-continue-btn"
            >
              Continue
            </Button>
          </div>
        </Card>
      );
    }
  }

  return (
    <>
      <ProgressBar progress={progressValue} />
      <MainLayout
        sidebar={
          !trampoline && hasSelectedTrampoline ? null : (
            <PropertyProfileSidebar
              headline={sideHeadline}
              body={<p>{sideBody}</p>}
              iconName="Question"
            />
          )
        }
      >
        {main}
      </MainLayout>
    </>
  );
};
