import { type ReactNode } from "react";

import { type PoolFormStep } from "consts";

type UpdatePoolFormContent = {
  progress: number;
  sidebar?: {
    title: string;
    content: ReactNode;
  };
};

export const UPDATE_POOL_FORM: Record<PoolFormStep, UpdatePoolFormContent> = {
  poolType: {
    progress: 10,
    sidebar: {
      title: "Support",
      content: (
        <p>
          If you have any additional questions about how we can support you and
          give you the best coverage for your pool, please reach out to your
          insurance representative.
        </p>
      ),
    },
  },
  poolFence: {
    progress: 20,
    sidebar: {
      title: "Pool safety",
      content: (
        <p>
          Secure fencing <b>around the perimeter of your pool</b> can help
          prevent accidents.
        </p>
      ),
    },
  },
  fenceDetails: {
    progress: 25,
    sidebar: {
      title: "Fencing",
      content: (
        <p>
          A fence height of <b>at least 4 feet</b> with a <b>locking gate</b> is
          recommended for pool safety.
        </p>
      ),
    },
  },
  aboveGroundPoolMinimumHeight: {
    progress: 30,
  },
  boardOrSlide: {
    progress: 40,
  },
  poolFilled: {
    progress: 50,
  },
  poolCovered: {
    progress: 60,
    sidebar: {
      title: "Pool covers",
      content: (
        <p>
          Complete and <b>secure covering with permanent anchors</b> can prevent
          accidental injury from occurring in your unfilled pool.
        </p>
      ),
    },
  },
  poolImmovableLadder: {
    progress: 70,
  },
  poolAttachedToDecking: {
    progress: 80,
  },
  poolDeckGateLocks: {
    progress: 90,
    sidebar: {
      title: "Gate locks",
      content: (
        <p>
          The gate should be <b>self-locking, self-latching</b>, or be secured
          by a <b>combination lock or padlock.</b>
        </p>
      ),
    },
  },
};

type PoolTypes = "NONE" | "INGROUNDPOOL" | "ABOVEGROUNDPOOL";

export type UpdatePoolValueKeys =
  | "poolType"
  | "divingBoard"
  | "poolAttachedToDecking"
  | "poolCovered"
  | "poolDeckGateLocks"
  | "poolFence"
  | "poolFenceLockingGate"
  | "poolFenceMinimumHeight"
  | "poolFilled"
  | "poolImmovableLadder"
  | "poolSlide"
  | "aboveGroundPoolMinimumHeight"
  | "confirmNoPool";

export type UpdatePoolValues = {
  poolType: PoolTypes | null;
  divingBoard: boolean | null;
  poolAttachedToDecking: boolean | null;
  poolCovered: boolean | null;
  poolDeckGateLocks: boolean | null;
  poolFence: boolean | null;
  poolFenceLockingGate: boolean | null;
  poolFenceMinimumHeight: boolean | null;
  poolFilled: boolean | null;
  poolImmovableLadder: boolean | null;
  poolSlide: boolean | null;
  aboveGroundPoolMinimumHeight: boolean | null;
  confirmNoPool: boolean;
};
