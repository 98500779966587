import { Fragment, useState } from "react";
import MediaQuery from "react-responsive";
import { TransitionGroup } from "react-transition-group";

import { Card } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/common/table";
import { MSSTransition } from "components/shared/mss-transition";
import { ShowAllButton } from "components/show-all-button";
import { MINIMUM_TRANSACTIONS_SHOWN, TRANSITION_DURATION } from "consts";
import { type Transaction } from "gql/__generated__/hooks";
import { formatCurrency, formatDate } from "utils";

import styles from "./history.module.scss";

const CC_ABREVIATIONS = {
  "American Express": "AMEX",
};

type PaymentDetailsProps = {
  accountNumber: string;
  creditCardType: string;
  method: string;
};

const PaymentDetails = ({
  accountNumber,
  creditCardType,
  method,
}: PaymentDetailsProps) => {
  let displayedMethod = method;

  if (method === "creditcard") {
    const abreviatedMethod = CC_ABREVIATIONS[creditCardType];

    displayedMethod = abreviatedMethod || creditCardType || "CREDIT CARD";
  }
  return (
    <>
      {displayedMethod && accountNumber
        ? ` (${displayedMethod.toUpperCase()}-${accountNumber})`
        : ""}
    </>
  );
};

type TransactionListProps = {
  transactions: Transaction[];
  numShownTransactions: number;
  hasSurplusFee: boolean;
};

const TransactionsList = ({
  transactions,
  numShownTransactions,
  hasSurplusFee,
}: TransactionListProps) => (
  <TransitionGroup component={Fragment} key={transactions[0].date}>
    {transactions.map(
      (
        {
          description,
          amount,
          date,
          accountNumber,
          creditCardType,
          method,
          type,
        },
        idx
      ) => {
        let displayedDescription = description;
        if (hasSurplusFee) {
          displayedDescription = description?.replace("Premium", "Grand Total");
        }
        return (
          <MSSTransition
            show={idx < numShownTransactions}
            timeout={TRANSITION_DURATION}
            unmountOnExit={true}
            key={`${formatDate(date)}-${idx}`}
          >
            <TableRow data-testid="billing-history-item">
              <MediaQuery maxWidth={768}>
                {(matches) =>
                  matches ? (
                    <TableCell>{formatDate(date, "M/D/YY")}</TableCell>
                  ) : (
                    <TableCell>{formatDate(date)}</TableCell>
                  )
                }
              </MediaQuery>
              <TableCell>
                {displayedDescription}
                {type === "PAYMENT" && (
                  <PaymentDetails
                    creditCardType={creditCardType ?? ""}
                    accountNumber={accountNumber ?? ""}
                    method={method ?? ""}
                  />
                )}
              </TableCell>
              <TableCell align="right">
                {formatCurrency(amount, true)}
              </TableCell>
            </TableRow>
          </MSSTransition>
        );
      }
    )}
  </TransitionGroup>
);

type HistoryProps = {
  transactions: Transaction[];
  carrierId: string;
};

export const History = ({ transactions, carrierId }: HistoryProps) => {
  const [numShownTransactions, setNumShownTransactions] = useState(
    MINIMUM_TRANSACTIONS_SHOWN
  );

  const hasSurplusFee = carrierId === "SURE";

  const updateNumShownTransactions = () => {
    setNumShownTransactions(
      numShownTransactions === MINIMUM_TRANSACTIONS_SHOWN
        ? transactions.length
        : MINIMUM_TRANSACTIONS_SHOWN
    );
  };

  return (
    <Card title="Activity">
      {transactions.length > 0 ? (
        <div className={styles.history}>
          <Table className={styles.historyTable}>
            <TableHead>
              <TableRow>
                <TableHeader>Date</TableHeader>
                <TableHeader>Description</TableHeader>
                <TableHeader align="right">Amount</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TransactionsList
                transactions={transactions}
                numShownTransactions={numShownTransactions}
                hasSurplusFee={hasSurplusFee}
              />
            </TableBody>
          </Table>
          <div className={styles.showAll}>
            <ShowAllButton
              type="Transactions"
              shownNb={numShownTransactions}
              setShownNb={updateNumShownTransactions}
              minimumShownNb={MINIMUM_TRANSACTIONS_SHOWN}
              totalNb={transactions.length}
            />
          </div>
        </div>
      ) : (
        <div className={styles.history}>
          <Body className="keyDescription">
            There is no account history available at this time.
          </Body>
        </div>
      )}
    </Card>
  );
};
