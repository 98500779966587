import classnames from "classnames";

import {
  Alert,
  Button,
  Link as DSLink,
  Heading,
  Icon,
  Spinner,
} from "@icg360/design-system";

import ThumbtackLogo from "images/icon-thumbtack.svg";

import { useThumbtackPros } from "./hooks";
import { Pills } from "./pills";
import { Stars } from "./stars";
import styles from "./thumbtack-modal.module.scss";

type ThumbtackModalProps = {
  onClose: () => void;
  zipCode: string;
  category: string;
  action?: string;
  show: boolean;
};

export const ThumbtackModal = ({
  onClose,
  zipCode,
  category,
  action,
  show,
}: ThumbtackModalProps) => {
  const { results, loading, error } = useThumbtackPros(zipCode, category);
  return (
    // This and the below are ok because they're duplicate actions to or related to the close button
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classnames(styles.modal, { [styles.show]: show })}
      onClick={onClose}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <section className={styles.panel} onClick={stopBubbling}>
        <header className={styles.header}>
          <div className={styles.closeBtn}>
            <Button
              appearance="tertiary"
              size="sm"
              icon="Close"
              onPress={onClose}
              data-testid="thumbtack-close-btn"
            />
          </div>
          {!loading && !error && (
            <>
              {action && <Heading size="md">{action}</Heading>}
              <div>
                {results.length} partner-recommended contractor
                {results.length === 1 ? "" : "s"}
              </div>
              {results.length ? (
                <p>
                  Here
                  {results.length === 1
                    ? " is a highly rated contractor"
                    : " are a few highly rated contractors"}{" "}
                  in your area
                </p>
              ) : null}
              <div className={styles.searchParams}>
                <div className={styles.param}>
                  <div className={styles.paramIcon}>
                    <Icon name="Tools" size="lg" />
                  </div>
                  <span className={styles.label}>{category}</span>
                </div>
                <div className={styles.param}>
                  <div className={styles.paramIcon}>
                    <Icon name="Locator" size="lg" />
                  </div>
                  <span className={styles.label}>{zipCode}</span>
                </div>
              </div>
            </>
          )}
        </header>
        <div className={styles.scrollable}>
          <main className={styles.content}>
            {error && <ThumbtackError />}
            {loading && !error && <ThumbtackLoading />}
            {!loading && !error && <ProList results={results} />}
          </main>
          <footer className={styles.footer}>
            <div className={styles.partner}>
              <ThumbtackLogo width={20} />{" "}
              <span className={styles.partnerLabel}>
                Partnered with Thumbtack
              </span>
            </div>
            <p>
              We partnered with Thumbtack to make it easier for you to find
              licensed contractors and get the work you need done. SageSure is
              not affiliated with or otherwise endorsing Thumbtack or the
              contractors listed on the Thumbtack website or app. SageSure may
              receive a fee from Thumbtack based on your selection of a
              Thumbtack contractor.
            </p>
          </footer>
        </div>
      </section>
    </div>
  );
};

const ThumbtackError = () => (
  <Alert
    appearance="danger"
    title="Oops, something went wrong!"
    description={
      <>
        We are not able to show any results at this time. Please try again or
        contact customer service at{" "}
        <DSLink href="tel:8093288821">(809) 328-8821</DSLink>
      </>
    }
  />
);

const ThumbtackLoading = () => (
  <div className={styles.modalState}>
    <div className={styles.modalStateIcon}>
      <Spinner />
    </div>
    <p>
      <strong>We’re checking for contractors in your area.</strong> This will
      take a few moments. Please do not close your browser or refresh the
      screen.
    </p>
  </div>
);

const ProList = ({ results }) => (
  <ul>
    {results.length === 0 ? (
      <div className={styles.emptyResults}>No contractors found</div>
    ) : (
      results.map((pro) => (
        <li key={pro.service_id}>
          <a
            href={pro.thumbtack_url}
            target="_blank"
            className={styles.card}
            rel="noreferrer"
          >
            <img
              src={pro.image_url}
              className={styles.thumb}
              alt="contractor logo"
            />
            <div className={styles.details}>
              <div className={styles.businessName}>
                <Heading size="sm">{pro.business_name}</Heading>
              </div>
              <Stars rating={pro.rating} count={pro.num_reviews} />
              {pro.pills && <Pills keys={pro.pills} />}
            </div>
            <div className={styles.linkIcon}>
              <Icon name="OpenNewTab" size="sm" />{" "}
            </div>
          </a>
        </li>
      ))
    )}
  </ul>
);

function stopBubbling(e) {
  e.stopPropagation();
}
