import { useContext, useEffect, useState } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import {
  type DocusignStatusQuery,
  useDocusignStatusQuery,
} from "gql/__generated__/hooks";
import { getCookie } from "utils";

const RECIPIENT_STATUSES_TO_SHOW = ["sent", "declined", "delivered"];

export const useEnvelopeToSign = () => {
  const { userDetails } = useContext(AuthAppContext);
  const hideDocumentsToSign = getCookie("hide-documents-to-sign");

  // We're ignoring errors and just not rendering in that case
  const { data, loading } = useDocusignStatusQuery({
    variables: { insightPolicyId: userDetails?.keystonePolicyId ?? "" },
    skip: hideDocumentsToSign || !userDetails?.keystonePolicyId,
    context: {
      clientName: "keystone-api",
    },
  });

  const [envelopeToSign, setEnvelopeToSign] =
    useState<NonNullable<DocusignStatusQuery["docusignStatus"]>[number]>(null);
  useEffect(() => {
    const applicableDocuments = hideDocumentsToSign
      ? []
      : data?.docusignStatus?.filter(
          (status) =>
            // EasyPay documents are a kinda wierd side effect of the way we sign up for easy pay
            // They don't actually need to be signed
            status?.documentType !== "EasyPay"
        ) ?? [];

    applicableDocuments.sort((a, b) =>
      a?.createdDateTime > b?.createdDateTime ? -1 : 1
    );

    const signers = applicableDocuments[0]?.signers ?? [];
    const recipientStatus = signers[0]?.status ?? "";

    setEnvelopeToSign(
      RECIPIENT_STATUSES_TO_SHOW.includes(recipientStatus)
        ? applicableDocuments[0]
        : null
    );
  }, [data?.docusignStatus, hideDocumentsToSign]);

  return { envelopeToSign, loading };
};
