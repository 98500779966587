import moment from "moment";
import { Fragment, useState } from "react";
import MediaQuery from "react-responsive";
import { TransitionGroup } from "react-transition-group";

import { Card } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/common/table";
import { MSSTransition } from "components/shared/mss-transition";
import { ShowAllButton } from "components/show-all-button";
import { MINIMUM_PAYMENTS_SHOWN, TRANSITION_DURATION } from "consts";
import { type Installment } from "gql/__generated__/hooks";
import { formatCurrency, formatDate } from "utils";

import styles from "./upcoming-payments.module.scss";

export const UpcomingPayments = ({
  installments,
}: {
  installments: Installment[];
}) => {
  const futureInstallments = installments.filter((installment) =>
    moment(installment.dueDateMillis).isAfter(moment(moment.now()))
  );

  const [numShownPayments, setNumShownPayments] = useState(
    MINIMUM_PAYMENTS_SHOWN
  );

  const updateNumShownPayments = () => {
    setNumShownPayments(
      numShownPayments === MINIMUM_PAYMENTS_SHOWN
        ? futureInstallments.length
        : MINIMUM_PAYMENTS_SHOWN
    );
  };

  return (
    <Card title="Upcoming payments">
      {futureInstallments.length > 0 ? (
        <div className={styles.upcomingPayments}>
          <Table className={styles.paymentsTable}>
            <TableHead>
              <TableRow>
                <TableHeader>Invoice date</TableHeader>
                <TableHeader>Due date</TableHeader>
                <TableHeader align="right">Amount</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TransitionGroup
                component={Fragment}
                key={futureInstallments[0].dueDateMillis}
              >
                {futureInstallments
                  .sort((a, b) => (a?.number ?? 0) - (b?.number ?? 0))
                  .map(({ amount, dueDateMillis, invoiceDateMillis }, idx) => (
                    <MSSTransition
                      show={idx < numShownPayments}
                      timeout={TRANSITION_DURATION}
                      unmountOnExit={true}
                      key={`${formatDate(dueDateMillis)}-${idx}`}
                    >
                      <TableRow>
                        <MediaQuery maxWidth={768}>
                          {(matches) =>
                            matches ? (
                              <TableCell>
                                {formatDate(invoiceDateMillis, "M/D/YY")}
                              </TableCell>
                            ) : (
                              <TableCell>
                                {formatDate(invoiceDateMillis)}
                              </TableCell>
                            )
                          }
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                          {(matches) =>
                            matches ? (
                              <TableCell>
                                {formatDate(dueDateMillis, "M/D/YY")}
                              </TableCell>
                            ) : (
                              <TableCell>{formatDate(dueDateMillis)}</TableCell>
                            )
                          }
                        </MediaQuery>
                        <TableCell align="right">
                          {formatCurrency(amount, true)}
                        </TableCell>
                      </TableRow>
                    </MSSTransition>
                  ))}
              </TransitionGroup>
            </TableBody>
          </Table>
          <div className={styles.showAll}>
            <ShowAllButton
              type="Payments"
              shownNb={numShownPayments}
              setShownNb={updateNumShownPayments}
              minimumShownNb={MINIMUM_PAYMENTS_SHOWN}
              totalNb={futureInstallments.length}
            />
          </div>
        </div>
      ) : (
        <div className={styles.upcomingPayments}>
          <Body>There are no upcoming payments available at this time.</Body>
        </div>
      )}
    </Card>
  );
};
