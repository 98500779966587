import { type FieldProps } from "formik";
import get from "lodash.get";
import { type ReactNode, useRef } from "react";

import {
  Radio,
  RadioGroup,
  type RadioGroupProps,
  type RadioProps,
} from "@icg360/design-system";

import { scrollToError } from "utils";

type RadioButtonFieldProps = Omit<RadioProps, "children" | "value"> &
  FieldProps & {
    label: string;
    id: string;
  };

export const RadioButtonField = ({
  field: { name },
  form: { errors, isSubmitting },
  id,
  label,
  ...props
}: RadioButtonFieldProps) => {
  const errorsField = get(errors, name);

  const inputRef = useRef(null);
  scrollToError(name, inputRef, errorsField, isSubmitting, errors);
  return (
    <Radio value={id} {...props}>
      {label}
    </Radio>
  );
};

type RadioButtonGroupProps = Omit<RadioGroupProps, "children"> &
  FieldProps & {
    touched: boolean;
    title: string;
    fieldChildren: () => ReactNode;
  };

export const RadioButtonGroup = ({
  name,
  error,
  touched,
  title,
  onChange,
  ...props
}: RadioButtonGroupProps) => (
  <RadioGroup
    onChange={onChange}
    label={title}
    name={name}
    isError={touched && error}
    errorMessage={error}
    {...props}
  >
    <props.fieldChildren />
  </RadioGroup>
);
