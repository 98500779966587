import { Button } from "@icg360/design-system";

type ShowAllButtonProps = {
  shownNb: number;
  setShownNb: () => void;
  minimumShownNb: number;
  type: string;
  totalNb: number;
};

export const ShowAllButton = ({
  shownNb,
  setShownNb,
  minimumShownNb,
  type,
  totalNb,
}: ShowAllButtonProps) => (
  <>
    {totalNb > minimumShownNb && (
      <Button
        appearance="secondary"
        size="sm"
        onPress={setShownNb}
        data-testid="show-all-btn"
      >
        {shownNb === minimumShownNb
          ? `Show all ${type.toLowerCase()}`
          : "Show less"}
      </Button>
    )}
  </>
);
