import cx from "classnames";
import { Field, Form, useFormikContext } from "formik";

import { Button, Icon, Tooltip } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";

import styles from "./bill-pay.module.scss";

export const BillPayForm = () => {
  const { isSubmitting, isValid } = useFormikContext();

  return (
    <Form id="bill-payment" name="bill-payment" className={styles.form}>
      <Field
        name="policyNumber"
        title={<span className={styles.fieldLabel}>Policy #</span>}
        component={InputField}
        placeholder="Enter 10 or 12 Digit Policy Number"
      />
      <Field
        name="zipCode"
        title={
          <span className={cx(styles.fieldLabel, styles.withTooltip)}>
            Zip code
            <Tooltip
              content={
                "If your policy has more than one insured locations, zip code of the first/primary location must be entered"
              }
            >
              <span className={styles.questionIcon}>
                <Icon name="Question" size="sm" />
              </span>
            </Tooltip>
          </span>
        }
        component={InputField}
        placeholder="Enter 5-digit Property Zip Code"
      />
      <Button
        type="submit"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        data-testid="bill-pay-make-payment-btn"
        fluid
      >
        Make a payment
      </Button>
    </Form>
  );
};
