import { type UiIconNames } from "@icg360/design-system";

export type PageName =
  | "Overview"
  | "Billing"
  | "Documents"
  | "Claims"
  | "Property"
  | "Support";

type NavElement = {
  to: string;
  title: PageName;
  iconName: UiIconNames;
};

export const navElements: NavElement[] = [
  {
    to: "/my/overview",
    title: "Overview",
    iconName: "Overview",
  },
  {
    to: "/my/billing",
    title: "Billing",
    iconName: "Money",
  },
  {
    to: "/my/documents",
    title: "Documents",
    iconName: "Document",
  },
  {
    to: "/my/claims",
    title: "Claims",
    iconName: "Claims",
  },
  {
    to: "/my/property",
    title: "Property",
    iconName: "Home",
  },
  {
    to: "/my/support",
    title: "Support",
    iconName: "Support",
  },
];
