import { Card } from "@icg360/design-system";

import { MSSLink } from "components/common/link";

import styles from "./paperless-card.module.scss";

export const PaperlessCardEnrolled = () => {
  return (
    <Card title="Paperless enrolled">
      <div className={styles.content}>
        <p>
          We&apos;ll donate $1 to the Arbor Day Foundation to plant trees in
          neighborhoods devastated by natural disasters.
        </p>
        <p>
          You can view details of your Paperless enrollment in{" "}
          <MSSLink to="/my/settings/paperless">Settings</MSSLink>.
        </p>
      </div>
    </Card>
  );
};
