import { useContext } from "react";

import { Card } from "@icg360/design-system";

import { DataDefinition, DataRow, DataTerm } from "components/common/data-row";
import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { PaperlessCard } from "components/shared/paperless-card";
import { isPaperlessDocumentEligible } from "utils";

import styles from "./paperless-documents-enroll-widget.module.scss";

export const PaperlessDocumentsEnrollWidget = () => {
  const { userDetails } = useContext(AuthAppContext);

  if (!userDetails) {
    return null;
  }

  const isEligible = isPaperlessDocumentEligible(userDetails);

  const {
    opInsuredEmailAddress,
    opInsuredEmailAddressDocuments,
    opInsuredEmailAddressBilling,
  } = userDetails.insuredPreferences ?? {};

  const userPolicyDocumentsEmail =
    opInsuredEmailAddressDocuments || opInsuredEmailAddress;

  const userBillingEmail =
    opInsuredEmailAddressBilling || opInsuredEmailAddress;

  const differentPaperlessEmails =
    userPolicyDocumentsEmail !== userBillingEmail;

  return isEligible ? (
    <PaperlessCard pageName="Documents" />
  ) : (
    <Card title="Documents information">
      <DataRow className={styles.data}>
        <DataTerm className={styles.modalTriggerWrapper}>
          {differentPaperlessEmails
            ? "Policy documents email"
            : "Documents email"}
          :
        </DataTerm>
        <DataDefinition>{userPolicyDocumentsEmail}</DataDefinition>
      </DataRow>
      {differentPaperlessEmails && userBillingEmail && (
        <DataRow className={styles.data}>
          <DataTerm>Billing email:</DataTerm>
          <DataDefinition>{userBillingEmail}</DataDefinition>
        </DataRow>
      )}
      <div className={styles.settingsLink}>
        Manage documents information in{" "}
        <MSSLink to="/my/settings/paperless">Settings</MSSLink>
      </div>
    </Card>
  );
};
