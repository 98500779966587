import { useNavigate } from "react-router-dom";

import { Button, Card } from "@icg360/design-system";

import * as styles from "./easypay-card.module.scss";

export const EasyPayCard = () => {
  const navigate = useNavigate();

  return (
    <Card leadingIcon="Payment" title="EasyPay">
      <div className={styles.content}>
        <p>
          Switch to automated EasyPay payments from your bank account and you
          can save both money and paper.
        </p>
        <p>
          You&apos;ll receive digital statements and send payments automatically
          each billing cycle.
        </p>
        <div>
          <Button
            appearance="secondary"
            size="sm"
            onPress={() => navigate("/my/billing/update")}
            data-testid="enrollInEasyPayButton"
          >
            Enroll in EasyPay
          </Button>
        </div>
      </div>
    </Card>
  );
};
