import { DATETIME_API_REGEX, DATETIME_FIXER_REGEX } from "consts";

export const fixApiDatetime = (badDatetime: string): string =>
  badDatetime.replace(DATETIME_FIXER_REGEX, "$1:$3:$5");

export const getApiDatetimeFromRoute = (route: string): Date => {
  const dateTimeMatch = route.match(DATETIME_API_REGEX);
  if (!dateTimeMatch) {
    return new Date();
  }
  const [apiDateTimeStr] = dateTimeMatch;
  return new Date(fixApiDatetime(apiDateTimeStr ?? ""));
};
