import { useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";

import { type PrivateLoaderData } from "components/root/router";
import { CONFIG } from "config";
import {
  type ClaimList,
  useClaimsPageDataQuery,
} from "gql/__generated__/hooks";
import { KeystoneApiClient, isError, useFlags } from "utils";

const POLL_INTERVAL = 2000;
const { getClaimsList } = new KeystoneApiClient(CONFIG.KEYSTONE_API_HREF);

// This hook can deliver claims data from graphql or keystone api, depending on the
// retireProxy and universalLogin feature flags
export function useClaimsData(policyId: string) {
  const { retireProxy, universalLogin } = useFlags();
  const loaderData = useRouteLoaderData("private") as PrivateLoaderData;
  const [restLoading, setRestLoading] = useState(false);
  const [restClaimsList, setRestClaimsList] = useState<ClaimList>();

  const { error, loading, data, startPolling, stopPolling } =
    useClaimsPageDataQuery({
      variables: {
        policyID: policyId,
      },
    });

  const carrierInfo =
    retireProxy && universalLogin ? loaderData?.carrier : data?.userCarrierInfo;

  const info = data?.userClaimsList?.ClaimsTrackerInfo;
  const pollGql =
    info?.length &&
    info?.every((claim) => claim.CarrierClaim?.Number !== "PENDING");

  useEffect(() => {
    // Retire proxy data handling
    if (retireProxy && universalLogin) {
      (async () => {
        setRestLoading(true);
        const list = await loaderData?.claimsList;
        setRestClaimsList(list);
        setRestLoading(false);
        if (checkPending(list)) {
          pollClaimsListWhilePending(policyId, setRestClaimsList);
        }
      })();
      return;
    }

    // GraphQL data handling
    if (pollGql) {
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [
    retireProxy,
    universalLogin,
    pollGql,
    startPolling,
    stopPolling,
    policyId,
    loaderData?.claimsConstants,
    loaderData?.claimsList,
  ]);

  // GraphQL data
  if (!retireProxy || !universalLogin) {
    return {
      error,
      loading,
      claimsList: data?.userClaimsList,
      carrierInfo,
    };
  }

  // Retired proxy data
  return {
    loading: restLoading,
    claimsList: restClaimsList,
    carrierInfo,
  };
}

// Returns true if ClaimsTrackerInfo has been fetched and at least one entry is "PENDING"
function checkPending(list?: ClaimList) {
  return (
    list?.ClaimsTrackerInfo?.length &&
    list?.ClaimsTrackerInfo?.some(
      (claim) => claim.CarrierClaim?.Number === "PENDING"
    )
  );
}

async function pollClaimsListWhilePending(
  policyId: string,
  setResult: (res: ClaimList | undefined) => void
) {
  const res = await getClaimsList(policyId);
  if (isError(res)) {
    console.error("Polling claims list", res.message);
    return;
  }
  setResult(res);
  if (checkPending(res)) {
    setTimeout(
      () => pollClaimsListWhilePending(policyId, setResult),
      POLL_INTERVAL
    );
  }
}
