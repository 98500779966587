export type AlarmType = "none" | "central" | "local";
type AlarmEnum = 1 | 2 | 4;

// Applies to both burglar and fire alarms:
export const ALARM_ENUM_DISPLAY_MAP: Record<AlarmEnum, string> = {
  1: "No alarm",
  2: "Local alarm",
  4: "Central alarm",
};

export const ALARM_ENUM_VALUE_MAP: Record<AlarmEnum, AlarmType> = {
  1: "none",
  2: "local",
  4: "central",
};

export const ALARM_VALUE_DISPLAY_MAP: Record<AlarmType, string> = {
  none: "No alarm",
  local: "Local alarm",
  central: "Central alarm",
};

export const ALARM_VALUE_ENUM_MAP: Record<AlarmType, AlarmEnum> = {
  none: 1,
  local: 2,
  central: 4,
};

// Added the 1 here as a key to conform it to the other maps (used in getPropertyDataValue).
export const PROPERTY_TRAMPOLINE = {
  No: false,
  Yes: true,
  1: "No",
  false: "No",
  true: "Yes",
};

export const PROPERTY_POOL_TYPE = {
  1: "None",
  100: "In-ground",
  200: "Above-ground",
};

export type PoolFormStep =
  | "poolType"
  | "poolFence"
  | "fenceDetails"
  | "aboveGroundPoolMinimumHeight"
  | "boardOrSlide"
  | "poolFilled"
  | "poolCovered"
  | "poolImmovableLadder"
  | "poolAttachedToDecking"
  | "poolDeckGateLocks";

export const POOL_FORM_STEPS: PoolFormStep[] = [
  "poolType",
  "poolFence",
  "fenceDetails",
  "aboveGroundPoolMinimumHeight",
  "boardOrSlide",
  "poolFilled",
  "poolCovered",
  "poolImmovableLadder",
  "poolAttachedToDecking",
  "poolDeckGateLocks",
];

export const POOL_CARDS: Record<PoolFormStep, string> = {
  poolType: "pool type",
  poolFence: "fence",
  fenceDetails: "fence details",
  aboveGroundPoolMinimumHeight: "pool height",
  boardOrSlide: "board or slide",
  poolFilled: "filled",
  poolCovered: "covered",
  poolImmovableLadder: "ladder",
  poolAttachedToDecking: "decking",
  poolDeckGateLocks: "pool decking locking",
};

export const POOL_DATA = [
  "poolType",
  "poolFence",
  "poolFenceAboveGround",
  "divingBoardOrSlide",
  "poolCovering",
  "unlockedPoolGate",
  "immovablePoolLadder",
];

export type PROPERTY_ROOF_TYPE =
  | "UNKNOWN"
  | "NONE"
  | "ARCHITECTURALSHINGLES"
  | "ASPHALTCOMPOSITE"
  | "BUILTUP"
  | "CONCRETECLAY"
  | "REINFORCEDCONCRETE"
  | "METALSTEEL"
  | "METALOTHER"
  | "SINGLEPLY"
  | "TLOCKINTERLOCKINGSHINGLES"
  | "WOODSHINGLE"
  | "WOODSHAKE"
  | "RUBBER";

export const PROPERTY_ROOF_CODE_MAP: Record<number, PROPERTY_ROOF_TYPE> = {
  0: "UNKNOWN",
  1: "NONE",
  502: "ARCHITECTURALSHINGLES",
  500: "ASPHALTCOMPOSITE",
  400: "BUILTUP",
  600: "CONCRETECLAY",
  100: "REINFORCEDCONCRETE",
  200: "METALSTEEL",
  250: "METALOTHER",
  300: "SINGLEPLY",
  550: "TLOCKINTERLOCKINGSHINGLES",
  700: "WOODSHINGLE",
  701: "WOODSHAKE",
  800: "RUBBER",
};

export const PROPERTY_ROOF_TYPE_MAP: Record<PROPERTY_ROOF_TYPE, number> = {
  ARCHITECTURALSHINGLES: 502,
  ASPHALTCOMPOSITE: 500,
  BUILTUP: 400,
  CONCRETECLAY: 600,
  REINFORCEDCONCRETE: 100,
  METALSTEEL: 200,
  METALOTHER: 250,
  SINGLEPLY: 300,
  TLOCKINTERLOCKINGSHINGLES: 550,
  WOODSHINGLE: 700,
  WOODSHAKE: 701,
  RUBBER: 800,
  UNKNOWN: 0,
  NONE: 1,
};

export const PROPERTY_ROOF_TYPES: PROPERTY_ROOF_TYPE[] = [
  "ARCHITECTURALSHINGLES",
  "ASPHALTCOMPOSITE",
  "BUILTUP",
  "CONCRETECLAY",
  "REINFORCEDCONCRETE",
  "METALSTEEL",
  "METALOTHER",
  "SINGLEPLY",
  "TLOCKINTERLOCKINGSHINGLES",
  "WOODSHINGLE",
  "WOODSHAKE",
  "RUBBER",
  "UNKNOWN",
];

export const PROPERTY_ROOF_UPDATE_TYPE: Record<
  PROPERTY_ROOF_TYPE,
  { label: string; imgSrc: string }
> = {
  UNKNOWN: { label: "I'm not sure", imgSrc: "" },
  NONE: { label: "None", imgSrc: "" },
  ARCHITECTURALSHINGLES: {
    label: "Architectural shingles",
    imgSrc: "/assets/images/roof-architectural-shingles.jpg",
  },
  ASPHALTCOMPOSITE: {
    label: "Asphalt or composite shingles",
    imgSrc: "/assets/images/roof-asphalt.jpg",
  },
  BUILTUP: { label: "Built-up", imgSrc: "/assets/images/roof-built-up.jpg" },
  CONCRETECLAY: {
    label: "Concrete or clay tiles",
    imgSrc: "/assets/images/roof-clay-tiles.jpg",
  },
  REINFORCEDCONCRETE: {
    label: "Reinforced concrete",
    imgSrc: "/assets/images/roof-concrete.jpg",
  },
  METALSTEEL: { label: "Steel", imgSrc: "/assets/images/roof-steel.jpg" },
  METALOTHER: {
    label: "Other metal",
    imgSrc: "/assets/images/roof-other-metal.jpg",
  },
  SINGLEPLY: {
    label: "Single-ply",
    imgSrc: "/assets/images/roof-singleply.jpg",
  },
  TLOCKINTERLOCKINGSHINGLES: {
    label: "T-lock or interlocking",
    imgSrc: "/assets/images/roof-tlock.jpg",
  },
  WOODSHINGLE: {
    label: "Wood shingles",
    imgSrc: "/assets/images/roof-wood-shingles.jpg",
  },
  WOODSHAKE: {
    label: "Wood shake",
    imgSrc: "/assets/images/roof-wood-shake.jpg",
  },
  RUBBER: { label: "Rubber", imgSrc: "/assets/images/roof-rubber.jpg" },
};

export const PROPERTY_ALARM_UPDATE_TYPE = {
  None: "NONE",
  "Local alarm": "LOCAL",
  "Central alarm": "CENTRAL",
};

export const PROPERTY_PROFILE = {
  burglarAlarm: { title: "Burglar alarm", valueMap: ALARM_ENUM_DISPLAY_MAP },
  fireAlarm: { title: "Fire alarm", valueMap: ALARM_ENUM_DISPLAY_MAP },
  trampoline: { title: "Trampoline", valueMap: PROPERTY_TRAMPOLINE },
};
