import { useEffect } from "react";
import { Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { Button, Heading, MarketingIcon } from "@icg360/design-system";

import { HeaderLogo } from "components/shared/logo-link";
import { PageFooter } from "components/shared/page-footer";
import { scrollToSmoothly, trackEvent, urlSearchParamsObject } from "utils";

import styles from "./shingle-repair-landing.module.scss";

export const ShingleRepairLanding = () => {
  const navigate = useNavigate();
  const { email } = urlSearchParamsObject(window.location.search);
  const isMobile = useMediaQuery({ maxHeight: "700px" });

  useEffect(() => {
    trackEvent("shinglesLanding_load", { email });
    setTimeout(() => {
      scrollToSmoothly(isMobile ? 140 : 200, 700);
    }, 200);
  }, [email, isMobile]);

  return (
    <>
      <header className={styles.header}>
        <HeaderLogo />
        <Button
          appearance="secondary"
          className={styles.loginBtn}
          onPress={() => {
            trackEvent("shinglesLanding_logInClicked");
            navigate("/login");
          }}
        >
          Log in
        </Button>
      </header>

      <Col lg={12} className={styles.splashContainer}>
        <video
          src="/assets/videos/RoofSealing-1.mp4"
          className={styles.backgroundVideo}
          autoPlay
          loop
          muted
        />
        <div className={styles.overlay} />

        <div className={styles.splashContent}>
          <Heading size="lg">
            Protect Your Roof Shingle by Shingle – for Free
          </Heading>
          <Heading size="sm" className={styles.splashText}>
            Get your roof ready for peak hurricane season with the Shingle
            Resealing Pilot Program. If your home is selected, a trained
            professional will reseal loose shingles, one of the most common
            causes of roof cover damage.
          </Heading>

          <Button
            className={styles.applyButton}
            onPress={() => {
              trackEvent("shinglesLanding_applyNowClicked", { banner: "top" });
              navigate(`/shingle-repair-pilot${window.location.search}`);
            }}
          >
            Apply now
          </Button>
        </div>
      </Col>

      <Col lg={12} className={styles.midBanner}>
        <span>
          The new Shingle Resealing Pilot Program is completely free—and may
          improve your home&apos;s readiness for severe weather.
        </span>
      </Col>

      <div className={styles.infoWrapper}>
        <Col lg={10} className={styles.infoSection}>
          <Col lg={6} className={styles.infoBullets}>
            <div className={styles.infoBullet}>
              <MarketingIcon size="lg" name="Clipboard" />

              <div className={styles.infoText}>
                <Heading size="md" className={styles.infoHeading}>
                  Apply now
                </Heading>
                It&apos;s easy—sign up online to join homeowners like yourself
                in an innovative Shingle Resealing Pilot Program.
              </div>
            </div>

            <div className={styles.infoBullet}>
              <MarketingIcon size="lg" name="HouseTalk" />

              <div className={styles.infoText}>
                <Heading size="md" className={styles.infoHeading}>
                  Schedule an assessment
                </Heading>
                If selected, our partner will schedule a visit to identify
                unsealed shingles. Homes in hurricane-affected areas are our
                primary candidates.
              </div>
            </div>

            <div className={styles.infoBullet}>
              <MarketingIcon size="lg" name="ShieldHouse" />

              <div className={styles.infoText}>
                <Heading size="md" className={styles.infoHeading}>
                  Get loose shingles resealed
                </Heading>
                A trained professional will identify and reseal loose shingles
                with an approved adhesive. That&apos;s it!
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className={styles.videoBox}>
              <video
                src="/assets/videos/RoofSealing-2.mp4"
                poster="/assets/images/roof-shingle-sealing.jpg"
                autoPlay
                loop
                muted
              />
            </div>
          </Col>
        </Col>
      </div>

      <Col lg={12} className={styles.applyBanner}>
        <div className={styles.applyContent}>
          <Heading size="md">
            Apply today—you may be eligible for the Shingle Resealing Pilot
            Program.
          </Heading>

          <Button
            className={styles.applyButton}
            onPress={() => {
              trackEvent("shinglesLanding_applyNowClicked", {
                banner: "bottom",
              });
              navigate(`/shingle-repair-pilot${window.location.search}`);
            }}
          >
            Apply now
          </Button>
        </div>
      </Col>

      <PageFooter className={styles.footer} />
    </>
  );
};
