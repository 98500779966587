import { useEffect, useState } from "react";

import {
  Button,
  Card,
  Heading,
  TelephoneMask,
  TextInput,
} from "@icg360/design-system";

import {
  formatPhoneNumber,
  isValidEmail,
  isValidPhone,
} from "pages/leakbot/leakbot-utils";

import styles from "./leakbot.module.scss";

const LeakbotConfirmContact = ({
  onNext,
  onBack,
  name,
  email,
  setEmail,
  phone,
  setPhone,
}) => {
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  useEffect(() => {
    setErrorEmail(false);
    setErrorPhone(false);
  }, []);

  const validate = () => {
    const validEmail = isValidEmail(email);
    const validPhone = isValidPhone(formatPhoneNumber(phone));

    setErrorEmail(!validEmail);
    setErrorPhone(!validPhone);

    if (validEmail && validPhone) {
      onNext();
    }
  };

  return (
    <div className={styles.lbCardWrapper}>
      <Card>
        <div className={styles.lbCardContentWrapper}>
          <Heading size="lg">How can LeakBot reach you?</Heading>
          <span>
            LeakBot will send your device to you. You&apos;ll receive a
            confirmation email and updates on shipping. Your phone number will
            only be used for alerts about your LeakBot device.
          </span>
          <TextInput value={name} label="Name" isDisabled />
          <TextInput
            type="text"
            label="Email"
            value={email}
            data-testid="LeakbotEmailField"
            onChange={(e) => {
              setErrorEmail(false);
              setEmail(e.target.value);
            }}
            errorMessage="Enter your email address: yourname@domain.com"
            isError={errorEmail}
          />
          <TelephoneMask
            data-testid="LeakbotPhoneField"
            label="Phone number"
            value={phone}
            onValueChange={(values) => {
              setErrorPhone(false);
              setPhone(values.value);
            }}
            errorMessage="Enter a valid 10 digit phone number."
            isError={errorPhone}
          />
          <div className={styles.lbNav}>
            <Button appearance="tertiary" onPress={onBack}>
              Go back
            </Button>
            <Button onPress={validate} data-testid="LeakbotContactBtn">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LeakbotConfirmContact;
