import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { usePropertyUpdateRoof } from "./hooks";
import styles from "./property-update-roof.module.scss";

export type PropertyUpdateRoofContextState = {
  roofYear?: number;
  roofMaterial?: string;
  files?: FileList;
} | null;

export const PropertyUpdateRoofContext = createContext<{
  state: PropertyUpdateRoofContextState;
  setState: Dispatch<SetStateAction<PropertyUpdateRoofContextState>>;
}>({ state: null, setState: () => null });

export const PropertyUpdateRoof = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { loading } = usePropertyUpdateRoof();

  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );

  useEffect(() => {
    if (!state?.roofYear || !state?.roofMaterial) {
      formNavigate("./");
    }
  }, [formNavigate, state]);

  if (loading || !selectedPolicyId) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet />;
};

export const PropertyUpdateRoofFlow = () => {
  const [state, setState] = useState<PropertyUpdateRoofContextState>(null);
  return (
    <PropertyUpdateRoofContext.Provider value={{ state, setState }}>
      <PropertyUpdateRoof />
    </PropertyUpdateRoofContext.Provider>
  );
};
