import { useContext, useState } from "react";

import { Card } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";

import PaymentItem from "./payment-item";

export const Payment = () => {
  const [showEasyPayBanner, setEasyPayBanner] = useState(false);
  const { policies } = useContext(AuthAppContext);

  const banner = showEasyPayBanner ? (
    <Card title="Save more with EasyPay" leadingIcon="Payment" border>
      Sign up for EasyPay and eliminate your installment fee by having payments
      automatically drafted from your bank account.
    </Card>
  ) : null;

  const policyItems = policies?.map((policy) => (
    <PaymentItem
      key={policy?.policyId}
      policy={policy}
      setEasyPayBanner={setEasyPayBanner}
    />
  ));

  return (
    <SettingsPanelLayout
      title="Payment"
      banner={banner}
      policyItems={policyItems}
    />
  );
};
