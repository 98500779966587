import * as styles from "./shingle-repair-pilot.module.scss";

const WaiverContent = () => {
  return (
    <div className={styles.waiverContainer}>
      <div className={styles.waiverContentWrapper}>
        <p>
          The Participant has volunteered to participate in one of
          LiftLock&apos;s Wind Defense Research Programs (herein referred to as
          the &quot;Program&quot;). SageSure Insurance Managers LLC
          (&quot;SageSure&quot;), the managing general agency of the insurance
          company that wrote your insurance policy (&quot;Your Insurance
          Company&quot;) has entered into an agreement with LiftLock, LLC (the
          &quot;Company&quot;). The Company has contracted with numerous vendors
          (&quot;Technicians&quot;) to seal/reseal roof shingles on residences
          situated in certain hurricane-prone areas (the &quot;Services&quot;).
        </p>
        <p>
          Participant understands in order to participate in the Program, the
          Participant agrees as follows:
        </p>
        <p>
          <b>Assumption of Risk.</b> The Participant hereby understands and
          acknowledges the Services provided by the Technicians, who are
          third-party service providers, and neither SageSure nor Your Insurance
          Company is making any representations or warranties to the Participant
          regarding the Technicians, their capabilities, background, or
          experience. Participant consents to participate voluntarily in the
          Program. Participant, knowingly and voluntarily, accepts, and assumes
          responsibility for any and all danger, damage, and risks, whether
          known or unknown, that could arise out of, or occur during,
          Participant&apos;s participation in the Program even if such risks are
          due to the negligence of SageSure or the Company and/or their
          respective agents, contractors, volunteers or employees.
        </p>
        <p>
          <b>Authorization.</b> Participant authorizes SageSure and the Company,
          and their respective agents, contractors, volunteers, or employees to
          perform the services necessary for the Program, as well as authorizes
          and consents to the capturing and retention of any pictures and videos
          necessary for the Program and for marketing purposes. Participant
          authorizes Company to share information related to Services with
          SageSure for purposes of servicing, underwriting, or other actions
          related to your insurance policy. Participant acknowledges that it may
          receive benefits through the voluntary Program, including but not
          limited to roof clearing and sealant applied to shingles. Participant
          agrees that there is no guarantee of receiving any such benefits
          through the voluntary Program. Participant further acknowledges and
          agrees that any services provided by SageSure and/or the Company
          through the Program are provided on an &quot;AS IS&quot; basis.
        </p>
        <p>
          THE PROGRAM AND ANY RELATED SERVICES PROVIDED ARE PROVIDED ON AN
          &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT WARRANTY
          OF ANY KIND, WHETHER WRITTEN OR ORAL, EXPRESS OR IMPLIED. SAGESURE AND
          COMPANY EACH DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING BUT NOT
          LIMITED TO ALL WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY
          AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER SAGESURE NOR COMPANY
          REPRESENT OR WARRANT THAT THE SERVICES PROVIDED THROUGH THE PROGRAM
          WILL MEET PARTICIPANTS&apos; NEEDS OR REQUIREMENTS IN ANY WAY, THAT
          THE SERVICES WILL BE ACCURATE OR RELIABLE, THAT THE SERVICES WILL BE
          SECURE FROM ERROR, OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED.
        </p>
        <p>
          <b>Release and Waiver.</b> The Participant hereby releases, waives,
          discharges, and covenants not to sue SageSure, the Company, and Your
          Insurance Company, and each of their respective affiliates, directors,
          agents, and other employees (collectively, the &quot;Releases&quot;),
          from and for any liability resulting the Program including, without
          limitation any personal injury, accident or illness (including death),
          and/or property loss or damage, however caused, even if caused by the
          negligence of SageSure, or the Company, and/or their respective
          agents, contractors, arising from, or in any way related to,
          Participant&apos;s participation in the Program. Participant
          understands that their participation in the Program is strictly
          voluntary and a personal decision.
        </p>
        <p>
          <b>Confidentiality.</b> The Participant shall not, directly or
          indirectly, disclose any confidential information regarding the
          Program to third parties without SageSure&apos;s prior written
          consent, which consent may be withheld at SageSure&apos;s discretion.
        </p>
        <p>
          <b>Severability.</b> The Participant expressly agrees that the release
          and waiver of liability, is intended to be as broad and inclusive as
          is permitted by the law of the State of Florida and that if any
          portion thereof is held invalid, it is agreed that the balance shall,
          notwithstanding, continue in full legal force and effect.
        </p>
        <p>
          <b>Acknowledgment of Understanding.</b> The Participant has read this
          waiver and has had the opportunity to ask questions about the same.
          The Participant fully understands this assumption of risk, release,
          and waiver of liability, that the Participant is giving up substantial
          rights in connection therewith, and that its terms are contractual.
          The Participant acknowledges that they are agreeing to this waiver
          freely and voluntarily and that no oral representations, statements,
          or inducements, apart from the foregoing written waiver, have been
          made.
        </p>
        <p>
          <b>Governing Law.</b> This Program shall be governed by the laws of
          the State of Florida without regard for its conflicts of laws
          principles. The Participant agrees to submit to the jurisdiction of
          the federal and state courts located in the state in which the subject
          residence is located.
        </p>
        <p>
          <b>Miscellaneous.</b> This waiver and release with respect to the
          Program supersedes any prior contemporaneous representations, written
          or oral, with regard to the Program and Services.
        </p>
      </div>
    </div>
  );
};

export default WaiverContent;
