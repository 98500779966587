import { useContext, useEffect, useState } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import NavMenu from "components/shared/nav-menu";
import { navElements } from "consts";

import { MultiPolicySelector } from "./multi-policy-selector";
import styles from "./navigation.module.scss";

const Navigation = (): JSX.Element => {
  const { policy } = useContext(AuthAppContext);

  const [navItems, setNavItems] = useState(navElements);

  useEffect(() => {
    setNavItems(
      navElements.filter(
        ({ to }) => !policy?.isCommercial || to !== "/my/property"
      )
    );
  }, [policy]);

  return (
    <div className={styles.navigation} data-testid="secondary-header">
      <div className={styles.policyAddressContainer}>
        <MultiPolicySelector />
      </div>
      <NavMenu navItems={navItems} />
    </div>
  );
};

export default Navigation;
