import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";
import OffersItem from "pages/settings/offers/offers-item";

const Offers = () => {
  const { policies } = useContext(AuthAppContext);

  const policyItems =
    policies?.map((policy) => (
      <OffersItem key={policy.policyId} policy={policy} />
    )) ?? [];

  return (
    <SettingsPanelLayout title="Home protection" policyItems={policyItems} />
  );
};

export default Offers;
