import { useEffect } from "react";

import { Button, Card, Heading, Icon, Span } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./featured-offers-ting.module.scss";

export const FeaturedOfferTing = () => {
  useEffect(() => {
    trackEvent("Featured Offer Card Shown", {
      offerInternalName: "ting-offer",
    });
  });
  return (
    <Card className={styles.cardDS}>
      <div className={styles.title}>
        <Span className={styles.iconDS}>
          <Icon name="New" size="xl" />
        </Span>
        <Heading size="md">Monitor your home for free!</Heading>
      </div>
      <div className={styles.content}>
        <Heading size="md" className={styles.heroTextDS}>
          Ting can help protect your home from devastating electrical fires
        </Heading>
        <Button>Sign up for free</Button>
      </div>
      <div className={styles.footer}>
        <Button appearance="tertiary" size="xs" trailingIcon="ArrowRight">
          View all my perks
        </Button>
      </div>
    </Card>
  );
};
