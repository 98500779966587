import { useEffect, useState } from "react";
import Dropzone, { ErrorCode, type FileRejection } from "react-dropzone-latest";
import { useMediaQuery } from "react-responsive";

import {
  Alert,
  Button,
  Heading,
  List,
  Span,
  UiIcon,
} from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { trackEvent } from "utils";

import {
  PropertyUpdateRoofContext,
  type PropertyUpdateRoofContextState,
} from ".";
import { FileThumbnails } from "../file-thumbnails";
import { PropertyUpdateLayout } from "../property-update-layout";
import styles from "./roof-proof-upload.module.scss";

const MAX_BYTE_FILE_SIZE = 6000000;
const MAX_FILE_COUNT = 20;
const REJECTION_MAP: { [key in ErrorCode]?: string } = {
  [ErrorCode.FileInvalidType]: "File type needs be .pdf, .jpg, or .png.",
  [ErrorCode.FileTooLarge]: "File is too large.",
};

export const RoofProofUpload = () => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [rejectionErrorList, setRejectionErrorList] = useState<string[]>([]);
  const [fileCountLimit, setFileCountLimit] = useState<number>(MAX_FILE_COUNT);
  const [showTooManyError, setShowTooManyError] = useState(false);
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );
  const isMobile = useMediaQuery({ maxWidth: screenSizes.laptop });
  const preparedFiles = state?.files ?? [];

  useEffect(() => {
    setFileCountLimit(MAX_FILE_COUNT - (state?.files ?? []).length);
  }, [state?.files]);

  useEffect(() => {
    // Arrays for event tracking:
    const errorCodes: string[] = [],
      fileSizes: number[] = [],
      fileTypes: string[] = [];

    const fileErrorDisplayList = rejectedFiles.reduce(
      (errorList: string[], fileRejection: FileRejection): string[] => {
        const { errors, file } = fileRejection;
        fileTypes.push(file.type);
        fileSizes.push(file.size);
        // Prioritize the invalid type error over other errors:
        const errorCode = errors.reduce((curCode, { code }) => {
          errorCodes.push(code);
          if (code === ErrorCode.TooManyFiles) {
            setShowTooManyError(true);
          }
          if ([curCode, code].includes(ErrorCode.FileInvalidType)) {
            curCode = ErrorCode.FileInvalidType;
          } else if (code === ErrorCode.FileTooLarge) {
            curCode = code;
          } else if (code === ErrorCode.TooManyFiles) {
            curCode = code;
          }
          return curCode;
        }, "");

        if (errorCode !== ErrorCode.TooManyFiles) {
          errorList.push(
            `"${fileRejection.file.name}" (${REJECTION_MAP[errorCode]})`
          );
        }

        return errorList;
      },
      []
    );
    setRejectionErrorList(fileErrorDisplayList);

    if (rejectedFiles?.length) {
      trackEvent("input_error", {
        id: "roof-upload-files-rejected",
        numberOfRejectedFiles: rejectedFiles.length,
        errorMessages: fileErrorDisplayList,
        errorCodes,
        fileSizes,
        fileTypes,
      });
    }
  }, [rejectedFiles]);

  const fileCountValidator = () => {
    if (fileCountLimit <= 0) {
      return {
        code: ErrorCode.TooManyFiles,
        message: "", // we don't use this
      };
    }
    return null;
  };

  const removeFile = (fileToDelete: File & { preview?: string }) => {
    if (fileToDelete?.preview) {
      URL.revokeObjectURL(fileToDelete.preview);
    }
    const newList = preparedFiles.filter((file) => file !== fileToDelete);
    setFields({ files: newList });
  };

  return (
    <PropertyUpdateLayout
      step={3}
      numSteps={4}
      heading="Update roof details"
      buttonProps={{
        primaryDisabled: !preparedFiles.length,
        primaryPath: "../review",
      }}
    >
      <div className={styles.roofProof}>
        <Heading size="md">Help us review the details</Heading>
        <div>
          Please upload:
          <List type="ol">
            <li>
              The <Span bold>contract</Span> from your roofer, making sure it
              includes their <Span bold>license number</Span> and{" "}
              <Span bold>a detailed list of the work</Span> they did
            </li>
            <li>
              <Span bold>Photos of your roof</Span> from all sides of your home
            </li>
          </List>
        </div>
        <div className={styles.dropzoneWrapper}>
          <Dropzone
            noClick
            maxSize={MAX_BYTE_FILE_SIZE}
            maxFiles={fileCountLimit}
            validator={fileCountValidator}
            accept={{
              "image/jpg": [".jpg", ".jpeg"],
              "image/png": [".png"],
              "application/pdf": [".pdf"],
            }}
            onDropRejected={setRejectedFiles}
            onDrop={(acceptedFiles) => {
              setShowTooManyError(false);
              const currentFiles = state?.files;
              setFields({
                files: [
                  ...(currentFiles ?? []),
                  ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  ),
                ] as unknown as FileList,
              });

              if (rejectionErrorList.length > 0) {
                setRejectedFiles([]);
              }
            }}
          >
            {({ getRootProps, getInputProps, isDragActive, open }) => (
              <div
                role="presentation"
                className={
                  isDragActive ? styles.dropAreaDragging : styles.dropArea
                }
                {...getRootProps()}
                onClick={open}
              >
                <UiIcon name="Document" size="md" />
                {isMobile ? (
                  <Button
                    size="sm"
                    appearance="secondary"
                    onPress={open}
                    className={styles.uploadButton}
                  >
                    Upload document
                  </Button>
                ) : (
                  <Span bold className={styles.dropAreaHeading}>
                    Drag your files here
                  </Span>
                )}
                <Span color="quiet">Up to 6MB each</Span>
                <Span color="quiet">We can handle: JPG, PNG, PDF</Span>

                {!isMobile && (
                  <div className={styles.inputLabel}>
                    <Span bold color="interactive">
                      Choose files
                    </Span>
                  </div>
                )}
                <input
                  {...getInputProps({
                    type: "file",
                    name: "files",
                    multiple: true,
                  })}
                />
              </div>
            )}
          </Dropzone>
          {showTooManyError ? (
            <Alert
              title="You've reached the limit."
              description={`You can upload up to ${MAX_FILE_COUNT} files.`}
              appearance="danger"
              className={styles.fileErrorAlertDS}
            />
          ) : null}
          {rejectionErrorList.length > 0 && (
            <Alert
              className={styles.fileErrorAlertDS}
              title={`Something went wrong while uploading the following file${
                rejectionErrorList.length > 1 ? "s" : ""
              }:`}
              appearance="danger"
              description={
                <List type="ul">
                  {rejectionErrorList?.map((errorDisplay) => {
                    return <li key={errorDisplay}>{errorDisplay}</li>;
                  })}
                </List>
              }
            />
          )}
        </div>
        <FileThumbnails files={state?.files} removeFile={removeFile} />
      </div>
    </PropertyUpdateLayout>
  );
};
