import { Button, Card, Link as DSLink, Span } from "@icg360/design-system";

import { MSSLink } from "components/common/link";

import styles from "./faq-sidebar.module.scss";
import { type FaqPage, useFaqSidebar } from "./hooks";

type FaqSidebarProps = {
  page: FaqPage;
};

export const FaqSidebar = ({ page }: FaqSidebarProps) => {
  const { faqs, selectedFaq, setSelectedFaq } = useFaqSidebar(page);

  return (
    <Card title="FAQs">
      {selectedFaq ? (
        <div className={styles.answer}>
          <Span bold>{selectedFaq.question}</Span>
          <selectedFaq.answer />
          <div>
            <Button
              appearance="tertiary"
              onPress={() => setSelectedFaq(null)}
              leadingIcon="ArrowLeft"
              size="xs"
            >
              Back
            </Button>
          </div>
        </div>
      ) : (
        <div id="faqs" className={styles.mainList}>
          {faqs.slice(0, 3).map((faq, idx) => (
            <div id={faq.id} key={idx} className={styles.listItem}>
              <DSLink onPress={() => setSelectedFaq(faq)}>
                {faq.question}
              </DSLink>
            </div>
          ))}
          <div>
            <MSSLink to="/my/support" className={styles.toFaqLink}>
              {"View all FAQs >"}
            </MSSLink>
          </div>
        </div>
      )}
    </Card>
  );
};
