import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { type ShingleResealContextState } from "pages/shingle-reseal/shingle-reseal-flow";
import { authHeaders, handleUnauthorized, logError, useFlags } from "utils";

export const useEligibility = () => {
  const { universalLogin } = useFlags();
  const navigate = useNavigate();
  const { policy } = useContext(AuthAppContext);
  const [eligibilityData, setEligibilityData] =
    useState<KeystoneOfferEligibility>();
  const [loading, setLoading] = useState(!policy?.insightPolicyId);

  const { offeringId } = useParams();

  useEffect(() => {
    if (!policy?.insightPolicyId) {
      return;
    }

    setLoading(true);
    (async () => {
      const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offer/${policy.insightPolicyId}/offering/${offeringId}`;
      const headers = universalLogin ? await authHeaders() : {};
      const res = await fetch(endpoint, {
        method: "POST",
        credentials: universalLogin ? "same-origin" : "include",
        headers,
      });
      if (res.ok) {
        const data = await res.json();
        if (data.offerStatusCode === "INELIGIBLE") {
          // EXPIRED?
          navigate("/my/offers/ineligible");
          return;
        } else if (data.offerStatusCode === "ENROLLED") {
          navigate("/my/shingle-opportunity/registered");
          return;
        } else {
          setEligibilityData(data);
        }
      } else {
        if ([401, 403].includes(res.status)) {
          await handleUnauthorized({ endpoint });
        } else {
          logError(`Creating Offer: ${res.status} ${res.statusText}`);
          navigate("/my/overview");
        }
      }
      setLoading(false);
    })();
  }, [universalLogin, policy.insightPolicyId, offeringId, navigate]);

  return { eligibilityData, loading };
};

export const useShingleResealEnrollment = (
  state: ShingleResealContextState
) => {
  const { universalLogin } = useFlags();
  const { policy } = useContext(AuthAppContext);
  const { offeringId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { firstName, lastName, emailAddress, phone, offerId } = state ?? {};

  const enrollShingleReseal = useCallback(async () => {
    setLoading(true);
    setError(false);
    const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/shinglereseal/enroll`;
    const res = await fetch(endpoint, {
      method: "POST",
      credentials: universalLogin ? "same-origin" : "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        ...(universalLogin ? await authHeaders() : {}),
      },
      body: JSON.stringify({
        insightPolicyId: policy.insightPolicyId,
        offeringId,
        offerId,
        additionalDetails: {
          firstName,
          lastName,
          emailAddress,
          phone,
        },
      }),
    });
    if (!res.ok) {
      if ([401, 403].includes(res.status)) {
        await handleUnauthorized({ endpoint });
      } else {
        setError(true);
      }
    }
    setLoading(false);

    return res.ok;
  }, [
    emailAddress,
    firstName,
    lastName,
    offerId,
    offeringId,
    phone,
    policy.insightPolicyId,
    universalLogin,
  ]);

  return { enrollShingleReseal, loading, error };
};
