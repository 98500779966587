import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Link as DSLink,
  Heading,
  Span,
} from "@icg360/design-system";

import ClaimIcon from "images/icon-claim.svg";
import { trackEvent, useFlags } from "utils";

import styles from "./submit-claim.module.scss";

type SubmitClaimProps = {
  hasClaims: boolean;
  carrierName?: string;
};

const MaintenanceSVG = () => {
  // copied from figma
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8220_5739)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 0 16 0C7.16345 0 0 7.16345 0 16C0 24.8366 7.16345 32 16 32Z"
          fill="#80BC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2017 31.6795C18.1673 31.8896 17.0965 32 16 32C14.9197 32 13.8644 31.893 12.8442 31.6889V22.6766C11.4196 22.1467 10.1571 21.2515 9.18481 20.0785C8.14689 18.8263 7.48141 17.3078 7.26413 15.696C7.04687 14.0841 7.28653 12.4437 7.95585 10.9613C8.62516 9.47897 9.69722 8.21435 11.05 7.31141C11.2743 7.19102 11.5223 7.12137 11.7764 7.10741C12.0305 7.09344 12.2847 7.1355 12.5208 7.23061V13.613C12.5208 14.8754 13.5441 15.8987 14.8065 15.8987H17.1526C18.415 15.8987 19.4383 14.8754 19.4383 13.613L19.4383 7.03664C19.6752 6.97991 19.9213 6.9736 20.1608 7.01815C20.4003 7.0627 20.6277 7.1571 20.8283 7.29525C22.1622 8.19838 23.2195 9.45381 23.8825 10.9219C24.5456 12.39 24.7884 14.0132 24.584 15.6111C24.3797 17.209 23.7363 18.7189 22.725 19.9729C21.8106 21.1069 20.6279 21.9915 19.287 22.5486L19.2017 31.6795Z"
          fill="#004438"
        />
      </g>
      <defs>
        <clipPath id="clip0_8220_5739">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SubmitClaim = ({ hasClaims, carrierName }: SubmitClaimProps) => {
  const navigate = useNavigate();
  const { claimsMaintenanceEnabled } = useFlags();

  const handleSubmitClaimNavigation = () => {
    trackEvent("Claim: FNOL Started");
    navigate("/my/claims/submit");
  };

  if (claimsMaintenanceEnabled) {
    return (
      <Card>
        <div className={styles.maintenanceContainer}>
          <MaintenanceSVG />
          <div className={styles.maintenanceMessageContainer}>
            <Heading size="sm">We&apos;re performing some maintenance</Heading>
            <div>
              <Span>To submit a claim please call&nbsp;</Span>
              <DSLink href="tel:8663117243">(866) 311-7243</DSLink>
              <Span>&nbsp;or check back here later.</Span>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  // Display Claims exist submit claim card
  if (hasClaims) {
    return (
      <Card>
        <div className={styles.claimSubmitBanner}>
          <div className={styles.description}>
            Have you experienced a loss? Report it here
          </div>
          <Button
            appearance="primary"
            size="default"
            onPress={handleSubmitClaimNavigation}
            data-testid="claim-submit-btn"
          >
            Submit a claim
          </Button>
        </div>
      </Card>
    );
  }

  const isIndependentMutualInsuranceCompany =
    carrierName === "Independent Mutual Fire Insurance Company";

  // Display Claims doesn't exist submit claim card
  return (
    <Card className={styles.noClaimSubmitBannerDS}>
      <ClaimIcon className={styles.claimIcon} />
      <div className={styles.content}>
        <Heading size="sm">No claims reported</Heading>
        <Span>
          Have you experienced a loss? Tell us about it here and we&apos;ll
          reach out with next steps
        </Span>
      </div>
      <Button
        appearance="primary"
        size="default"
        onPress={handleSubmitClaimNavigation}
        data-testid="claim-submit-btn"
      >
        Submit a claim
      </Button>
      {isIndependentMutualInsuranceCompany ? (
        <Span color="quiet" size="sm" className={styles.specialCopy}>
          Please note: at this time, claims for Independent Mutual Fire
          Insurance Company are only available by contacting the carrier
          directly.
        </Span>
      ) : null}
    </Card>
  );
};

export default SubmitClaim;
