import { useContext } from "react";

import { Link as DSLink, Heading } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useThumbtackModalContext } from "components/thumbtack-modal";
import { formatDate, trackEvent } from "utils";

import styles from "./underwriting-condition-item.module.scss";

export const UnderwritingConditionItem = ({
  underwritingCondition,
  zipCode,
}) => {
  const { openThumbtackModal } = useThumbtackModalContext();
  const { selectedPolicy } = useContext(AuthAppContext);
  const { dueDate } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};

  return (
    <Stack gap="sm">
      <Heading size="sm" className={styles.writeOutHeader}>
        {underwritingCondition.referralText}
      </Heading>
      <div>{underwritingCondition.actionStatement}</div>
      <div>
        {underwritingCondition.proof}
        {dueDate ? ` by ${formatDate(dueDate, "MM/DD/YYYY")}` : ""}.
      </div>
      <div>
        Need help?{" "}
        <DSLink
          onPress={() => {
            openThumbtackModal(
              zipCode,
              underwritingCondition?.category,
              underwritingCondition?.searchTerm
            );
            trackEvent("writeout_findContractorClicked");
          }}
        >
          Find a contractor
        </DSLink>
      </div>
    </Stack>
  );
};
