import { useEffect } from "react";

import { Modal, Span } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./easypay-enrollment-modal.module.scss";

type EasyPayEnrollmentModalProps = {
  show: boolean;
  onEnroll: () => void;
  onClose: () => void;
};

const EasyPayEnrollmentModal = ({
  show,
  onEnroll,
  onClose,
}: EasyPayEnrollmentModalProps) => {
  useEffect(() => {
    if (show) trackEvent("EasyPay Modal Displayed");
  }, [show]);
  const handleEnrollClick = () => {
    trackEvent("EasyPay Modal Enroll Button Clicked");
    onEnroll();
  };

  return (
    <Modal
      open={show}
      heading="Save more with EasyPay"
      primaryAction="Enroll in EasyPay"
      onPrimaryAction={handleEnrollClick}
      tertiaryAction="Remind me later"
      onTertiaryAction={onClose}
      onClose={onClose}
    >
      <Span bold>
        Save on fees and keep your policy active without late payments.
      </Span>
      <div className={styles.mainText}>
        Switch to automated EasyPay payments from your bank account and you can
        save both money and paper: you’ll receive digital statements and send
        payments automatically each billing cycle.
      </div>
    </Modal>
  );
};

export default EasyPayEnrollmentModal;
