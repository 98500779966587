import { type ReactNode, useEffect, useState } from "react";

import { Button, Icon, Modal, type ModalProps } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./ie-warning-modal.module.scss";

type IEModalProps = {
  title: ModalProps["heading"];
  line1: ReactNode;
  line2: ReactNode;
};

export const IEModal = ({ title, line1, line2 }: IEModalProps) => {
  const ua = window.navigator.userAgent.toLowerCase();
  const isIE = ua.includes("msie") || ua.includes("trident");
  const [show, setShow] = useState(() => isIE);

  const onBeforeUnload = () => {
    if (isIE) {
      trackEvent("Displayed I.E. depreciation message");
      setShow(true);
      window.onbeforeunload = null;
    }
  };

  useEffect(() => {
    if (show) {
      window.onbeforeunload = onBeforeUnload;
    } else {
      window.onbeforeunload = null;
    }
  }, [show]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      size="lg"
      heading={title}
      noFooter
    >
      <div className={styles.content}>
        <div>{line1}</div>
        <div>{line2}</div>

        <div className={styles.browserButtons}>
          <a
            className={styles.browserButton}
            href="https://google.com/chrome/download"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/images/icon-chrome.png"
              alt="Google Chrome"
              width="40"
              height="40"
            />
            Google Chrome
            <Icon name="OpenNewTab" size="sm" />
          </a>

          <a
            className={styles.browserButton}
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/images/icon-edge.png"
              alt="Google Chrome"
              width="40"
              height="40"
            />
            Microsoft Edge
            <Icon name="OpenNewTab" size="sm" />
          </a>

          <a
            className={styles.browserButton}
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/images/icon-firefox.png"
              alt="Google Chrome"
              width="40"
              height="40"
            />
            Firefox
            <Icon name="OpenNewTab" size="sm" />
          </a>
        </div>

        <Button
          appearance="link"
          onPress={() => setShow(false)}
          data-testid="closeTextButton"
        >
          Continue using Internet Explorer
        </Button>
      </div>
    </Modal>
  );
};
