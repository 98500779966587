import { Field, Form, useFormikContext } from "formik";

import { Link as DSLink, Modal, Span } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";
import { trackEvent } from "utils";

import styles from "./paperless-modal.module.scss";

export const PaperlessModalForm = ({ show, onClose }) => {
  const { isValid, isSubmitting, resetForm, submitForm } = useFormikContext();

  const onModalClose = () => {
    trackEvent("Paperless Documents Options Modal Closed");
    onClose();
    resetForm();
  };

  return (
    <Modal
      open={show}
      onClose={onModalClose}
      heading="Go paperless and help us make a difference"
      primaryAction="Enroll"
      tertiaryAction="Remind me later"
      onPrimaryAction={submitForm}
      disablePrimary={!isValid}
      loadingPrimary={isSubmitting}
      disableTertiary={isSubmitting}
    >
      <Form className={styles.paperlessForm}>
        <div>
          <Span bold>
            Your enrollment makes a big difference to our environment!
          </Span>{" "}
          SageSure partners with the Arbor Day Foundation’s Community Tree
          Recovery Program, helping to restore the vital tree canopies in
          communities impacted by natural disasters.
        </div>
        <Field
          name="documentsEmail"
          title="Email address"
          component={InputField}
          placeholder="Enter your Email Address"
        />
        <Span color="quiet" size="sm">
          By clicking the enroll button below, I confirm that I have read, agree
          to and accept the{" "}
          <DSLink
            href="https://www.sagesure.com/PaperlessConsent"
            target="_blank"
            rel="noopener"
          >
            Disclosure and Consent
          </DSLink>
        </Span>
      </Form>
    </Modal>
  );
};
