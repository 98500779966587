import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

import { Card, Heading, Spinner } from "@icg360/design-system";

import styles from "./loader.module.scss";

export const AddressLoader = (props) => (
  <ContentLoader
    height={46}
    width={264}
    viewBox="0 0 264 46"
    speed={3}
    {...props}
  >
    <rect x="20" y="20" width="200" height="6" />
    <rect x="20" y="35" width="150" height="6" />
  </ContentLoader>
);

export const RegularContentLoader = (props) => (
  <Card>
    <ContentLoader width="100%" viewBox="0 0 710 90" speed={3} {...props}>
      <rect x="0" y="10" width="627" height="7" />
      <rect x="0" y="28" width="510" height="7" />
      <rect x="0" y="46" width="627" height="7" />
      <rect x="0" y="64" width="510" height="7" />
      <rect x="0" y="82" width="627" height="7" />
    </ContentLoader>
  </Card>
);

type SpinnerLoaderProps = {
  firstMessage?: string;
};

export const SpinnerLoader = ({ firstMessage }: SpinnerLoaderProps) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  useEffect(() => {
    const spinnerTimeout = setTimeout(() => setShowSpinner(true), 1000);
    const firstMessageTimeout = setTimeout(
      () =>
        setLoadingMessage(
          firstMessage ?? "Hang tight, we're getting your policy..."
        ),
      4000
    );
    const secondMessageTimeout = setTimeout(
      () =>
        setLoadingMessage(
          "Thanks for your patience. This may take a few more seconds..."
        ),
      10000
    );
    return () => {
      clearTimeout(spinnerTimeout);
      clearTimeout(firstMessageTimeout);
      clearTimeout(secondMessageTimeout);
    };
  }, []);

  return (
    <div className={styles.spinnerWrapper} title="Loading...">
      {showSpinner ? (
        <div>
          <Spinner />
        </div>
      ) : null}
      <Heading size="sm">{loadingMessage}</Heading>
    </div>
  );
};
