import { type Dispatch, type SetStateAction, useEffect } from "react";

import { FetchingError } from "components/common/fetching-error";
import {
  type PaperlessSettingsDataQuery,
  usePolicyPaperlessDataQuery,
} from "gql/__generated__/hooks";
import EditBillingEmail from "pages/settings/common/edit-billing-email";
import SettingsPolicy, {
  type SettingsPolicyDetailItem,
} from "pages/settings/common/settings-policy";
import { getPaperlessEnrollment, logError } from "utils";

import EditDocumentsEmail from "./edit-documents-email";
import EnrollPaperless from "./enroll-paperless";
import styles from "./paperless-item.module.scss";

const getDetailItems = (
  policyId,
  userBilling,
  userDetails,
  userBillingAddress
): SettingsPolicyDetailItem[] => {
  const detailItems = [{ label: "Policy number:", value: policyId }];

  if (!userDetails || !userBilling) {
    return detailItems;
  }

  const { insuredPreferences } = userDetails ?? {};

  const { isIneligible, isEnrolledBilling, isEnrolledDocuments } =
    getPaperlessEnrollment(userBilling, userDetails);

  const getEnrollmentStatus = () => {
    if (isIneligible) {
      return "Not eligible for paperless";
    } else if (isEnrolledDocuments) {
      return "Enrolled";
    } else if (isEnrolledBilling) {
      return "Enrolled (billing statements only)";
    } else {
      return "Not enrolled";
    }
  };

  detailItems.push({ label: "Status:", value: getEnrollmentStatus() });

  if (isEnrolledDocuments || isEnrolledBilling) {
    detailItems.push({
      label: "Policy documents email:",
      value: isEnrolledDocuments ? (
        <>
          {insuredPreferences?.opInsuredEmailAddressDocuments ||
            insuredPreferences?.opInsuredEmailAddress}
          <EditDocumentsEmail policyId={policyId} />
        </>
      ) : (
        "Not enrolled"
      ),
    });
    if (userBillingAddress) {
      detailItems.push({
        label: "Billing email:",
        value: (
          <>
            {userBillingAddress}
            <EditBillingEmail policyId={policyId} />
          </>
        ),
      });
    }
  }

  return detailItems;
};

type PaperlessItemProps = {
  policy: NonNullable<PaperlessSettingsDataQuery["userPolicies"]>[number];
  setShowPaperlessBanner: Dispatch<SetStateAction<boolean>>;
};

const PaperlessItem = ({
  policy: { propertyAddress, policyId, isCommercial },
  setShowPaperlessBanner,
}: PaperlessItemProps) => {
  const {
    data: PolicyPaperlessData,
    loading,
    error,
    refetch,
  } = usePolicyPaperlessDataQuery({
    variables: {
      policyID: policyId ?? "",
    },
  });
  const { userDetails, userBilling, userBillingAddress } =
    PolicyPaperlessData ?? {};

  useEffect(() => {
    if (userBilling && userDetails) {
      const { isIneligible, isEnrolledDocuments, isEnrolledBilling } =
        getPaperlessEnrollment(userBilling, userDetails);

      if (!isIneligible && (!isEnrolledDocuments || !isEnrolledBilling)) {
        setShowPaperlessBanner(true);
      }
    }
  }, [setShowPaperlessBanner, userBilling, userDetails]);

  if (error) {
    logError(`Use policy paperless data: ${error.message}`);
    return <FetchingError />;
  }

  const { isIneligible, isEnrolledBilling, isEnrolledDocuments } =
    userBilling && userDetails
      ? getPaperlessEnrollment(userBilling, userDetails)
      : {
          isIneligible: true,
          isEnrolledBilling: false,
          isEnrolledDocuments: false,
        };

  const detailItems =
    !loading && PolicyPaperlessData
      ? getDetailItems(policyId, userBilling, userDetails, userBillingAddress)
      : [];

  return (
    <SettingsPolicy
      isCommercial={!!isCommercial}
      address={propertyAddress}
      detailItems={detailItems}
      loading={loading || !PolicyPaperlessData}
    >
      {!userBilling || !userDetails
        ? "An error occurred while fetching this data"
        : null}
      {!isIneligible && (!isEnrolledDocuments || !isEnrolledBilling) ? (
        <div className={styles.enrollPaperlessButtonWrapper}>
          <EnrollPaperless
            isEnrolledDocuments={isEnrolledDocuments}
            isEnrolledBilling={isEnrolledBilling}
            userDetails={userDetails}
            userBilling={userBilling}
            policyId={policyId}
            refetch={refetch}
          />
        </div>
      ) : null}
    </SettingsPolicy>
  );
};

export default PaperlessItem;
