import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Icon, Span, addToast } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";

import styles from "./upload-proof.module.scss";
import { UploadSection } from "./upload-section";

const PROXY_UPLOAD_URL = `${CONFIG.KEYSTONE_PROXY_HREF}/api/write-out-upload`;
const ERROR_ALERT_TIMEOUT = 10000;

type UploadProofProps = {
  setSubmitting: (submitting: boolean) => void;
  onSubmitSuccess: () => void;
  showSuccessAlert: boolean;
};

export const UploadProof = ({
  setSubmitting,
  onSubmitSuccess,
  showSuccessAlert,
}: UploadProofProps) => {
  const { control, register, setValue, getValues } = useForm();
  const { userDetails, userInfo } = useContext(AuthAppContext);
  const { email: userEmail } = userInfo ?? {};

  const { keystonePolicyId: insightPolicyId } = userDetails ?? {};

  const [localSubmitting, setLocalSubmitting] = useState(false);
  const [preparedFiles, setPreparedFiles] = useState<
    (File & { preview?: string })[]
  >([]);

  const resetFiles = () => {
    preparedFiles.forEach(
      (file) => file.preview && URL.revokeObjectURL(file.preview)
    );
    setValue("files", undefined);
    setPreparedFiles([]);
  };

  const removeFile = (fileIndex) => {
    const fileToBeRemoved = preparedFiles[fileIndex];
    if (fileToBeRemoved.preview) {
      URL.revokeObjectURL(fileToBeRemoved.preview);
    }
    const newList = getValues("files");
    newList.splice(fileIndex, 1);
    setValue("files", newList, { shouldTouch: true });
    setPreparedFiles(newList);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    setLocalSubmitting(true);

    try {
      for (const file of preparedFiles) {
        const fileType = file.type || "text/plain";
        const formData = new FormData();
        formData.append("upload-type", "file");
        formData.append("content-type", fileType);
        formData.append("file", file);
        formData.append("insightPolicyId", insightPolicyId || "");
        formData.append("documentType", "INSPECTION_DOCUMENT");
        formData.append("userEmail", userEmail || "");

        await fetch(PROXY_UPLOAD_URL, {
          method: "POST",
          credentials: "include",
          body: formData,
        });

        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      }
    } catch (_e) {
      addToast("There was an error uploading your files.", {
        icon: true,
        error: true,
        duration: ERROR_ALERT_TIMEOUT,
      });
      setSubmitting(false);
      setLocalSubmitting(false);
      return;
    }

    addToast("Proof of repair submitted.", {
      icon: true,
    });
    onSubmitSuccess();
    resetFiles();
    setSubmitting(false);
    setLocalSubmitting(false);
  };

  return (
    <>
      <UploadSection
        preparedFiles={preparedFiles}
        setPreparedFiles={setPreparedFiles}
        submitting={localSubmitting}
        showSuccessAlert={showSuccessAlert}
        formControl={{ control, register, setValue }}
      />
      {preparedFiles.length > 0 ? (
        <div className={styles.preparedFileSection}>
          <div className={styles.preparedFiles}>
            {preparedFiles.map((file, idx) => (
              <div className={styles.previewContainer} key={idx}>
                {file.type === "application/pdf" ? (
                  <div className={styles.filePreview}>
                    <Icon name="FilePdf" />
                    <Span size="micro">{file.name}</Span>
                  </div>
                ) : (
                  <img
                    src={file.preview}
                    className={styles.thumbnailPreview}
                    alt="thumbnail preview"
                  />
                )}
                <Button
                  className={styles.thumbnailButtonDS}
                  onPress={() => removeFile(idx)}
                  disabled={localSubmitting}
                  size="xs"
                  icon="Delete"
                  aria-label="Delete_item"
                  data-testid="writeouts-remove-file-btn"
                />
              </div>
            ))}
          </div>
          <Button
            className={styles.submitButtonDS}
            disabled={localSubmitting}
            loading={localSubmitting}
            onPress={onSubmit}
            data-testid="writeouts-uploads-done-btn"
          >
            Share with SageSure
          </Button>
        </div>
      ) : null}
    </>
  );
};
