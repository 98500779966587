import cx from "classnames";

import styles from "./progress-bar.module.scss";

export const ProgressBar = ({ progress }: { progress: number }) => {
  const progressStyle = cx(
    styles[`progress${progress || "0"}`],
    styles.progress
  );

  return (
    <div className={styles.progressBar}>
      <div className={progressStyle} />
    </div>
  );
};
