import moment from "moment";
import { useContext } from "react";
import { Col, Container, Row } from "react-grid-system";

import { Alert } from "@icg360/design-system";

import { usePaymentButton } from "components/payment-button/hooks";
import { AuthAppContext } from "components/root/auth-app-provider";
import { formatCurrency, formatDate, logError, trackEvent } from "utils";

import styles from "./scheduled-payment-notification.module.scss";

const ScheduledPaymentNotification = () => {
  const { userBilling } = useContext(AuthAppContext);
  const scheduledPayments =
    userBilling?.pendingChanges?.ScheduledPayments?.scheduledPaymentList ?? [];

  const prevDay = moment().utc().subtract(1, "days");

  const { oneIncPortalReady, onOpenPayment, loading } = usePaymentButton({
    onPaymentStart: () => trackEvent("Scheduled Payment Edit Started"),
    onScheduledPaymentCreated: () => trackEvent("Scheduled Payment Created"),
    onPaymentComplete: (response) => trackEvent("Submit Payment", response),
    onPaymentError: (error) => {
      const errorMessage = error?.message ?? "Unknown Error";
      logError(
        `Payment Error: (Scheduled Payment Notification): ${errorMessage}`
      );
      trackEvent("Payment Error", { errorMessage });
    },
  });

  return (
    <>
      {scheduledPayments?.map((payment, i) => {
        const isUpcoming = moment(payment.paymentDate).isAfter(prevDay);
        //Issue with timezone
        const paymentDate = formatDate(
          payment.paymentDate?.replace("T00:00:00", "")
        );
        return isUpcoming ? (
          <Container className={styles.scheduledPaymentWrap} key={i}>
            <Row>
              <Col md={8} sm={12}>
                <Alert
                  title="Scheduled payment."
                  description={`A payment of ${formatCurrency(
                    payment.totalScheduledAmount,
                    true
                  )} is scheduled for ${paymentDate}.`}
                  cta="Edit"
                  onAction={onOpenPayment}
                  actionable={oneIncPortalReady && !loading}
                />
              </Col>
            </Row>
          </Container>
        ) : null;
      })}
    </>
  );
};

export default ScheduledPaymentNotification;
