import { Button, Card, Heading } from "@icg360/design-system";

import { PolicySummary } from "components/shared/policy-summary";

import styles from "./leakbot.module.scss";

const LeakbotConfirmAddress = ({ onNext, onBack, address, policyId }) => (
  <div className={styles.lbCardWrapper}>
    <Card>
      <div className={styles.lbCardContentWrapper}>
        <Heading size="lg">Send a LeakBot to this property?</Heading>
        <span>
          Your LeakBot is solely for use at the property insured by your policy.
        </span>
        <PolicySummary
          address={address}
          policyId={policyId}
          isCommercial={false}
        />
        <div className={styles.lbNav}>
          <Button appearance="tertiary" onPress={onBack}>
            Go back
          </Button>
          <Button onPress={onNext} data-testid="LeakbotPropertyBtn">
            Continue
          </Button>
        </div>
      </div>
    </Card>
  </div>
);

export default LeakbotConfirmAddress;
